import React, { useState } from "react";
import axios from "axios";
import './Brand.css';
import NavBar from "./NavBar.js";

export const Brand = () => {
    const initialValues = {
        brandId: "",
        brandName: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const uppercaseValue = value.toUpperCase();
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        setFormValues({ ...formValues, [name]: uppercaseValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            try {
                const updatedFormData = { ...formValues, user };
                 const response = await axios.post("https://inventory-mtxj.onrender.com/common/brand", updatedFormData);
                // const response = await axios.post("http://localhost:8084/common/brand", updatedFormData);
                if (response.data.code === 200) {
                    alert(response.data.message);
                    window.location.href = "/import";
                }
            } catch (error) {
                alert(error.response.data.message);
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.brandId) {
            errors.brandId = "Brand ID is required";
        }
        if (!values.brandName) {
            errors.brandName = "Brand Name is required";
        }
        return errors;
    };

    return (
        <div className="brand-form-container">
            <h1 className="brand-form-title">Add brand</h1>
            <form className="brand-form" onSubmit={handleSubmit}>
                <div className="brand-field">
                    <label className="brand-field-label">Brand Id:</label>
                    <input
                        type="text"
                        placeholder="brand Id"
                        name="brandId"
                        value={formValues.brandId}
                        onChange={handleChange}
                    />
                    {formErrors.brandId && <p className="brand-error-message">{formErrors.brandId}</p>}
                </div>
                <div className="brand-field">
                    <label className="brand-field-label">Brand Name:</label>
                    <input
                        type="text"
                        placeholder="brand Name"
                        name="brandName"
                        value={formValues.brandName}
                        onChange={handleChange}
                    />
                    {formErrors.brandName && <p className="brand-error-message">{formErrors.brandName}</p>}
                </div>
                <div className="brand-submit-button-container">
                    <button className="brand-submit" type="submit">
                        Create Brand
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Brand;