import React, { useState, useEffect } from "react";
import axios from "axios";
import './Login.css';

export const Login = () => {
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);


  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setShowAnimation(true);

      // Set a timeout to hide the animation after 3 seconds
      const timeoutId = setTimeout(() => {
        setShowAnimation(false);
      }, 3000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [formErrors, isSubmit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormValues({ ...formValues, [name]: uppercaseValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      try {
         const response = await axios.post("https://inventory-mtxj.onrender.com/auth/login", formValues);
        //  const response = await axios.post("http://localhost:8084/auth/login", formValues);
        if (response.data.code === 200) {
          localStorage.setItem('userName', response.data.name);
          localStorage.setItem('userEmail', response.data.email);
          alert(response.data.message + "\nName: " + response.data.name + "\nCreation Time: " + response.data.createdAt);
          window.location.href = "/welcome";
        }
      } catch (error) {
        window.alert(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleRegistrationClick = () => {
    window.location.href = "/registration";
  };

  return (
    <div className="login-container">
      <main className="login-main-content">
        <header className="login-header">
          <a className="login-company-name">Tanisa International LTD</a>
        </header>
        <div className="login-form-container">
          <h1 className="login-h1">Login</h1>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="login-field">
              <label className="login-field-label">Email:</label>
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />
              {formErrors.email && <p className="login-error">{formErrors.email}</p>}
            </div>
            <div className="login-field">
              <label className="login-field-label">Password:</label>
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
              />
              {formErrors.password && <p className="login-error">{formErrors.password}</p>}
            </div>
            <div className="login-button-container">
              <button className="login-submitbutton" type="submit">
                Sign In
              </button>
            </div>
            <p className="login-registration-link">
              Not registered? <a href="/registration" onClick={handleRegistrationClick}>Click here to register</a>
            </p>
          </form>
        </div>
      </main>
    </div>
  );
};

export default Login;
