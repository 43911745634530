import React, { useState } from "react";
import axios from "axios";
import './Product.css';

export const Product = () => {
    const initialValues = {
        productId: "",
        productName: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const uppercaseValue = value.toUpperCase();
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        setFormValues({ ...formValues, [name]: uppercaseValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const userEmail = localStorage.getItem('userEmail');
            console.log(formValues);
            try {
                const updatedFormData = { ...formValues, userEmail };
                const response = await axios.post("https://inventory-mtxj.onrender.com/common/product", updatedFormData);
                // const response = await axios.post("http://localhost:8084/common/product", updatedFormData);
                if (response.data.code === 200) {
                    alert(response.data.message);
                    window.location.href = "/import";
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    setFormErrors({ serverError: error.response.data.message });
                } else {
                    console.error(error);
                }
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.productId) {
            errors.productId = "Product ID is required";
        }
        if (!values.productName) {
            errors.productName = "Product Name is required";
        }
        return errors;
    };
    return (
        <div className="product-form-container">
            <h1 className="product-form-title">Add Product</h1>
            <form className="product-form" onSubmit={handleSubmit}>
                <div className="product-field">
                    <label className="product-field-label">Product Id:</label>
                    <input
                        type="text"
                        placeholder="Product Id"
                        name="productId"
                        value={formValues.productId}
                        onChange={handleChange}
                    />
                    {formErrors.productId && <p className="product-error-message">{formErrors.productId}</p>}
                </div>
                <div className="product-field">
                    <label className="product-field-label">Product Name:</label>
                    <input
                        type="text"
                        placeholder="Product Name"
                        name="productName"
                        value={formValues.productName}
                        onChange={handleChange}
                    />
                    {formErrors.productName && <p className="product-error-message">{formErrors.productName}</p>}
                </div>
                <div className="product-submit-button-container">
                    <button className="product-submit" type="submit">
                        Create Product
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Product;