import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveLink(path);

    if (path === "/logout") {
      localStorage.clear();
      window.location.href = "/";
    }
  };

  return (
    <nav className="navbar">
      <ul className="links">
        <li className="link-item">
          <Link
            to="/import"
            className={`link ${activeLink === "/import" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/import")}
          >
            Import
          </Link>
        </li>
        <li className="link-item">
          <Link
            to="/order"
            className={`link ${activeLink === "/order" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/order")}
          >
            Order
          </Link>
        </li>
        <li className="link-item">
          <Link
            to="/wastage"
            className={`link ${activeLink === "/wastage" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/wastage")}
          >
            Wastage
          </Link>
        </li>
        <li className="link-item">
          <Link
            to="/return"
            className={`link ${activeLink === "/return" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/return")}
          >
            Return
          </Link>
        </li>
        <li className="link-item">
          <Link
            to="/move"
            className={`link ${activeLink === "/move" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/move")}
          >
            Move
          </Link>
        </li>
        <li className="link-item">
          <Link
            to="/report"
            className={`link ${activeLink === "/report" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/report")}
          >
            Report
          </Link>
        </li>
        <li className="link-item">
          <Link
            to="/payments"
            className={`link ${activeLink === "/payments" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/payments")}
          >
            Payments
          </Link>
        </li>
        <li className="link-item">
          <Link
            to="/logout"
            className={`link signout ${activeLink === "/logout" ? "active-link" : ""}`}
            onClick={() => handleLinkClick("/logout")}
          >
            Logout
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
