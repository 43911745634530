import React, { useState } from "react";
import axios from "axios";
import './Supplier.css';

export const Supplier = () => {
    const initialValues = {
        supplierId: "",
        supplierName: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const uppercaseValue = value.toUpperCase();
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        setFormValues({ ...formValues, [name]: uppercaseValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            try {
                const updatedFormData = { ...formValues, user };
                 const response = await axios.post("https://inventory-mtxj.onrender.com/common/supplier", updatedFormData);
                //  const response = await axios.post("http://localhost:8084/common/supplier", updatedFormData);
                if (response.data.code === 200) {
                    alert(response.data.message);
                    window.location.href = "/order";
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    if (error.response.data.message) {
                        alert(error.response.data.message)
                    }
                } else {
                    alert(error);
                }
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.supplierId) {
            errors.supplierId = "Supplier ID is required";
        }
        if (!values.supplierName) {
            errors.supplierName = "Company Name is required";
        }
        return errors;
    };

    return (
        <div className="supplier-form-container">
            <h1 className="supplier-form-title">Add New Supplier</h1>
            <form className="supplier-form" onSubmit={handleSubmit}>
                <div className="supplier-field">
                    <label className="supplier-field-label">Supplier Id:</label>
                    <input
                        type="text"
                        placeholder="Supplier ID"
                        name="supplierId"
                        value={formValues.supplierId}
                        onChange={handleChange}
                    />
                    {formErrors.supplierId && <p className="supplier-error-message">{formErrors.supplierId}</p>}
                </div>
                <div className="supplier-field">
                    <label className="supplier-field-label">Company Name:</label>
                    <input
                        type="text"
                        placeholder="Company Name"
                        name="supplierName"
                        value={formValues.supplierName}
                        onChange={handleChange}
                    />
                    {formErrors.supplierName && <p className="supplier-error-message">{formErrors.supplierName}</p>}
                </div>
                <div className="supplier-submit-button-container">
                    <button className="supplier-submit" type="submit">
                        Add Supplier
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Supplier;