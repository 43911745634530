import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import './MoveDetails.css';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import Navbar from './NavBar';
import PasswordModal from './PasswordModal';

const MoveDetails = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setMoveTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [info, setInfo] = useState(null);
    const [isAddOperation, setIsAddOperation] = useState(false);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    const addMoveToImport = (id, user) => {
        setInfo({ id, user });
        setIsAddOperation(true);
        setIsModalOpen(true);
    };

    const deleteMove = async (id, user) => {
        setInfo({ id, user });
        setIsModalOpen(true);
    };

    const handleModalSubmit = (password) => {
        if (!password) {
            alert('Password is required.');
            return;
        }
    
        if (password !== 'Flagship@2024') {
            alert('Password not matched');
            return;
        }
    
        if (isAddOperation) {
            const confirmAdd = window.confirm('Are you sure you want to add this move details to import?');
            if (confirmAdd && info) {
                addMoveApiCall(info.id, info.user);
            }
        } else {
            const confirmDelete = window.confirm('Are you sure you want to delete this move details?');
            if (confirmDelete && info) {
                deleteMoveDetailsApiCall(info.id, info.user);
            }
        }
    
        setIsModalOpen(false);
        setInfo(null);
    };

    const deleteMoveDetailsApiCall = async (id, user) => {
        try {
            const response = await axios.delete(`https://inventory-mtxj.onrender.com/move?id=${id}&user=${user}`);
            // const response = await axios.delete(`http://localhost:8084/move?id=${id}&user=${user}`);
            if (response.data.code === 200) {
                alert('Move details deleted successfully.');
                window.location.href = "/move_details";
            } else {
                alert('Error deleting move details.');
            }
        } catch (error) {
            alert('Error deleting move details. Please try again.');
        }
    };

    const addMoveApiCall = async (id, userEmail) => {
        try {
            const response = await axios.put(`https://inventory-mtxj.onrender.com/move?id=${id}&user=${userEmail}`);
            // const response = await axios.put(`http://localhost:8084/move?id=${id}&user=${userEmail}`);
            if (response.data.code === 200) {
                alert(response.data.message);
                alert('Move details added successfully.');
                window.location.href = "/move_details";
            } else {
                alert('Error deleting product details.');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message);
            } else {
                console.error(error);
            }
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/move`);
            // const response = await axios.get('http://localhost:8084/move');
            if (response.data.code === 200) {
                const importResponseList = response.data.moveResponseList || [];
                setMoveTableData(importResponseList);
                setFilteredData(importResponseList);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
    }, []);

    const handleXlsxClick = () => {
        const customFileName = prompt('Enter the file name') || 'report';
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const uniqueFileName = `${customFileName}_${timestamp}`;
        const wsData = [
            ...filteredData.map((row, index) => ({
                'SL NO': index + 1,
                'Serial No:': row.serialNo,
                'Shipment No': row.shipment,
                'Product': row.product,
                'Move From': row.moveFrom,
                'Move To': row.moveTo,
                'Carton': row.carton,
                'Piece': row.piece,
                'Kg/Lt': row.kgLt,
                'Status': row.status,
                'Created By': row.name,
                'Created On': row.createdOn,
            })),
        ];
        const ws = XLSX.utils.json_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const xlsxBinaryArray = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });

        const xlsxBlob = new Blob([xlsxBinaryArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const xlsxUrl = URL.createObjectURL(xlsxBlob);
        const a = document.createElement('a');
        a.href = xlsxUrl;
        a.download = `${uniqueFileName}.xlsx`;
        a.click();
    };

    const handleViewDetails = (serialNo, name, createdOn) => {
        const newTab = window.open('', '_blank');

        if (newTab) {
            const moveSlipUrl = `move_slip?serialNo=${serialNo}?${name}?${createdOn}`;
            newTab.location.href = moveSlipUrl;
        }
    };

    useEffect(() => {
        const updatedFilteredData = data.filter((item) => {
            const matchesSearchTerm =
                item.shipment.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.moveFrom.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.moveTo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.product.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesSearchTerm;
        });

        setFilteredData(updatedFilteredData);
    }, [data, searchTerm]);

    return (
        <div className="move-details-table-container">
            <Navbar />
            <h2>Move Details</h2>
            <div className='move-table-controls'>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <button className='move-action-buttons' type="button" onClick={handleXlsxClick}>
                XLSX
            </button>

            <table className="move-details-table">
                <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Move Id</th>
                        <th>Shipment No</th>
                        <th>Product Name</th>
                        <th>Move From</th>
                        <th>Move To</th>
                        <th>Carton</th>
                        <th>Piece</th>
                        <th>Kg/Lt</th>
                        <th>Status</th>
                        <th>Name</th>
                        <th>Created On</th>
                        <th>Add Move</th>
                        <th>Delete Move</th>
                        <th>Move Slip</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{row.serialNo}</td>
                            <td>{row.shipment}</td>
                            <td>{row.product}</td>
                            <td>{row.moveFrom}</td>
                            <td>{row.moveTo}</td>
                            <td>{row.carton}</td>
                            <td>{row.piece}</td>
                            <td>{row.kgLt}</td>
                            <td>{row.status}</td>
                            <td>{row.name}</td>
                            <td>{row.createdOn}</td>
                            <td>
                                {row.status !== 'MOVED' && (
                                    <button onClick={() => addMoveToImport(row.id, localStorage.getItem('userEmail'))}>
                                        Add
                                    </button>
                                )}
                            </td>
                            <td>
                                {row.status !== 'MOVED' && (
                                    <button onClick={() => deleteMove(row.id, localStorage.getItem('userEmail'))} button={true.toString()}>
                                        Delete
                                    </button>
                                )}
                            </td>
                            <td>
                                <button onClick={() => handleViewDetails(row.serialNo, row.name, row.createdOn)}>
                                    Slip
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            {data.length === 0 && <p>No records to show.</p>}
            <PasswordModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
            />
        </div>
    );
};

export default MoveDetails;