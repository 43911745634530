import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import './AllReturn.css';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import Navbar from './NavBar';

const AllReturn = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setReturnData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleViewDetails = (serialNo, name, warehouse, createdOn, customer, branch) => {
        const newTab = window.open('', '_blank');

        if (newTab) {
            const returnSlipUrl = `return_slip?serialNo=${serialNo}?${name}?${warehouse}?${createdOn}?${customer}?${branch}`;
            newTab.location.href = returnSlipUrl;
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('https://inventory-mtxj.onrender.com/common/return');
            // const response = await axios.get('http://localhost:8084/common/return');
            if (response.data.code === 200) {
                const returnResponseList = response.data.returnResponseUsingSerials || [];
                setReturnData(returnResponseList);
                setFilteredData(returnResponseList);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
    }, []);

    const handleXlsxClick = () => {
        const customFileName = prompt('Enter the file name') || 'report';
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const uniqueFileName = `${customFileName}_${timestamp}`;

        const wsData = filteredData.map((row, index) => ({
            'SL NO': index + 1,
            'Date': row.createdOn,
            'Return Slip': row.serialNo,
            'Customer Name': row.customer,
            'Branch': row.branch,
            'item': row.productName,
            'Pcs': row.piece,
            'Carton': row.cartoon,
            'Kg/Lt': row.kgLt,
            'Receiving Person': row.name,
            'Warehouse': row.warehouse,
            'Cause': row.cause,
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const xlsxBinaryArray = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });

        const xlsxBlob = new Blob([xlsxBinaryArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const xlsxUrl = URL.createObjectURL(xlsxBlob);
        const a = document.createElement('a');
        a.href = xlsxUrl;
        a.download = `${uniqueFileName}.xlsx`;
        a.click();
    };

    useEffect(() => {
        const updatedFilteredData = data.filter((item) => {
            const matchesSearchTerm =
                item.serialNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.warehouse?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.customer?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.branch?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.date?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.productName?.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesSearchTerm;
        });

        setFilteredData(updatedFilteredData);
    }, [data, searchTerm]);

    return (
        <div className="return-details-table-container">
            <Navbar />
            <h2>Return Details</h2>
            <div className='return-table-controls'>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <button className='requisition-action-buttons' type="button" onClick={handleXlsxClick}>
                XLSX
            </button>

            <table className="return-details-table">
                <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Date</th>
                        <th>Return Slip</th>
                        <th>Customer Name</th>
                        <th>Branch</th>
                        <th>Item</th>
                        <th>Pcs</th>
                        <th>Carton</th>
                        <th>Kg/Lt</th>
                        <th>Receiving Person</th>
                        <th>Warehouse</th>
                        <th>Cause</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{row.createdOn}</td>
                            <td>{row.serialNo}</td>
                            <td>{row.customer}</td>
                            <td>{row.branch}</td>
                            <td>{row.productName}</td>
                            <td>{row.piece}</td>
                            <td>{row.cartoon}</td>
                            <td>{row.kgLt}</td>
                            <td>{row.name}</td>
                            <td>{row.warehouse}</td>
                            <td>{row.cause}</td>
                            <td>
                                <button onClick={() => handleViewDetails(row.serialNo, row.name, row.warehouse, row.createdOn, row.customer, row.branch)}>
                                    Return Slip
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            {data.length === 0 && <p>No records to show.</p>}
        </div>
    );
};
export default AllReturn;