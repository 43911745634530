import React, { useState, useEffect } from "react";
import axios from "axios";
import './Branch.css';
import makeAnimated from "react-select/animated";
import Select from "react-select";

const animatedComponents = makeAnimated();

export const Branch = () => {
    const initialValues = {
        supplier: "",
        name: "",
        address: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [suppliers, setSupplier] = useState([]);

    const handleChange = (name, value) => {
        const uppercaseValue = name === 'name' || name === 'address' ? value.toUpperCase() : value;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: uppercaseValue,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            try {
                const updatedFormData = { ...formValues, user };
                const response = await axios.post("https://inventory-mtxj.onrender.com/common/branch", updatedFormData);
                // const response = await axios.post("http://localhost:8084/common/branch", updatedFormData);
                if (response.data.code === 200) {
                    alert(response.data.message);
                    window.location.href = "/order";
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    if (error.response.data.message) {
                        alert(error.response.data.message)
                    }
                } else {
                    alert(error);
                }
            }
        }
    };

    const fetchSupplier = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/supplier`);
            // const response = await axios.get(`http://localhost:8084/common/supplier`);
            if (response.data.code === 200) {
                setSupplier(response.data.supplierList);
            }
        } catch (error) {
            alert("Error fetching supplier data:", error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchSupplier();
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        if (!values.supplier) {
            errors.supplier = "Company is required";
        }
        if (!values.name) {
            errors.name = "Branch Name is required";
        }
        if (!values.address) {
            errors.address = "Delivery Address is required";
        }
        return errors;
    };

    return (
        <div className="branch-form-container">
            <h1 className="branch-form-title">Add New Branch</h1>
            <form className="branch-form" onSubmit={handleSubmit}>
                <div className="branch-field">
                    <label className="branch-field-label">Company Name:</label>
                    <Select
                        className="dropdown"
                        name="supplier"
                        components={{
                            ...animatedComponents,
                        }}
                        value={formValues.supplier}
                        onChange={(selectedOption) => handleChange("supplier", selectedOption)}
                        options={suppliers.map((s) => ({ label: s.supplierName, value: s.supplierId }))}
                        isSearchable
                        placeholder="Select Company"
                    />
                    {formErrors.supplier && <p className="branch-error-message">{formErrors.supplier}</p>}
                </div>
                <div className="branch-field">
                    <label className="branch-field-label">Name:</label>
                    <input
                        type="text"
                        placeholder="Branch Name"
                        name="name"
                        value={formValues.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                    />
                    {formErrors.name && <p className="branch-error-message">{formErrors.name}</p>}
                </div>
                <div className="branch-field">
                    <label className="branch-field-label">Delivery Address:</label>
                    <input
                        type="text"
                        placeholder="Delivery Address"
                        name="address"
                        value={formValues.address}
                        onChange={(e) => handleChange("address", e.target.value)}
                    />
                    {formErrors.address && <p className="branch-error-message">{formErrors.address}</p>}
                </div>
                <div className="branch-submit-button-container">
                    <button className="branch-submit" type="submit">
                        Add Branch
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Branch;