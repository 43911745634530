import React, { useState, useEffect } from "react";
import axios from "axios";
import './SalesPerson.css';

const Sales = () => {
    const initialValues = {
        salesPersonId: "", salesPersonName: "", phoneNumber: "", area: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const uppercaseValue = value.toUpperCase();
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        setFormValues({ ...formValues, [name]: uppercaseValue });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            callYourAPI(formValues, user);
        }
    };

    const callYourAPI = async (formData, user) => {
        try {
            const updatedFormData = { ...formData, user };
            const response = await axios.post("https://inventory-mtxj.onrender.com/common/sales_person", updatedFormData);
            //  const response = await axios.post("http://localhost:8084/common/sales_person", updatedFormData);
            if (response && response.data && response.data.code === 200) {
                alert(response.data.message);
                window.location.href = "/order";
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.message) {
                    alert(error.response.data.message)
                }
            } else {
                alert(error);
            }
        }
    };


    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        if (Object.keys(formErrors).length === 0 && isSubmit) {
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        if (!values.salesPersonId) {
            errors.salesPersonId = "Sales Person Id is required";
        }
        if (!values.salesPersonName) {
            errors.salesPersonName = "Sales Person Name is required";
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = "Phone Number is required";
        }
        return errors;
    }

    return (
        <div className="sales-person-form-container">
            <h1 className="sales-person-form-title">Add New Sales Person</h1>
            <form className="sales-person-form" onSubmit={handleSubmit}>
                <div className="sales-person-field">
                    <label className="sales-person-field-label">Sales Person Id:</label>
                    <input
                        type="text"
                        placeholder="Sales Person Id"
                        name="salesPersonId"
                        value={formValues.salesPersonId}
                        onChange={handleChange}
                    />
                    {formErrors.salesPersonId && <p className="sales-person-error-message">{formErrors.salesPersonId}</p>}
                </div>
                <div className="sales-person-field">
                    <label className="sales-person-field-label">Sales Person Name:</label>
                    <input
                        type="text"
                        placeholder="Sales Person Name"
                        name="salesPersonName"
                        value={formValues.salesPersonName}
                        onChange={handleChange}
                    />
                    {formErrors.salesPersonName && <p className="sales-person-error-message">{formErrors.salesPersonName}</p>}
                </div>
                <div className="sales-person-field">
                    <label className="sales-person-field-label">Phone Number:</label>
                    <input
                        type="text"
                        placeholder="Phone Number"
                        name="phoneNumber"
                        value={formValues.phoneNumber}
                        onChange={handleChange}
                    />
                    {formErrors.phoneNumber && <p className="sales-person-error-message">{formErrors.phoneNumber}</p>}
                </div>
                <div className="sales-person-field">
                    <label className="sales-person-field-label">Area:</label>
                    <input
                        type="text"
                        placeholder="Area"
                        name="area"
                        value={formValues.area}
                        onChange={handleChange}
                    />
                </div>
                <div className="sales-person-submit-button-container">
                    <button className="sales-person-submit" type="submit">
                        Add Sales Person
                    </button>
                </div>
            </form>
        </div>
    )
}
export default Sales