import React, { useState, useEffect } from "react";
import "./Wastage.css";
import axios from "axios";
import NavBar from "./NavBar.js";
import makeAnimated from "react-select/animated";
import Select from "react-select";

const animatedComponents = makeAnimated();

export const Wastage = () => {
  const [formErrors, setFormErrors] = useState({});
  const [wastageDetailsErrors, setwastageDetailsErrors] = useState([{}]);
  const [products, setProduct] = useState([]);
  const [productShipments, setProductShipments] = useState([]);
  const [warehouseList, setWarehouse] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [wastageMaster, setwastageMasterData] = useState({
    warehouse: "",
    user: "",
    wastageDetailsRequestList: [],
  });
  const [wastageDetails, setWastageDetails] = useState([
    {
      product: "",
      shipment: "",
      wastageFrom: "",
      uom: "",
      quantity: "",
      cause: "",
      available: "",
      piece: "",
    },
  ]);

  const initialWastageDetail = {
    product: "",
    shipment: "",
    wastageFrom: "",
    uom: "",
    quantity: "",
    cause: "",
    available: "",
    piece: "",
  };

  const validateFormData = (values) => {
    const errors = {};
    if (!values.warehouse) {
      errors.warehouse = "Warehouse is required";
    }
    return errors;
  };

  const handleChange = (fieldName, fieldValue) => {
    let uppercaseValue = fieldValue;
    if (typeof fieldValue === 'object' && fieldValue.label) {
      uppercaseValue = { label: fieldValue.label.toUpperCase(), value: fieldValue.value };
    }

    setwastageMasterData((prevValues) => ({
      ...prevValues,
      [fieldName]: uppercaseValue || '',
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const masterErrors = validateFormData(wastageMaster);
    setFormErrors(masterErrors);

    const detailsErrors = wastageDetails.map((detail) => validateWastageDetail(detail));
    setwastageDetailsErrors(detailsErrors);

    if (Object.keys(masterErrors).length === 0 && detailsErrors.every((errors) => Object.keys(errors).length === 0)) {
      const user = localStorage.getItem('userEmail');
      callYourAPI(wastageMaster, user);
    }
  };

  const callYourAPI = async (wastageMaster, user) => {
    try {
      const updatedFormData = { ...wastageMaster, user, wastageDetailsRequestList: wastageDetails };
      console.log(updatedFormData);

      // Check quantity against available before making the API call
      if (wastageDetails.some(detail => detail.quantity > detail.available)) {
        alert("Quantity cannot be greater than available!");
        return;
      }

      // Make the API call
      const response = await axios.post("https://inventory-mtxj.onrender.com/common/wastage", updatedFormData);
      // const response = await axios.post("http://localhost:8084/common/wastage", updatedFormData);
      if (response && response.data && response.data.code === 200) {
        alert(response.data.message);
        window.location.href = "/wastage";
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setFormErrors({ serverError: error.response.data.message });
      } else {
        console.error(error.response.data.message);
      }
    }
  };

  const fetchProduct = async () => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/common/product`);
      // const response = await axios.get(`http://localhost:8084/common/product`);
      if (response.data.code === 200) {
        setProduct(response.data.allProductResponse);
      }
    } catch (error) {
      alert("Error fetching product data:", error);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/common/warehouse`);
      // const response = await axios.get(`http://localhost:8084/common/warehouse`);
      if (response.data.code === 200) {
        setWarehouse(response.data.warehouseList);
      }
    } catch (error) {
      alert("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    fetchProduct();
    fetchWarehouse();
  }, [formErrors]);

  const handleRemoveRow = (index) => {
    if (wastageDetails.length > 1) {
      const updatedWastageDetails = [...wastageDetails];
      updatedWastageDetails.splice(index, 1);
      setWastageDetails(updatedWastageDetails);
    } else {
      alert("Cannot remove the only row");
    }
  };

  const handleChangeDetail = async (index, name, selectedOption) => {
    const updatedWastageDetail = [...wastageDetails];
    const updatedWastageDetailsErrors = [...wastageDetailsErrors];
    updatedWastageDetailsErrors[index][name] = "";
    const stringValue = typeof selectedOption === 'string' ? selectedOption.toUpperCase() : selectedOption;
    updatedWastageDetail[index][name] = stringValue;
    if (name === "product" || name === "shipment" || name === "wastageFrom") {
      await handleProductOrShipmentChange(index, name, updatedWastageDetail, updatedWastageDetailsErrors);
    }
    setWastageDetails(updatedWastageDetail);
    setwastageDetailsErrors(updatedWastageDetailsErrors);
  };

  const handleProductOrShipmentChange = async (index, name, updatedWastageDetail, updatedWastageDetailsErrors) => {
    const product = updatedWastageDetail[index].product;
    const shipment = updatedWastageDetail[index].shipment;
    const wastageFrom = updatedWastageDetail[index].wastageFrom;
    if (name === 'product' && product) {
      await fetchShipments(product.value);
    }
    if (name === 'shipment' && shipment) {
      await fetchWarehouseInfo(product.value, shipment.value);
    };
    if (name === "wastageFrom" && wastageFrom) {
      const uomAndAvailable = await fetchUomAndAvaiableInfo(product.value, shipment.value, wastageFrom.value);
      updatedWastageDetail[index] = {
        ...updatedWastageDetail[index],
        uom: uomAndAvailable.uom,
        available: uomAndAvailable.available,
      };
      updatedWastageDetailsErrors[index].uom = "";
      updatedWastageDetailsErrors[index].available = "";
    } else {
      updatedWastageDetail[index] = {
        ...updatedWastageDetail[index],
        uom: "N/A",
        available: 0,
      };
      updatedWastageDetailsErrors[index].uom = "";
      updatedWastageDetailsErrors[index].available = "";
    }
  }

  const fetchUomAndAvaiableInfo = async (productId, shipmentNo, warehouse) => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/import/uom/available?product=${productId}&shipment=${shipmentNo}&warehouse=${warehouse}`);
      // const response = await axios.get(`http://localhost:8084/import/uom/available?product=${productId}&shipment=${shipmentNo}&warehouse=${warehouse}`);
      if (response.data.code === 200) {
        return {
          uom: response.data.uom,
          available: response.data.available,
        };
      }
    } catch (error) {
      return {
        uom: "",
        available: 0,
      };
    }
  };

  const fetchShipments = async (productId) => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/import/shipment?product=${productId}`);
      // const response = await axios.get(`http://localhost:8084/import/shipment?product=${productId}`);
      if (response.data.code === 200) {
        setProductShipments(response.data.allShipment || []);
      }
    } catch (error) {
      alert("Error fetching shipments:", error);
    }
  };

  const fetchWarehouseInfo = async (productId, shipmentNo) => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/import/warehouse?product=${productId}&shipment=${shipmentNo}`);
      // const response = await axios.get(`http://localhost:8084/import/warehouse?product=${productId}&shipment=${shipmentNo}`);
      if (response.data.code === 200) {
        setWarehouses(response.data.allWarehouse || []);
      }
    } catch (error) {
      alert("Error fetching warehouse:", error);
    }
  };

  const handleAddRow = () => {
    const newRowErrors = wastageDetails.map((detail) => validateWastageDetail(detail));
    const hasErrors = newRowErrors.some((errors) => Object.keys(errors).length > 0);

    if (hasErrors) {
      setwastageDetailsErrors(newRowErrors);
    } else {
      setWastageDetails([...wastageDetails, { ...initialWastageDetail }]);
      setwastageDetailsErrors([...wastageDetailsErrors, {}]);
    }
  };

  const validateWastageDetail = (detail) => {
    const errors = {};
    if (!detail.product) {
      errors.product = "Product is required";
    }
    if (!detail.shipment) {
      errors.shipment = "Shipment is required";
    }
    if (!detail.quantity) {
      errors.quantity = "Quantity is required";
    }
    if (!detail.cause) {
      errors.cause = "Cause is required";
    }
    if (!detail.wastageFrom) {
      errors.wastageFrom = "Wastage From is required";
    }
    if (!detail.piece) {
      errors.piece = "Piece is required";
    }
    return errors;
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      maxHeight: '200px',
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div className="wastage-container">
      <NavBar />
      <main className="wastage-main-content">
        <header className="wastage-header">
          <a className="wastage-company-name">Tanisa International LTD</a>
        </header>
        <div className="wastage-form-container">
          <h1 className="wastage-form-title">Add Wastage</h1>
          <form className="wastage-form" onSubmit={handleSubmit}>
            <div className="wastage-Common-fields-container">
              <div className='wastage-row'>
                <div className="wastage-field">
                  <label className="wastage-field-label">Warehouse:</label>
                  <Select
                    name="warehouse"
                    components={{
                      ...animatedComponents,
                    }}
                    value={wastageMaster.warehouse}
                    onChange={(value) => handleChange("warehouse", value)}
                    options={warehouseList.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                    isSearchable
                    placeholder="Select Warehouse"
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    menuPlacement='auto'
                  />
                  {formErrors.warehouse && <p className="wastage-error-message">{formErrors.warehouse}</p>}
                </div>
              </div>
            </div>
            <div className="wastage-table-container">
              <table className="wastage-table">
                <thead>
                  <th>Product Name</th>
                  <th>Shipment</th>
                  <th>Warehouse</th>
                  <th>UOM</th>
                  <th>Available</th>
                  <th>Quantity</th>
                  <th>Piece</th>
                  <th>Cause</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {wastageDetails.map((detail, index) => (
                    <tr row={index}>
                      <td>
                        <Select
                          name="product"
                          components={{
                            ...animatedComponents,
                          }}
                          value={detail.product}
                          onChange={(selectedOption) => handleChangeDetail(index, "product", selectedOption)}
                          options={products.map((p) => ({ label: p.productName, value: p.productId }))}
                          placeholder="Product"
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPlacement='auto'
                        />
                        {wastageDetailsErrors[index].product && (
                          <p className="wastage-detail-error-message">{wastageDetailsErrors[index].product}</p>
                        )}
                      </td>
                      <td>
                        <Select
                          name="shipment"
                          components={{
                            ...animatedComponents,
                          }}
                          value={detail.shipment || ""}
                          onChange={(selectedOption) => handleChangeDetail(index, "shipment", selectedOption)}
                          options={productShipments.map((s) => ({ label: s.shipmentNo, value: s.shipmentNo }))}
                          placeholder="Shipment"
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPlacement='auto'
                        />
                        {wastageDetailsErrors[index].shipment && (
                          <p className="wastage-detail-error-message">{wastageDetailsErrors[index].shipment}</p>
                        )}
                      </td>
                      <td>
                        <Select
                          name="wastageFrom"
                          components={{
                            ...animatedComponents,
                          }}
                          value={detail.wastageFrom}
                          onChange={(value) => handleChangeDetail(index, "wastageFrom", value)}
                          options={warehouses.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                          placeholder="Wastage From"
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPlacement='auto'
                        />
                        {wastageDetailsErrors[index].wastageFrom && (
                          <p className="wastage-detail-error-message">{wastageDetailsErrors[index].wastageFrom}</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          name="uom"
                          value={detail.uom}
                          readOnly={true}
                          onChange={(e) => handleChangeDetail(index, "uom", e.target.value)}
                        />
                        {wastageDetailsErrors[index].uom && (
                          <p className="wastage-detail-error-message">{wastageDetailsErrors[index].uom}</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="number"
                          name="available"
                          value={detail.available}
                          readOnly={true}
                          onChange={(value) => handleChangeDetail(index, "available", value.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          name="quantity"
                          value={detail.quantity}
                          onChange={(value) => handleChangeDetail(index, "quantity", value.target.value)}
                        />
                        {wastageDetailsErrors[index].quantity && (
                          <p className="wastage-detail-error-message">{wastageDetailsErrors[index].quantity}</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="number"
                          name="piece"
                          value={detail.piece}
                          onChange={(value) => handleChangeDetail(index, "piece", value.target.value)}
                        />
                        {wastageDetailsErrors[index].piece && (
                          <p className="wastage-detail-error-message">{wastageDetailsErrors[index].piece}</p>
                        )}
                      </td>
                      <td>
                        <select
                          name="cause"
                          value={detail.cause}
                          onChange={(e) => handleChangeDetail(index, "cause", e.target.value)}
                        >
                          <option value="">Select Cause</option>
                          <option value="DAMAGED">DAMAGED</option>
                          <option value="DATE_OVER">DATE OVER</option>
                          <option value="DELIVERY_SHORTAGE">DELIVERY SHORTAGE</option>
                          <option value="SHRINKAGE">SHRINKAGE</option>
                        </select>
                        {wastageDetailsErrors[index].cause && (
                          <p className="wastage-detail-error-message">{wastageDetailsErrors[index].cause}</p>
                        )}
                      </td>
                      <td>
                        <button className="remove-button" type="button" onClick={() => handleRemoveRow(index)}>
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <button className="wastage-add-row-button" type="button" onClick={handleAddRow}>
                  Add Row
                </button>
              </table>
            </div>
            <div class="wastage-center-button">
              <button class="wastage-button">SUBMIT</button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default Wastage;