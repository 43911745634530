import React, { useState, useEffect } from "react";
import "./Move.css";
import axios from "axios";
import NavBar from "./NavBar.js";
import makeAnimated from "react-select/animated";
import Select from "react-select";

const animatedComponents = makeAnimated();

export const Move = () => {
    const [moveErrors, setMoveErrors] = useState([{}]);
    const [shipments, setShipments] = useState([]);
    const [products, setProduct] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState([]);
    const [moveDetails, setMoveDetails] = useState([
        {
            product: "",
            shipment: "",
            moveFrom: "",
            moveTo: "",
            availableCarton: "",
            availablePiece: "",
            availableKgLt: "",
            cartonQuantity: "",
            pieceQuantity: "",
            kgLtQuantity: "",
            user: "",
        },
    ]);

    const initialMoveDetail = {
        product: "",
        shipment: "",
        moveFrom: "",
        moveTo: "",
        availableCarton: "",
        availablePiece: "",
        availableKgLt: "",
        cartonQuantity: "",
        pieceQuantity: "",
        kgLtQuantity: "",
        user: "",
    };

    const fetchProduct = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/product`);
            // const response = await axios.get(`http://localhost:8084/common/product`);
            if (response.data.code === 200) {
                setProduct(response.data.allProductResponse);
            }
        } catch (error) {
            alert("Error fetching product data:", error);
        }
    };

    const fetchWarehouse = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/warehouse`);
            // const response = await axios.get(`http://localhost:8084/common/warehouse`);
            if (response.data.code === 200) {
                setWarehouse(response.data.warehouseList);
            }
        } catch (error) {
            alert("Error fetching warehouse data:", error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const detailsErrors = moveDetails.map((detail) => validateMoveDetail(detail));
        const hasErrors = detailsErrors.some((errors) => Object.keys(errors).length > 0);
        if (hasErrors) {
            console.log("Errors: ", detailsErrors);
            setMoveErrors(detailsErrors);
        } else {
            const user = localStorage.getItem('userEmail');
            callYourAPI(moveDetails, user);
        }
    };

    const callYourAPI = async (moveDetails, user) => {
        try {
            const updatedMoveDetails = moveDetails.map(detail => ({ ...detail, user }));

            const hasDuplicateMoveFromTo = hasDuplicateMoveFromToPairs(updatedMoveDetails);
            const isCartonQuantityGreaterThanAvailableCarton = hasCartonQuantityGreaterThanAvailableCarton(updatedMoveDetails);
            const isPieceQuantityGreaterThanAvailablePiece = hasPieceQuantityGreaterThanAvailablePiece(updatedMoveDetails);
            const isKgLtQuantityGreaterThanAvailableCarton = hasKgLtQuantityGreaterThanAvailableKgLt(updatedMoveDetails);

            if (hasDuplicateMoveFromTo) {
                alert("Move From and Move To should be different for each row.");
                return;
            }
            if (isCartonQuantityGreaterThanAvailableCarton) {
                alert("Carton Quantity cannot be greater than available carton.");
                return;
            }
            if (isPieceQuantityGreaterThanAvailablePiece) {
                alert("Piece Quantity cannot be greater than available piece.");
                return;
            }
            if (isKgLtQuantityGreaterThanAvailableCarton) {
                alert("Kg/LT Quantity cannot be greater than available kg/lt.");
                return;
            }
            console.log(updatedMoveDetails);
            const response = await axios.post("https://inventory-mtxj.onrender.com/move", updatedMoveDetails);
            // const response = await axios.post("http://localhost:8084/move", updatedMoveDetails);
            if (response && response.data && response.data.code === 200) {
                alert(response.data.message);
                window.location.href = "/move";
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message);
                setMoveErrors({ serverError: error.response.data.message });
            } else {
                console.error(error);
            }
        }
    };

    const hasDuplicateMoveFromToPairs = (moveDetails) => {
        const uniquePairs = new Set();
        for (const detail of moveDetails) {
            const moveFromToPair = `${detail.moveFrom}-${detail.moveTo.value}-${detail.product.value}`;
            if (uniquePairs.has(moveFromToPair)) {
                return true;
            }
            uniquePairs.add(moveFromToPair);
        }
        return false
    };

    const hasCartonQuantityGreaterThanAvailableCarton = (moveDetails) => {
        for (const detail of moveDetails) {
            const cartonQuantity = parseFloat(detail.cartonQuantity);
            const availableCarton = parseFloat(detail.availableCarton);

            if (cartonQuantity > availableCarton) {
                return true;
            }
        }
        return false;
    };
    const hasPieceQuantityGreaterThanAvailablePiece = (moveDetails) => {
        for (const detail of moveDetails) {
            const pieceQuantity = parseFloat(detail.pieceQuantity);
            const availablePiece = parseFloat(detail.availablePiece);

            if (pieceQuantity > availablePiece) {
                return true;
            }
        }
        return false;
    };
    const hasKgLtQuantityGreaterThanAvailableKgLt = (moveDetails) => {
        for (const detail of moveDetails) {
            const kgLtQuantity = parseFloat(detail.kgLtQuantity);
            const availableKgLt = parseFloat(detail.availableKgLt);

            if (kgLtQuantity > availableKgLt) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchProduct();
        fetchWarehouse();
    }, [moveDetails]);

    const handleRemoveRow = (index) => {
        if (moveDetails.length > 1) {
            const updatedMoveDetails = [...moveDetails];
            updatedMoveDetails.splice(index, 1);
            setMoveDetails(updatedMoveDetails);
        } else {
            alert("Cannot remove the only row");
        }
    };

    const handleChangeDetail = async (index, name, value) => {
        const updatedMoveDetails = [...moveDetails];
        const updatedMoveErrors = [...moveErrors];
        updatedMoveErrors[index][name] = "";
        let uppercaseValue;
        if (value && typeof value === 'object') {
            if (value.label) {
                uppercaseValue = value.label.toUpperCase();
            } else {
                console.error("Invalid selectedOption structure. Missing label property.");
                return;
            }
        } else {
            uppercaseValue = String(value).toUpperCase();
        }
        updatedMoveDetails[index][name] = uppercaseValue;
        if (name === "product") {
            updatedMoveDetails[index][name] = value;
        } else if (name === "shipment") {
            updatedMoveDetails[index][name] = value;
        } else if (name === "moveFrom") {
            updatedMoveDetails[index][name] = value;
        } else if (name === "moveTo") {
            updatedMoveDetails[index][name] = value;
        } else {
            updatedMoveDetails[index][name] = uppercaseValue;
        }
        if (name === "product" || name === "shipment" || name === "moveFrom") {
            await handleProductOrShipmentChange(index, name, updatedMoveDetails, updatedMoveErrors);
        }
        setMoveDetails(updatedMoveDetails);
        setMoveErrors(updatedMoveErrors);
    };

    const handleProductOrShipmentChange = async (index, name, updatedMoveDetails, updatedMoveErrors) => {
        const product = updatedMoveDetails[index].product;
        const shipment = updatedMoveDetails[index].shipment;
        const warehouse = updatedMoveDetails[index].moveFrom;
        if (name === "product" && product) {
            await fetchShipments(product.value);
        }
        if (name === "shipment" && shipment) {
            await fetchWarehouseInfo(product.value, shipment.value);
        };
        if (name === "moveFrom" && warehouse) {
            const productAvailable = await fetchUomAndAvaiableInfo(product.value, shipment.value, warehouse.value);
            updatedMoveDetails[index] = {
                ...updatedMoveDetails[index],
                availableCarton: productAvailable.availableCarton,
                availablePiece: productAvailable.availablePiece,
                availableKgLt: productAvailable.availableKgLt
            };
            updatedMoveErrors[index].availableCarton = "";
            updatedMoveErrors[index].availablePiece = "";
            updatedMoveErrors[index].availableKgLt = "";
        } else {
            updatedMoveDetails[index] = {
                ...updatedMoveDetails[index],
                availableCarton: 0,
                availablePiece: 0,
                availableKgLt: 0,
            };
            updatedMoveErrors[index].availableCarton = "";
            updatedMoveErrors[index].availablePiece = "";
            updatedMoveErrors[index].availableKgLt = "";
        }
    }
    const fetchShipments = async (productId) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/import/shipment?product=${productId}`);
            // const response = await axios.get(`http://localhost:8084/import/shipment?product=${productId}`);
            if (response.data.code === 200) {
                setShipments(response.data.allShipment || []);
            }
        } catch (error) {
            alert("Error fetching shipments:", error);
        }
    };
    const fetchWarehouseInfo = async (productId, shipmentNo) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/import/warehouse?product=${productId}&shipment=${shipmentNo}`);
            // const response = await axios.get(`http://localhost:8084/import/warehouse?product=${productId}&shipment=${shipmentNo}`);
            if (response.data.code === 200) {
                setWarehouses(response.data.allWarehouse || []);
            }
        } catch (error) {
            alert("Error fetching warehouse:", error);
        }
    };
    const fetchUomAndAvaiableInfo = async (productId, shipmentNo, warehouse, sourceWarehouse) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/import/available?product=${productId}&shipment=${shipmentNo}&warehouse=${warehouse}`);
            // const response = await axios.get(`http://localhost:8084/import/available?product=${productId}&shipment=${shipmentNo}&warehouse=${warehouse}`);
            if (response.data.code === 200) {
                return {
                    availableCarton: response.data.carton,
                    availablePiece: response.data.piece,
                    availableKgLt: response.data.kgLt,
                };
            }
        } catch (error) {
            return {
                availableCarton: 0,
                availablePiece: 0,
                availableKgLt: 0,
            };
        }
    };

    const handleAddRow = () => {
        const newRowErrors = moveDetails.map((detail) => validateMoveDetail(detail));
        const hasErrors = newRowErrors.some((errors) => Object.keys(errors).length > 0);

        if (hasErrors) {
            setMoveErrors(newRowErrors);
        } else {
            setMoveDetails([...moveDetails, { ...initialMoveDetail }]);
            setMoveErrors([...moveErrors, {}]);
        }
    };

    const validateMoveDetail = (detail) => {
        const errors = {};
        if (!detail.product) {
            errors.product = "Product is required";
        }
        if (!detail.shipment) {
            errors.shipment = "Shipment is required";
        }
        if (!detail.moveFrom) {
            errors.moveFrom = "Move From is required";
        }
        if (!detail.moveTo) {
            errors.moveTo = "Move To is required";
        }
        if (!detail.cartonQuantity) {
            errors.cartonQuantity = "Carton Quantity is required";
        }
        if (!detail.pieceQuantity) {
            errors.pieceQuantity = "Piece Quantity is required";
        }
        if (!detail.kgLtQuantity) {
            errors.kgLtQuantity = "Kg Lt Quantity is required";
        }
        return errors;
    };

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            maxHeight: '200px',
            overflowY: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '200px',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    return (
        <div className="move-container">
            <NavBar />
            <main className="move-main-content">
                <header className="move-header">
                    <a className="move-company-name">Tanisa International LTD</a>
                </header>
                <div className="move-form-container">
                    <h1 className="move-form-title">Add Move</h1>
                    <form className="move-form" onSubmit={handleSubmit}>
                        <div className="move-table-container">
                            <table className="move-table">
                                <thead>
                                    <th>Product Name</th>
                                    <th>Shipment</th>
                                    <th>Move From</th>
                                    <th>Move To</th>
                                    <th>Available Carton</th>
                                    <th>Available Piece</th>
                                    <th>Available Kg/Lt</th>
                                    <th>Carton Quantity</th>
                                    <th>Piece Quantity</th>
                                    <th>Kg/Lt Quantity</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {moveDetails.map((detail, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Select
                                                    name="product"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.product}
                                                    onChange={(value) => handleChangeDetail(index, "product", value)}
                                                    options={products.map((p) => ({ label: p.productName, value: p.productId }))}
                                                    placeholder="Select Product"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {moveErrors[index].product && (
                                                    <p className="move-detail-error-message">{moveErrors[index].product}</p>
                                                )}
                                            </td>
                                            <td>
                                                <Select
                                                    name="shipment"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.shipment}
                                                    onChange={(value) => handleChangeDetail(index, "shipment", value)}
                                                    options={shipments.map((s) => ({ label: s.shipmentNo, value: s.shipmentNo }))}
                                                    placeholder="Select Shipment"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {moveErrors[index].shipment && (
                                                    <p className="move-detail-error-message">{moveErrors[index].shipment}</p>
                                                )}
                                            </td>
                                            <td>
                                                <Select
                                                    name="moveFrom"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.moveFrom}
                                                    onChange={(value) => handleChangeDetail(index, "moveFrom", value)}
                                                    options={warehouses.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                                                    placeholder="Move From"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {moveErrors[index].moveFrom && (
                                                    <p className="move-detail-error-message">{moveErrors[index].moveFrom}</p>
                                                )}
                                            </td>
                                            <td>
                                                <Select
                                                    name="moveTo"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.moveTo}
                                                    onChange={(value) => handleChangeDetail(index, "moveTo", value)}
                                                    options={warehouse.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                                                    placeholder="Select Move To"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {moveErrors[index].moveTo && (
                                                    <p className="move-detail-error-message">{moveErrors[index].moveTo}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="availableCarton"
                                                    value={detail.availableCarton}
                                                    readOnly={true}
                                                    onChange={(e) => handleChangeDetail(index, "availableCarton", e.target.value)}
                                                />
                                                {moveErrors[index].availableCarton && (
                                                    <p className="move-detail-error-message">{moveErrors[index].availableCarton}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="availablePiece"
                                                    value={detail.availablePiece}
                                                    readOnly={true}
                                                    onChange={(e) => handleChangeDetail(index, "availablePiece", e.target.value)}
                                                />
                                                {moveErrors[index].availablePiece && (
                                                    <p className="move-detail-error-message">{moveErrors[index].availablePiece}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="availableKgLt"
                                                    value={detail.availableKgLt}
                                                    readOnly={true}
                                                    onChange={(e) => handleChangeDetail(index, "availableKgLt", e.target.value)}
                                                />
                                                {moveErrors[index].availableKgLt && (
                                                    <p className="move-detail-error-message">{moveErrors[index].availableKgLt}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="cartonQuantity"
                                                    value={detail.cartonQuantity}
                                                    onChange={(e) => handleChangeDetail(index, "cartonQuantity", e.target.value)}
                                                />
                                                {moveErrors[index].cartonQuantity && (
                                                    <p className="move-detail-error-message">{moveErrors[index].cartonQuantity}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="pieceQuantity"
                                                    value={detail.pieceQuantity}
                                                    onChange={(e) => handleChangeDetail(index, "pieceQuantity", e.target.value)}
                                                />
                                                {moveErrors[index].pieceQuantity && (
                                                    <p className="move-detail-error-message">{moveErrors[index].pieceQuantity}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="kgLtQuantity"
                                                    value={detail.kgLtQuantity}
                                                    onChange={(e) => handleChangeDetail(index, "kgLtQuantity", e.target.value)}
                                                />
                                                {moveErrors[index].kgLtQuantity && (
                                                    <p className="move-detail-error-message">{moveErrors[index].kgLtQuantity}</p>
                                                )}
                                            </td>
                                            <td>
                                                <button className="remove-button" type="button" onClick={() => handleRemoveRow(index)}>
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                                <button className="move-add-row-button" type="button" onClick={handleAddRow}>
                                    Add Row
                                </button>
                            </table>
                        </div>
                        <div class="move-center-button">
                            <button class="move-button">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default Move;