import React, { useState } from "react";
import axios from "axios";
import './Country.css';
import NavBar from "./NavBar.js";

export const Country = () => {
    const initialValues = {
        countryId: "",
        countryName: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const uppercaseValue = value.toUpperCase();
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        setFormValues({ ...formValues, [name]: uppercaseValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            try {
                const updatedFormData = { ...formValues, user };
                 const response = await axios.post("https://inventory-mtxj.onrender.com/common/country", updatedFormData);
                // const response = await axios.post("http://localhost:8084/common/country", updatedFormData);
                if (response.data.code === 200) {
                    alert(response.data.message);
                    window.location.href = "/import";
                }
            } catch (error) {
                alert(error.response.data.message);
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.countryId) {
            errors.countryId = "Country ID is required";
        }
        if (!values.countryName) {
            errors.countryName = "Country Name is required";
        }
        return errors;
    };

    return (
        <div className="country-form-container">
            <h1 className="country-form-title">Add Country</h1>
            <form className="country-form" onSubmit={handleSubmit}>
                <div className="country-field">
                    <label className="country-field-label">Country Id:</label>
                    <input
                        type="text"
                        placeholder="country Id"
                        name="countryId"
                        value={formValues.countryId}
                        onChange={handleChange}
                    />
                    {formErrors.countryId && <p className="country-error-message">{formErrors.countryId}</p>}
                </div>
                <div className="country-field">
                    <label className="country-field-label">Country Name:</label>
                    <input
                        type="text"
                        placeholder="country Name"
                        name="countryName"
                        value={formValues.countryName}
                        onChange={handleChange}
                    />
                    {formErrors.countryName && <p className="country-error-message">{formErrors.countryName}</p>}
                </div>
                <div className="country-submit-button-container">
                    <button className="country-submit" type="submit">
                        Create country
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Country;