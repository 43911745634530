import React, { useState, useEffect } from 'react';
import axios from "axios";
import './DailyOrderShort.css';

const DailyOrderShort = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState("Order");
  const [dailyOrderData, setDailyOrderData] = useState([]);
  const fetchData = async () => {
    try {
      const formattedDate = formatDate(selectedDate);
      const response = await axios.get(`https://inventory-mtxj.onrender.com/order/short?date=${formattedDate}&type=${selectedType}`);
      // const response = await axios.get(`http://localhost:8084/order/short?date=${formattedDate}&type=${selectedType}`);
      if (response.data.code === 200) {
        setDailyOrderData(response.data.getDailyShortResponse || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    fetchData();
  }, [selectedDate, selectedType]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="daily-order-short-container">
      <div className="daily-order-short-title">
        <div className="daily-order-short-company-name">Tanisa International Ltd</div>
        <div className="daily-order-short-big-bold-title">Daily Order Summary - Short</div>
      </div>
      <div className="daily-order-short-date-section">
        <div>
          <label>Select Date:</label>
          <input
            type="date"
            value={formatDate(selectedDate)}
            onChange={handleDateChange}
          />
        </div>
        <div>
          <label>Type</label>
          <select
            name="type"
            value={selectedType}
            onChange={handleTypeChange}
          >
            <option value="Order">Order</option>
            <option value="Delivery">Delivery</option>
          </select>
        </div>
      </div>
      <table className="daily-order-short-table">
        <thead>
          <tr>
            <th className="daily-order-short-small-column">Sl No</th>
            <th className="daily-order-short-bigger-item-column">Item</th>
            <th className="daily-order-short-small-column">Stock Out</th>
          </tr>
        </thead>
        <tbody>
          {dailyOrderData.map((row, index) => (
            <tr key={index + 1}>
              <td>{index + 1}</td>
              <td>{row.product}</td>
              <td>{row.stockOut}</td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>Grand Total (Stock Out)</td>
            <td>
              {dailyOrderData.reduce((total, row) => total + row.stockOut, 0)}
            </td>
          </tr>
        </tbody>
      </table>
      <button onClick={handlePrint} className="daily-order-short-print-button">
        Print
      </button>
    </div>
  );
};

export default DailyOrderShort;
