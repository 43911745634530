import React, { useState, useEffect } from 'react';
import axios from "axios";
import './DailyOrder.css';

const DailyOrder = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState("Order");
  const [dailyOrderData, setDailyOrderData] = useState([]);
  const [uniqueCustomer, setUniqueCustomer] = useState([]);

  const fetchData = async () => {
    try {
      const formattedDate = formatDate(selectedDate);
      const response = await axios.get(`https://inventory-mtxj.onrender.com/order/daily?date=${formattedDate}&type=${selectedType}`);
      // const response = await axios.get(`http://localhost:8084/order/daily?date=${formattedDate}&type=${selectedType}`);
      if (response.data.code === 200) {
        setDailyOrderData(response.data.orderResponseList || []);
        setUniqueCustomer(response.data.uniqueName || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    fetchData();
  }, [selectedDate, selectedType]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="daily-order-container">
      <div className="daily-order-title">
        <div className="company-name">Tanisa International Ltd</div>
        <div className="big-bold-title">Daily Order Summary</div>
      </div>
      <div className="date-section">
        <div>
          <label>Select Date:</label>
          <input
            type="date"
            value={formatDate(selectedDate)}
            onChange={handleDateChange}
          />
        </div>
        <div>
          <label>Type</label>
          <select
            name="type"
            value={selectedType}
            onChange={handleTypeChange}
          >
            <option value="Order">Order</option>
            <option value="Delivery">Delivery</option>
          </select>
        </div>
      </div>
     
      <table className="daily-order-table">
        <thead>
          <tr>
            <th className="customer-name-cell">Sl No</th>
            <th>Item</th>
            {uniqueCustomer.map((name) => (
              <th key={name} className="customer-name-cell">
                <div className="vertical-text">{name}</div>
              </th>
            ))}
            <th className="grand-total-item">Grand Total (Item)</th>
          </tr>
        </thead>
        <tbody>
          {dailyOrderData.reduce((uniqueProducts, product) => {
            if (!uniqueProducts.some((p) => p.product === product.product)) {
              uniqueProducts.push(product);
            }
            return uniqueProducts;
          }, []).map((uniqueProduct, index) => (
            <tr key={index + 1}>
              <td>{index + 1}</td>
              <td>{uniqueProduct.product}</td>
              {uniqueCustomer.map((customer) => (
                <td key={customer}>
                  {dailyOrderData
                    .filter((innerCustomer) => innerCustomer.customer === customer && innerCustomer.product === uniqueProduct.product)
                    .reduce((total, innerCustomer) => total + innerCustomer.quantity, '')}
                </td>
              ))}
              <td>
                {dailyOrderData
                  .filter((innerCustomer) => innerCustomer.product === uniqueProduct.product)
                  .reduce((total, innerCustomer) => total + innerCustomer.quantity, 0)}
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>Grand Total (Customer)</td>
            {uniqueCustomer.map((customer) => (
              <td key={customer}>
                {dailyOrderData
                  .filter((innerCustomer) => innerCustomer.customer === customer)
                  .reduce((total, innerCustomer) => total + innerCustomer.quantity, 0)}
              </td>
            ))}
            <td>
              {dailyOrderData.reduce((total, customer) => total + customer.quantity, 0)}
            </td>
          </tr>
        </tbody>
      </table>
      <button onClick={handlePrint} className="daily-order-print-button">
        Print
      </button>
    </div>
  );
};

export default DailyOrder;