import React, { useState, useEffect, useRef } from "react";
import "./Import.css";
import axios from "axios";
import NavBar from "./NavBar.js";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import Product from "./Product.js";
import Brand from "./Brand.js";
import Categories from "./Categories.js";
import Country from "./Country.js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Warehouse from "./Warehouse.js";

const animatedComponents = makeAnimated();

export const Import = () => {
    const [showProductModal, setShowProductModal] = useState(false);
    const [showCountryModal, setShowCountryModal] = useState(false);
    const [showBrandModal, setShowBrandModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showWarehouseModal, setShoWarehouseModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [importDetailsErrors, setImportDetailsErrors] = useState([{}]);
    const [countries, setCountry] = useState([]);
    const [products, setProduct] = useState([]);
    const [categories, setCategory] = useState([]);
    const [brands, setBrand] = useState([]);
    const [warehouses, setWarehouse] = useState([]);
    const [importMaster, setImportMasterData] = useState({
        shipmentNo: "",
        country: "",
        date: "",
        user: "",
        importDetailsRequestList: [],
    });
    const [importDetails, setImportDetails] = useState([
        {
            product: "",
            category: "",
            brand: "",
            production: "",
            importCountry: "",
            warehouse: "",
            expire: "",
            cartoon: "",
            piece: "",
            kgLt: "",
            uom: "",
            price: "",
            total: "",
        },
    ]);

    const initialImportDetail = {
        product: "",
        category: "",
        brand: "",
        production: "",
        importCountry: "",
        warehouse: "",
        expire: "",
        cartoon: "",
        piece: "",
        kgLt: "",
        uom: "",
        price: "",
        total: "",
    };
    const calculateGrandTotal = () => {
        return importDetails.reduce((total, detail) => total + Number(detail.total), 0).toFixed(2);
    };
    const fetchCountry = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/country`);
            // const response = await axios.get(`http://localhost:8084/common/country`);
            if (response.data.code === 200) {
                setCountry(response.data.countryResponseList);
            }
        } catch (error) {
            alert("Error fetching country data:", error);
        }
    };

    const fetchProduct = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/product`);
            // const response = await axios.get(`http://localhost:8084/common/product`);
            if (response.data.code === 200) {
                setProduct(response.data.allProductResponse);
            }
        } catch (error) {
            alert("Error fetching product data:", error);
        }
    };

    const fetchWarehouse = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/warehouse`);
            // const response = await axios.get(`http://localhost:8084/common/warehouse`);
            if (response.data.code === 200) {
                setWarehouse(response.data.warehouseList);
            }
        } catch (error) {
            alert("Error fetching warehouse data:", error);
        }
    };

    const fetchCategory = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/category`);
            // const response = await axios.get(`http://localhost:8084/common/category`);
            if (response.data.code === 200) {
                setCategory(response.data.categoryResponseList);
            }
        } catch (error) {
            alert("Error fetching category data:", error);
        }
    };

    const fetchBrand = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/brand`);
            // const response = await axios.get(`http://localhost:8084/common/brand`);
            if (response.data.code === 200) {
                setBrand(response.data.brandResponseList);
            }
        } catch (error) {
            alert("Error fetching category data:", error);
        }
    };

    const validateFormData = (values) => {
        const errors = {};
        if (!values.shipmentNo) {
            errors.shipmentNo = "Shipment No is required";
        }
        if (!values.country) {
            errors.country = "Country is required";
        }
        if (!values.date) {
            errors.date = "Date is required";
        }
        return errors;
    };

    const handleChange = (name, selectedOption) => {
        let uppercaseValue = selectedOption;
        if (typeof selectedOption === 'object' && selectedOption.label) {
            uppercaseValue = { label: selectedOption.label.toUpperCase(), value: selectedOption.value };
        }

        setImportMasterData((prevValues) => ({
            ...prevValues,
            [name]: uppercaseValue || '',
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateFormData(importMaster);
        setFormErrors(errors);
        const detailsErrors = importDetails.map((detail) => validateImportDetail(detail));
        setImportDetailsErrors(detailsErrors);
        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            callYourAPI(importMaster, user);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const callYourAPI = async (importMaster, user) => {
        try {
            // Format date in importMaster
            const formattedDate = importMaster.date ? formatDate(importMaster.date) : null;

            // Format dates in each detail of importDetails
            const formattedImportDetails = importDetails.map((detail) => ({
                ...detail,
                production: detail.production ? formatDate(new Date(detail.production)) : null,
                expire: detail.expire ? formatDate(new Date(detail.expire)) : null,
            }));

            const updatedFormData = {
                ...importMaster,
                user,
                importDetailsRequestList: formattedImportDetails,
                date: formattedDate,
            };

            console.log(updatedFormData);
            const response = await axios.post("https://inventory-mtxj.onrender.com/import", updatedFormData);
            // const response = await axios.post("http://localhost:8084/import", updatedFormData);

            if (response && response.data && response.data.code === 200) {
                alert(response.data.message);
                window.location.href = "/import";
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setFormErrors({ serverError: error.response.data.message });
            } else {
                console.error(error.response.data.message);
            }
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchCountry();
        fetchProduct();
        fetchCategory();
        fetchBrand();
        fetchWarehouse();
    }, [formErrors]);

    const handleRemoveRow = (index) => {
        if (importDetails.length > 1) {
            const updatedImportDetails = [...importDetails];
            updatedImportDetails.splice(index, 1);
            setImportDetails(updatedImportDetails);
        } else {
            alert("Cannot remove the only row");
        }
    };

    const handleChangeDetail = (index, name, value) => {
        const updatedImportDetails = [...importDetails];
        const updatedImportDetailsErrors = [...importDetailsErrors];
        updatedImportDetailsErrors[index][name] = "";
        const stringValue = typeof value === 'string' ? value.toUpperCase() : value;
        updatedImportDetails[index][name] = stringValue;
        const total = calculateTotal(updatedImportDetails[index]);
        updatedImportDetails[index].total = total;
        setImportDetails(updatedImportDetails);
        setImportDetailsErrors(updatedImportDetailsErrors);
    };


    const calculateTotal = (detail) => {
        if (!detail.price || (!detail.cartoon && !detail.piece && !detail.kgLt)) {
            return 0;
        }
        const price = parseFloat(detail.price);
        const uom = detail.uom;
        if (!uom) {
            alert("Please select a valid UOM");
            return 0;
        }
        let quantity;
        switch (uom) {
            case "CARTOON":
                quantity = detail.cartoon || 0;
                break;
            case "KG":
                quantity = detail.kgLt || 0;
                break;
            case "PCS":
                quantity = detail.piece || 0;
                break;
            case "LT":
                quantity = detail.kgLt || 0;
                break;
            default:
                alert("Invalid UOM");
                return 0;
        }
        return quantity * price;
    };

    const handleAddRow = () => {
        const newRowErrors = importDetails.map((detail) => validateImportDetail(detail));
        const hasErrors = newRowErrors.some((errors) => Object.keys(errors).length > 0);

        if (hasErrors) {
            setImportDetailsErrors(newRowErrors);
        } else {
            setImportDetails([...importDetails, { ...initialImportDetail }]);
            setImportDetailsErrors([...importDetailsErrors, {}]);
        }
    };

    const validateImportDetail = (detail) => {
        const errors = {};
        if (!detail.product) {
            errors.product = "Product is required";
        }
        if (!detail.category) {
            errors.category = "Category is required";
        }
        if (!detail.brand) {
            errors.brand = "Brand is required";
        }
        if (!detail.importCountry) {
            errors.importCountry = "Country is required";
        }
        if (!detail.warehouse) {
            errors.warehouse = "Warehouse is required";
        }
        if (!detail.cartoon) {
            errors.cartoon = "Cartoon is required";
        }
        if (!detail.piece) {
            errors.piece = "Piece is required";
        }
        if (!detail.kgLt) {
            errors.kgLt = "Kg/Lt is required";
        }
        if (!detail.uom) {
            errors.uom = "UOM is required";
        }
        if (!detail.price) {
            errors.price = "Price is required";
        }
        if (!detail.total) {
            errors.total = "Total is required";
        }
        return errors;
    };

    const handleOpenProductModal = () => {
        setShowProductModal(true);
    };
    const handleCloseProductModal = () => {
        setShowProductModal(false);
    };
    const handleOpenCountryModal = () => {
        setShowCountryModal(true);
    };
    const handleCloseCountryModal = () => {
        setShowCountryModal(false);
    };
    const handleOpenCategoryModal = () => {
        setShowCategoryModal(true);
    };
    const handleCloseCategoryModal = () => {
        setShowCategoryModal(false);
    };
    const handleOpenBrandModal = () => {
        setShowBrandModal(true);
    };
    const handleCloseBrandModal = () => {
        setShowBrandModal(false);
    };
    const handleOpenWarehouseModal = () => {
        setShoWarehouseModal(true);
    };
    const handleCloseWarehouseModal = () => {
        setShoWarehouseModal(false);
    };
    function formatCurrency(amount) {
        if (isNaN(amount)) {
            return amount;
        }
        return Number(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            maxHeight: '200px',
            overflowY: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '200px',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    return (
        <div className="import-container">
            <NavBar />
            <main className="import-main-content">
                <header className="import-header">
                    <a className="import-company-name">Tanisa International LTD</a>
                </header>
                <div className="import-action-buttons">
                    <button className="import-product" onClick={handleOpenProductModal}>Product</button>
                    <button className="import-category" onClick={handleOpenCategoryModal}>Category</button>
                    <button className="import-country" onClick={handleOpenCountryModal}>Country</button>
                    <button className="import-brand" onClick={handleOpenBrandModal}>Brand</button>
                    <button className="import-warehouse" onClick={handleOpenWarehouseModal}>Warehouse</button>
                </div>
                <div className="import-form-container">
                    <h1 className="import-form-title">Add Import</h1>
                    <form className="import-form" onSubmit={handleSubmit}>
                        <div className="import-Common-fields-container">
                            <div className='import-row'>

                                <div className="import-field">
                                    <label className="import-field-label">Country:</label>
                                    <Select
                                        name="country"
                                        components={{
                                            ...animatedComponents,
                                        }}
                                        value={importMaster.country}
                                        onChange={(selectedOption) => handleChange("country", selectedOption)}
                                        options={countries.map((c) => ({ label: c.countryName, value: c.countryId }))}
                                        isSearchable
                                        placeholder="Select Country"
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        menuPlacement='auto'
                                    />
                                    {formErrors.country && <p className="import-error-message">{formErrors.country}</p>}
                                </div>
                                <div className="import-field">
                                    <label className="import-field-label">Shipment No:</label>
                                    <input
                                        type="text"
                                        placeholder="Shipment No"
                                        name="shipmentNo"
                                        id="shipmentNo"
                                        value={importMaster.shipmentNo}
                                        onChange={(e) => handleChange("shipmentNo", e.target.value)}
                                        
                                    />
                                    {formErrors.shipmentNo && <p className="import-error-message">{formErrors.shipmentNo}</p>}
                                </div>

                                <div className="import-field">
                                    <label className="import-field-label">Date:</label>
                                    <DatePicker
                                        selected={importMaster.date}
                                        onChange={(date) => handleChange("date", date)}
                                        placeholderText="Date"
                                        dateFormat="dd-MM-yyyy"  // Adjust the date format as needed
                                        
                                    />
                                    {formErrors.date && <p className="import-error-message">{formErrors.date}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="import-table-container">
                            <table className="import-table">
                                <thead>
                                    <th>Product Name</th>
                                    <th>Category</th>
                                    <th>Brand</th>
                                    <th>Production</th>
                                    <th>Country</th>
                                    <th>Warehouse</th>
                                    <th>Expire</th>
                                    <th>Cartoon</th>
                                    <th>Piece</th>
                                    <th>Kg/Lt</th>
                                    <th>Uom</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {importDetails.map((detail, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Select
                                                    name="product"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.product}
                                                    onChange={(value) => handleChangeDetail(index, "product", value)}
                                                    options={products.map((p) => ({ label: p.productName, value: p.productId }))}
                                                    isSearchable
                                                    placeholder="Select Product"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {importDetailsErrors[index].product && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].product}</p>
                                                )}
                                            </td>
                                            <td>
                                                <Select
                                                    name="category"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.category}
                                                    onChange={(value) => handleChangeDetail(index, "category", value)}
                                                    options={categories.map((c) => ({ label: c.categoryName, value: c.categoryId }))}
                                                    isSearchable
                                                    placeholder="Select Category"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {importDetailsErrors[index].category && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].category}</p>
                                                )}
                                            </td>
                                            <td>
                                                <Select
                                                    name="brand"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.brand}
                                                    onChange={(value) => handleChangeDetail(index, "brand", value)}
                                                    options={brands.map((b) => ({ label: b.brandName, value: b.brandId }))}
                                                    isSearchable
                                                    placeholder="Select Brand"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {importDetailsErrors[index].brand && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].brand}</p>
                                                )}
                                            </td>
                                            <td>
                                                <DatePicker
                                                    selected={detail.production}
                                                    onChange={(date) => handleChangeDetail(index, "production", date)}
                                                    placeholderText="Production Date"
                                                    dateFormat="dd-MM-yyyy"
                                                   
                                                />
                                            </td>
                                            <td>
                                                <Select
                                                    name="importCountry"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.importCountry}
                                                    onChange={(selectedOption) => handleChangeDetail(index, "importCountry", selectedOption)}
                                                    options={countries.map((c) => ({ label: c.countryName, value: c.countryId }))}
                                                    isSearchable
                                                    placeholder="Select Country"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {importDetailsErrors[index].country && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].country}</p>
                                                )}
                                            </td>
                                            <td>
                                                <Select
                                                    name="warehouse"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.warehouse}
                                                    onChange={(value) => handleChangeDetail(index, "warehouse", value)}
                                                    options={warehouses.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                                                    isSearchable
                                                    placeholder="Select Warehouse"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {importDetailsErrors[index].warehouse && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].warehouse}</p>
                                                )}
                                            </td>
                                            <td>
                                                <DatePicker
                                                    selected={detail.expire}
                                                    onChange={(date) => handleChangeDetail(index, "expire", date)}
                                                    placeholderText="Expiration Date"
                                                    dateFormat="dd-MM-yyyy"
                                                    
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    name="cartoon"
                                                    value={detail.cartoon}
                                                    onChange={(e) => handleChangeDetail(index, "cartoon", e.target.value)}
                                                    
                                                />
                                                {importDetailsErrors[index].cartoon && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].cartoon}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="piece"
                                                    value={detail.piece}
                                                    onChange={(e) => handleChangeDetail(index, "piece", e.target.value)}
                                                />
                                                {importDetailsErrors[index].piece && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].piece}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="kglt"
                                                    value={detail.kgLt}
                                                    onChange={(e) => handleChangeDetail(index, "kgLt", e.target.value)}
                                                />
                                                {importDetailsErrors[index].kgLt && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].kgLt}</p>
                                                )}
                                            </td>
                                            <td>
                                                <select
                                                    name="uom"
                                                    value={detail.uom}
                                                    onChange={(e) => handleChangeDetail(index, "uom", e.target.value)}
                                                >
                                                    <option value="">UOM</option>
                                                    <option value="KG">KG</option>
                                                    <option value="LT">LT</option>
                                                    <option value="PCS">PCS</option>
                                                    <option value="CARTOON">CARTOON</option>
                                                </select>
                                                {importDetailsErrors[index].uom && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].uom}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="price"
                                                    value={detail.price}
                                                    onChange={(e) => handleChangeDetail(index, "price", e.target.value)}
                                                />
                                                {importDetailsErrors[index].price && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].price}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="total"
                                                    readOnly={true}
                                                    value={` ${formatCurrency(detail.total)}৳`}
                                                    onChange={(e) => handleChangeDetail(index, "total", e.target.value)}
                                                />
                                                {importDetailsErrors[index].total && (
                                                    <p className="import-detail-error-message">{importDetailsErrors[index].total}</p>
                                                )}
                                            </td>
                                            <td>
                                                <button className="remove-button" type="button" onClick={() => handleRemoveRow(index)}>
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tr>
                                    <td colSpan="12">Grand Total</td>
                                    <td colSpan="2">{formatCurrency(calculateGrandTotal())} &#2547;</td>
                                </tr>

                                <button className="import-add-row-button" type="button" onClick={handleAddRow}>
                                    Add Row
                                </button>
                            </table>
                        </div>
                        <div class="import-center-button">
                            <button class="import-button">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </main>
            {showProductModal && (
                <div className="import-modal">
                    <div className="import-modal-content">
                        <span className="import-close" onClick={handleCloseProductModal}>&times;</span>
                        <Product />
                    </div>
                </div>
            )}
            {showBrandModal && (
                <div className="import-modal">
                    <div className="import-modal-content">
                        <span className="import-close" onClick={handleCloseBrandModal}>&times;</span>
                        <Brand />
                    </div>
                </div>
            )}
            {showCountryModal && (
                <div className="import-modal">
                    <div className="import-modal-content">
                        <span className="import-close" onClick={handleCloseCountryModal}>&times;</span>
                        <Country />
                    </div>
                </div>
            )}
            {showCategoryModal && (
                <div className="import-modal">
                    <div className="import-modal-content">
                        <span className="import-close" onClick={handleCloseCategoryModal}>&times;</span>
                        <Categories />
                    </div>
                </div>
            )}
            {showWarehouseModal && (
                <div className="import-modal">
                    <div className="import-modal-content">
                        <span className="import-close" onClick={handleCloseWarehouseModal}>&times;</span>
                        <Warehouse />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Import;