import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./EditBill.css";

const EditBill = ({ orderId }) => {
    const [billDetails, setBillDetails] = useState(null);
    const [commonDetails, setCommonDetails] = useState(null);
    const [editingDetails, setEditingDetails] = useState(null);

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        const fetchBillDetails = async () => {
            try {
                const response = await axios.get(`https://inventory-mtxj.onrender.com/order/bill/details?order=${orderId}`);
                // const response = await axios.get(`http://localhost:8084/order/bill/details?order=${orderId}`);
                if (response.data.code === 200) {
                    setBillDetails(response.data.singleBillsDetailsResponses);
                    setCommonDetails(response.data.singleBillResponse);
                    setEditingDetails(response.data.singleBillsDetailsResponses.map(row => ({ ...row })));
                }
            } catch (error) {
                console.error('Error fetching Bill details:', error);
            }
        };
        fetchBillDetails();
    }, [orderId]);

    const handleEditChange = (e, field, index) => {
        const updatedDetails = [...editingDetails];
        updatedDetails[index][field] = e.target.value;
        if (field === 'quantity' || field === 'discount' || field === 'price') {
            const quantity = field === 'quantity' ? e.target.value : updatedDetails[index].quantity;
            const discount = field === 'discount' ? e.target.value : updatedDetails[index].discount;
            if (discount != null) {
                updatedDetails[index].total = (quantity - discount) * updatedDetails[index].price;
            } else {
                updatedDetails[index].total = quantity * updatedDetails[index].price;
            }
        }
        setEditingDetails(updatedDetails);
    };

    const handleSave = async () => {
        try {
            const user = localStorage.getItem('userEmail');

            const invalidIndex = editingDetails.findIndex((item) => {
                return item.discount != null && item.quantity < item.discount;
            });

            if (invalidIndex !== -1) {
                const invalidProduct = editingDetails[invalidIndex].product;
                alert("Discount can't be greater than quantity at product " + invalidProduct);
                return;
            }

            // Map editingDetails to EditOrderRequest objects
            const editOrderRequests = editingDetails.map(detail => ({
                product: detail.product,
                quantity: detail.discount != null ? (detail.quantity - detail.discount) : detail.quantity,
                discount: detail.discount != null ? detail.discount : 0,
                piece: detail.piece,
                price: detail.price,
                total: detail.total,
                vat: detail.vat,
                user: user
            }));

            const response = await axios.put(`https://inventory-mtxj.onrender.com/order?order=${orderId}`, editOrderRequests);
            // const response = await axios.put(`http://localhost:8084/order?order=${orderId}`, editOrderRequests);
            if (response.data.code === 200) {
                alert(response.data.message);
                window.location.href = "/bill";
            }
        } catch (error) {
            console.error('Error updating Bill details:', error);
        }
    };

    return (
        <div className="edit-bill-modal-container">
            <h1>Edit Order</h1>
            {commonDetails && billDetails && editingDetails ? (
                <>
                    <div className="edit-bill-top-elements-container">
                        <div>Supplier Code: {commonDetails.supplier}</div>
                        <div>Invoice No: {commonDetails.orderId}</div>
                        <div>Date: {commonDetails.orderDate}</div>
                        <div>Bill To: {commonDetails.billTo}</div>
                    </div>

                    <table className="edit-bill-modal-table">
                        <thead>
                            <tr>
                                <th>Sl No</th>
                                <th>Product Name</th>
                                <th>UOM</th>
                                <th>Article</th>
                                <th>Sale Code</th>
                                <th>Vat</th>
                                <th>Quantity</th>
                                <th>Piece</th>
                                <th>Discount</th>
                                <th>Unit Price</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(editingDetails) && editingDetails.map((row, index) => (
                                <tr key={index + 1}>
                                    <td>{index + 1}</td>
                                    <td>{row.product}</td>
                                    <td>{row.uom}</td>
                                    <td>{row.article}</td>
                                    <td>{row.sale}</td>
                                    <td>
                                        <input type="text" value={row.vat} onChange={(e) => handleEditChange(e, 'vat', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={row.quantity} onChange={(e) => handleEditChange(e, 'quantity', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={row.piece} onChange={(e) => handleEditChange(e, 'piece', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={row.discount} onChange={(e) => handleEditChange(e, 'discount', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={row.price} onChange={(e) => handleEditChange(e, 'price', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={row.total} onChange={(e) => handleEditChange(e, 'total', index)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <button onClick={handleSave}>Edit Order</button>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default EditBill;