import React from "react";
import "./Report.css";
import NavBar from "./NavBar.js";

const handleProductClick = () => {
  window.location.href = "/product_details";
};

const handleRevenueClick = () => {
  window.location.href = "/revenue";
};

const handleWastageClick = () => {
  window.location.href = "/all_wastage";
};

const handleReturnClick = () => {
  window.location.href = "/all_return";
};

const handleRequisitionClick = () => {
  window.location.href = "/all_requisition";
};

const handleCustomerClick = () => {
  window.location.href = "/all_customer";
};

const handleMoveClick = () => {
  window.location.href = "/move_details";
};

const handleBranchClick = () => {
  window.location.href = "/all_branch";
};

export const Report = () => {
  return (
    <div className="inventory-container">
      <NavBar />
      <main className="inventory-main-content">
        <header className="inventory-header">
          <a className="inventory-company-name">Tanisa International LTD</a>
        </header>
        <div className="inventory-action-buttons">
          <button className="inventory-revenue" onClick={handleRevenueClick}>Stock Value</button>
          <button className="inventory-customer" onClick={handleCustomerClick}>Customer Details</button>
          <button className="inventory-branch" onClick={handleBranchClick}>Branch Details</button>
          <button className="inventory-product-details" onClick={handleProductClick}>Product Details</button>
          <button className="inventory-move-details" onClick={handleMoveClick}>Move Details</button>
          <button className="inventory-wastage" onClick={handleWastageClick}>Wastage Details</button>
          <button className="inventory-return" onClick={handleReturnClick}>Return Details</button>
          <button className="inventory-requisition" onClick={handleRequisitionClick}>Requisition Details</button>
        </div>
      </main>
    </div>
  );
};

export default Report;