import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './BillModal.css';

const BillModal = () => {
  const [billDetails, setBillDetails] = useState(null);
  const [commonDetails, setCommonDetails] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('order');
  useEffect(() => {
    const fetchBillDetails = async () => {
      try {
        const response = await axios.get(`https://inventory-mtxj.onrender.com/order/bill/details?order=${orderId}`);
        // const response = await axios.get(`http://localhost:8084/order/bill/details?order=${orderId}`);
        if (response.data.code === 200) {
          setBillDetails(response.data.singleBillsDetailsResponses);
          setCommonDetails(response.data.singleBillResponse);
        }
      } catch (error) {
        console.error('Error fetching Bill details:', error);
      }
    };
    fetchBillDetails();
  }, [orderId]);

  const numberToWords = (num) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convert = (num) => {
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
      if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + convert(num % 100) : '');
      if (num < 1000000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + convert(num % 1000) : '');
      if (num < 1000000000) return convert(Math.floor(num / 1000000)) + ' Million' + (num % 1000000 !== 0 ? ' ' + convert(num % 1000000) : '');
      return 'Number too large';
    };


    return convert(num);
  };

  const totalAmountInWords = (amount) => {
    const amountInWords = numberToWords(amount);
    return `Taka ${amountInWords} Only.`;
  };

  // Ensure there are always 25 rows, fill with empty data if needed
  const paddedBillDetails = billDetails
    ? billDetails.concat(Array(25 - Math.min(billDetails.length, 25)).fill({}))
    : Array(25).fill({});

  const handlePrint = () => {
    window.print();
  };
  function formatCurrency(amount) {
    // Check if the amount is a valid number
    if (isNaN(amount)) {
      return amount;
    }

    // Format the amount with commas and two decimal places
    return Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
  return (
    <div className="bill-modal-container">
      {commonDetails && paddedBillDetails ? (
        <>
          <div className="bill-top-elements-container">
            <div>Supplier Code: {commonDetails.supplier}</div>
            <div>Invoice No: {commonDetails.orderId}</div>
            <div>Date: {commonDetails.deliveryDate}</div>
            {<div>Bill To: {`${commonDetails.billTo}`}</div>}
          </div>

          <table className="bill-modal-table">
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Product Name</th>
                <th>UOM</th>
                <th>Article</th>
                <th>Sale Code</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(paddedBillDetails) &&
                paddedBillDetails.map((row, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{row.product}</td>
                    <td>{row.uom}</td>
                    <td>{row.article}</td>
                    <td>{row.sale}</td>
                    <td>{Number.isInteger(row.quantity) ? row.quantity.toFixed(2) : row.quantity}</td>
                    <td>{formatCurrency(row.price)}</td>
                    <td >{formatCurrency(row.total)}</td>
                  </tr>
                ))}
              <tr>
                <td colSpan="7">Total</td>
                <td style={{ textAlign: 'right' }}>
                  {formatCurrency(
                    paddedBillDetails.reduce((total, row) => total + (row.total || 0), 0)
                  )}
                </td>
              </tr>

              <tr>
                <td colSpan="8">
                  (In Words):{' '}
                  {totalAmountInWords(
                    paddedBillDetails.reduce(
                      (total, row) => total + (row.total || 0),
                      0
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="signature-container">
            <div className="signature">
              ____________________
              <div>Receiving, Signature, Seal & Date:</div>
            </div>
            <div className="signature">
              ____________________
              <div>Thanks With Best Regards:</div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
      <div className="button-container">
        <button className="bill-modal-print-button" onClick={handlePrint}>
          Print
        </button>
      </div>
    </div>
  );
};

export default BillModal;
