import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import './DailyOrderTable.css';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DailyOrderTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setOrderTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/order/table`);
      // const response = await axios.get(`http://localhost:8084/order/table`);
      if (response.data.code === 200) {
        setOrderTableData(response.data.getDailyOrderProductResponseList || []);
        setFilteredData(response.data.getDailyOrderProductResponseList || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = '/';
    }
    fetchData();
  }, []);

  const handleXlsxClick = () => {
    const customFileName = prompt('Enter the file name') || 'report';
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const uniqueFileName = `${customFileName}_${timestamp}`;

    const wsData = [
      ...filteredData.map((row, index) => ({
      'SL NO': index + 1,
      'Order Date': row.orderDate,
      'Order By': row.orderBy,
      'Delivery Date': row.deliveryDate,
      'Order ID': row.invoice,
      'Customer': row.customer,
      'Branch': row.branch,
      'Item': row.item,
      'Uom': row.uom,
      'Quantity': row.quantity,
      'Piece': row.piece,
      'Unit Price': row.unitPrice,
      'Total Bill': row.totalBill,
      'Grand Total': row.grandTotal,
      'Item Discounted': row.itemDiscount,
    })),
    {
      'Unit Price': 'Total',
      'Total Bill': totalBill,
      'Grand Total': grandTotal,
    },
  ]
    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const xlsxBinaryArray = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    const xlsxBlob = new Blob([xlsxBinaryArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const xlsxUrl = URL.createObjectURL(xlsxBlob);
    const a = document.createElement('a');
    a.href = xlsxUrl;
    a.download = `${uniqueFileName}.xlsx`;
    a.click();
  };

  const totalBill = filteredData.reduce((acc, row) => acc + Number(row.totalBill), 0)
    .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const grandTotal = filteredData.reduce((acc, row) => acc + Number(row.grandTotal), 0)
    .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  function formatCurrency(amount) {
    if (isNaN(amount)) {
      return amount;
    }

    return Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  useEffect(() => {
    const updatedFilteredData = data.filter((row) => {
      const matchesSearchTerm =
        row.orderDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.orderBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.deliveryDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.invoice.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.branch.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.item.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesSearchTerm;
    });

    setFilteredData(updatedFilteredData);
  }, [data, searchTerm]);

  useEffect(() => {
    const updatedFilteredData = data.filter((item) => {
      // Convert item.orderDate to Date object
      const deliveryDateParts = item.deliveryDate.split('-');
      const deliveryDate = new Date(deliveryDateParts[2], deliveryDateParts[1] - 1, deliveryDateParts[0]);
  
      const from = fromDate ? new Date(fromDate.setHours(0, 0, 0, 0)) : null;
      const to = toDate ? new Date(toDate.setHours(23, 59, 59, 999)) : null;
  
      if (from && to) {
        return deliveryDate >= from && deliveryDate <= to;
      } else if (from) {
        return deliveryDate >= from;
      } else if (to) {
        return deliveryDate <= to;
      }
  
      return true;
    });
    
    setFilteredData(updatedFilteredData);
  }, [data, fromDate, toDate]);

  return (
    <div className="daily-order-table-container">
      <h2>Daily Order Table</h2>
      <div className='daily-order-table-controls'>
        <label>From: </label>
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select start date"
        />
        <label>To: </label>
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select end date"
        />
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <button className='daily-order-action-button' type="button" onClick={handleXlsxClick}>
        XLSX
      </button>

      <table className="daily-order-details-table">
        <thead>
          <tr>
            <th>SL NO</th>
            <th>Order Date</th>
            <th>Order By</th>
            <th>Delivery Date</th>
            <th>Order Id</th>
            <th>Customer</th>
            <th>Branch</th>
            <th>Item</th>
            <th>Uom</th>
            <th>Quantity</th>
            <th>Piece</th>
            <th>Unit Price</th>
            <th>Total Bill</th>
            <th>Grand Total</th>
            <th>Item Discounted</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((row, index) => (
            <tr key={index}>
              <td>{indexOfFirstItem + index + 1}</td>
              <td>{row.orderDate}</td>
              <td>{row.orderBy}</td>
              <td>{row.deliveryDate}</td>
              <td>{row.invoice}</td>
              <td>{row.customer}</td>
              <td>{row.branch}</td>
              <td>{row.item}</td>
              <td>{row.uom}</td>
              <td>{row.quantity}</td>
              <td>{row.piece}</td>
              <td>{formatCurrency(row.unitPrice)}</td>
              <td>{formatCurrency(row.totalBill)}</td>
              <td>{formatCurrency(row.grandTotal)}</td>
              <td>{formatCurrency(row.itemDiscount)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
      {filteredData.length === 0 && <p>No records to show.</p>}
      {filteredData.length > 0 && (
        <div className="daily-order-grand-total-container">
          <span colSpan="10">Total Bill:</span>
          <span colSpan="2">{formatCurrency(totalBill)}</span>
        </div>
      )}
      {filteredData.length > 0 && (
        <div className="daily-order-grand-total-container">
          <span colSpan="10">Grand Total:</span>
          <span colSpan="2">{formatCurrency(grandTotal)}</span>
        </div>
      )}
    </div>
  );
};

export default DailyOrderTable;