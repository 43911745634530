import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductDetails.css';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import Navbar from './NavBar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from './Pagination';
import PasswordModal from './PasswordModal';

const ProductDetails = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [data, setImportTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    function formatCurrency(amount) {
        if (isNaN(amount)) {
            return amount;
        }
        return Number(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/import`);
            // const response = await axios.get('http://localhost:8084/import');
            if (response.data.code === 200) {
                const importResponseList = response.data.importResponseList || [];
                setImportTableData(importResponseList);
                setFilteredData(importResponseList);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
    }, []);

    const handleXlsxClick = () => {
        const customFileName = prompt('Enter the file name') || 'report';
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const uniqueFileName = `${customFileName}_${timestamp}`;
        const wsData = [
            ...filteredData.map((row, index) => ({
                'SL NO': index + 1,
                'Shipment No': row.shipmentNo,
                'Shipment Country.': row.shipmentCountry,
                'Shipment Date': row.shipmentDate,
                'Product': row.product,
                'Category': row.category,
                'Brand': row.brand,
                'Production': row.production,
                'Country': row.country,
                'Warehouse': row.warehouse,
                'Expire': row.expire,
                'Carton': row.cartoon,
                'Piece': row.piece,
                'Kg/Lt': row.kgLt,
                'Uom': row.uom,
                'Unit Price': row.price,
                'Total Carton': row.totalCarton,
                'Total Piece': row.totalPiece,
                'Total Kg/Lt': row.totalKgLt,
                'Total': row.total,
            })),
            {
                'Total Kg/Lt': 'Grand Total',
                'Total': grandTotal,
            },
        ];

        const ws = XLSX.utils.json_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const xlsxBinaryArray = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });

        const xlsxBlob = new Blob([xlsxBinaryArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const xlsxUrl = URL.createObjectURL(xlsxBlob);
        const a = document.createElement('a');
        a.href = xlsxUrl;
        a.download = `${uniqueFileName}.xlsx`;
        a.click();
    };
    
    const updateProductDetails = async (id, user) => {
        const carton = prompt('Enter carton:');
        const piece = prompt('Enter piece:');
        const kg = prompt('Enter kg:');

        if (!carton || isNaN(carton) || !piece || isNaN(piece) || !kg || isNaN(kg)) {
            alert('Please enter valid numbers for carton, piece, and kg.');
            return;
        }

        const confirmUpdate = window.confirm('Are you sure you want to update this product details?');
        if (confirmUpdate) {
            updateProductDetailsApiCall(id, user, carton, piece, kg);
        }
    };

    const updateProductDetailsApiCall = async (id, user, carton, piece, kg) => {
        try {
            const response = await axios.put(`https://inventory-mtxj.onrender.com/import?id=${id}&user=${user}&carton=${carton}&piece=${piece}&kgLt=${kg}`);
            // const response = await axios.put(`http://localhost:8084/import?id=${id}&user=${user}&carton=${carton}&piece=${piece}&kgLt=${kg}`);
            if (response.data.code === 200) {
                alert('Product details updated successfully.');
                window.location.href = "/product_details";
            } else {
                alert('Error updating product details.');
            }
        } catch (error) {
            alert('Error updating product details. Please try again.');
        }
    };

    const deleteProductDetails = (id, user) => {
        setDeleteInfo({ id, user });
        setIsModalOpen(true);
    };

    const handleModalSubmit = (password) => {
        if (!password) {
            alert('Password is required.');
            return;
        }

        if (password !== 'Flagship@2024') {
            alert('Password not matched');
            return;
        }

        const confirmDelete = window.confirm('Are you sure you want to delete this product details?');
        if (confirmDelete && deleteInfo) {
            deleteProductDetailsApiCall(deleteInfo.id, deleteInfo.user);
        }

        setIsModalOpen(false);
        setDeleteInfo(null);
    };

    const deleteProductDetailsApiCall = async (id, user) => {
        try {
            const response = await axios.delete(`https://inventory-mtxj.onrender.com/import?id=${id}&user=${user}`);
            // const response = await axios.delete(`http://localhost:8084/import?id=${id}&user=${user}`);
            if (response.data.code === 200) {
                alert('Product details deleted successfully.');
                window.location.href = "/product_details";
            } else {
                alert('Error deleting product details.');
            }
        } catch (error) {
            alert('Error deleting product details. Please try again.');
        }
    };

    useEffect(() => {
        const updatedFilteredData = data.filter((item) => {
          // Convert item.shipmentdate to Date object
          const shipmentParts = item.shipmentDate.split('-');
          const shipmentDate = new Date(shipmentParts[2], shipmentParts[1] - 1, shipmentParts[0]);
      
          const from = fromDate ? new Date(fromDate.setHours(0, 0, 0, 0)) : null;
          const to = toDate ? new Date(toDate.setHours(23, 59, 59, 999)) : null;
      
          if (from && to) {
            return shipmentDate >= from && shipmentDate <= to;
          } else if (from) {
            return shipmentDate >= from;
          } else if (to) {
            return shipmentDate <= to;
          }
      
          return true;
        });
        
        setFilteredData(updatedFilteredData);
      }, [data, fromDate, toDate]);

    useEffect(() => {
        const updatedFilteredData = data.filter((item) => {
            const matchesSearchTerm =
                item.shipmentNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.shipmentCountry.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.warehouse.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.product.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesSearchTerm;
        });

        setFilteredData(updatedFilteredData);
    }, [data, searchTerm]);

    const grandTotal = filteredData.reduce((acc, row) => acc + Number(row.total), 0)
        .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return (
        <div className="product-details-table-container">
            <Navbar />
            <h2>Product Details</h2>
            <div className='product-table-controls'>
                <label>From: </label>
                <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select start date"
                />
                <label>To: </label>
                <DatePicker
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select end date"
                />
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <button className='product-action-buttons' type="button" onClick={handleXlsxClick}>
                XLSX
            </button>

            <table className="product-details-table">
                <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Shipment No</th>
                        <th>Shipment Country</th>
                        <th>Shipment Date</th>
                        <th>Product</th>
                        <th>Category</th>
                        <th>Brand</th>
                        <th>Country</th>
                        <th>Warehouse</th>
                        <th>Production Date</th>
                        <th>Expiry Date</th>
                        <th>UOM</th>
                        <th>Cartoon</th>
                        <th>Piece</th>
                        <th>Kg/Lt</th>
                        <th>Unit Price</th>
                        <th>Total Carton</th>
                        <th>Total Piece</th>
                        <th>Total Kg/Lt</th>
                        <th>Total</th>
                        <th>Update</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{row.shipmentNo}</td>
                            <td>{row.shipmentCountry}</td>
                            <td>{row.shipmentDate}</td>
                            <td>{row.product}</td>
                            <td>{row.category}</td>
                            <td>{row.brand}</td>
                            <td>{row.country}</td>
                            <td>{row.warehouse}</td>
                            <td>{row.production}</td>
                            <td>{row.expire}</td>
                            <td>{row.uom}</td>
                            <td>{row.cartoon}</td>
                            <td>{row.piece}</td>
                            <td>{row.kgLt}</td>
                            <td>{row.price}</td>
                            <td>{row.totalCarton}</td>
                            <td>{row.totalPiece}</td>
                            <td>{row.totalKgLt}</td>
                            <td>{formatCurrency(row.total)}</td>
                            <td>
                                <button onClick={() => updateProductDetails(row.id, localStorage.getItem('userEmail'))}>
                                    Update
                                </button>
                            </td>
                            <td>
                                <button onClick={() => deleteProductDetails(row.id, localStorage.getItem('userEmail'))}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            {data.length === 0 && <p>No records to show.</p>}
            {filteredData.length > 0 && (
                <div className="product-grand-total-container">
                    <span colSpan="12">Grand Total:</span>
                    <span colSpan="2">{formatCurrency(grandTotal)}</span>
                </div>
            )}
            <PasswordModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
            />
        </div>
    );
};

export default ProductDetails;
