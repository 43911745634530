import React, { useState, useEffect } from "react";
import axios from "axios";
import './Sale.css';
import makeAnimated from "react-select/animated";
import Select from "react-select";

const animatedComponents = makeAnimated();

export const Sale = () => {
    const initialValues = {
        supplier: "",
        product: "",
        article: "",
        saleCode: "",
        user: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [products, setProduct] = useState([]);
    const [suppliers, setSupplier] = useState([]);

    const handleChange = (name, selectedOption) => {
        let uppercaseValue;

        if (selectedOption === null) {
            uppercaseValue = { label: "", value: "" };
        } else if (typeof selectedOption === 'string') {
            uppercaseValue = { label: selectedOption, value: selectedOption.toUpperCase() };
        } else if (selectedOption && typeof selectedOption === 'object' && selectedOption.label) {
            uppercaseValue = { ...selectedOption, label: selectedOption.label.toUpperCase() };
        } else {
            uppercaseValue = selectedOption;
        }
        if (name === 'article' || name === 'saleCode') {
            uppercaseValue = selectedOption.toUpperCase();
        }

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: uppercaseValue,
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            try {
                const updatedFormData = { ...formValues, user };
                console.log(updatedFormData);
                 const response = await axios.post("https://inventory-mtxj.onrender.com/common/sale", updatedFormData);
                //  const response = await axios.post("http://localhost:8084/common/sale", updatedFormData);
                if (response.data.code === 200) {
                    alert(response.data.message);
                    window.location.href = "/order";
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    if (error.response.data.message) {
                        alert(error.response.data.message)
                    }
                } else {
                    alert(error);
                }
            }
        }
    };

    const fetchProduct = async () => {
        try {
             const response = await axios.get(`https://inventory-mtxj.onrender.com/common/product`);
            //  const response = await axios.get(`http://localhost:8084/common/product`);
            if (response.data.code === 200) {
                setProduct(response.data.allProductResponse);
            }
        } catch (error) {
            alert("Error fetching product data:", error);
        }
    };

    const fetchSupplier = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/supplier`);
            //  const response = await axios.get(`http://localhost:8084/common/supplier`);
            if (response.data.code === 200) {
                setSupplier(response.data.supplierList);
            }
        } catch (error) {
            alert("Error fetching supplier data:", error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchProduct();
        fetchSupplier();
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        if (!values.supplier) {
            errors.supplier = "Supplier is required";
        }
        if (!values.product) {
            errors.product = "Product Name is required";
        }
        if (!values.article) {
            errors.article = "Article Code is required";
        }
        if (!values.saleCode) {
            errors.saleCode = "Sale Code is required";
        }
        return errors;
    };

    return (
        <div className="sale-form-container">
            <h1 className="sale-form-title">Add Sale Code</h1>
            <form className="sale-form" onSubmit={handleSubmit}>
                <div className="sale-field">
                    <label className="sale-field-label">Supplier Name:</label>
                    <Select
                    className="dropdown"
                        name="supplier"
                        components={{
                            ...animatedComponents,
                        }}
                        value={formValues.supplier}
                        onChange={(selectedOption) => handleChange("supplier", selectedOption)}
                        options={suppliers.map((s) => ({ label: s.supplierName, value: s.supplierId }))}
                        placeholder="Select Supplier"
                    />
                    {formErrors.supplier && <p className="sale-error-message">{formErrors.supplier}</p>}
                </div>
                <div className="sale-field">
                    <label className="sale-field-label">Product Name:</label>
                    <Select
                    className="dropdown"
                        name="product"
                        components={{
                            ...animatedComponents,
                        }}
                        value={formValues.product}
                        onChange={(selectedOption) => handleChange("product", selectedOption)}
                        options={products.map((p) => ({ label: p.productName, value: p.productId }))}
                        placeholder="Select Product"
                    />
                    {formErrors.product && <p className="sale-error-message">{formErrors.product}</p>}
                </div>
                <div className="sale-field">
                    <label className="sale-field-label">Article Code:</label>
                    <input
                        type="text"
                        placeholder="Article Code"
                        name="article"
                        value={formValues.article}
                        onChange={(e) => handleChange("article", e.target.value)}
                    />
                    {formErrors.article && <p className="sale-error-message">{formErrors.article}</p>}
                </div>
                <div className="sale-field">
                    <label className="sale-field-label">Sale Code:</label>
                    <input
                        type="text"
                        placeholder="Sale Code"
                        name="saleCode"
                        value={formValues.saleCode}
                        onChange={(e) => handleChange("saleCode", e.target.value)}
                    />
                    {formErrors.saleCode && <p className="sale-error-message">{formErrors.saleCode}</p>}
                </div>
                <div className="sale-submit-button-container">
                    <button className="sale-submit" type="submit">
                        Add Sale Code
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Sale;