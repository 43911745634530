import React, { useState } from "react";
import axios from "axios";
import './Pay.css';

export const Pay = ({ orderId, due, isPasswordRequired }) => {
    const initialValues = {
        payment: "",
        orderId: orderId,
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [password, setPassword] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        const numericPayment = parseFloat(value);
        const numericDue = parseFloat(due);
        if (numericPayment > numericDue) {
            alert(`Payment cannot exceed due amount. The total due is ${due}.`);
            return;
        }
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isPasswordRequired) {
            // Implement your password validation logic here
            const isPasswordCorrect = password === "faisalbhai";
            if (isPasswordCorrect) {
                const errors = validate(formValues);
                setFormErrors(errors);
                if (Object.keys(errors).length === 0) {
                    const userEmail = localStorage.getItem('userEmail');
                    console.log(formValues);
                    try {
                        const updatedFormData = { ...formValues, userEmail };
                        const response = await axios.post("https://inventory-mtxj.onrender.com/order/payment", updatedFormData);
                        // const response = await axios.post("http://localhost:8084/order/payment", updatedFormData);
                        if (response.data.code === 200) {
                            alert(response.data.message);
                            window.location.href = "/bill";
                        }
                    } catch (error) {
                        if (error.response && error.response.data && error.response.data.message) {
                            setFormErrors({ serverError: error.response.data.message });
                        } else {
                            console.error(error);
                        }
                    }
                }
            }
            else {
                alert("Incorrect password. Payment not allowed.");
                return;
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.payment) {
            errors.payment = "Payment value is required";
        }
        return errors;
    };

    return (
        <div className="pay-form-container">
            <h1 className="pay-form-title">Add Payment</h1>
            {isPasswordRequired && (
                // Render password input if verification is required
                <div className="password-pay">
                    <label>Password:</label>
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            )}
            <form className="pay-form" onSubmit={handleSubmit}>
                <div className="pay-field">
                    <label className="pay-field-label">Payment:</label>
                    <input
                        type="number"
                        placeholder="Payment"
                        name="payment"
                        value={formValues.payment}
                        onChange={handleChange}
                    />
                    {formErrors.payment && <p className="pay-error-message">{formErrors.payment}</p>}
                </div>
                <div className="pay-submit-button-container">
                    <button className="pay-submit" type="submit">
                        Submit Payment
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Pay;