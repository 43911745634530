import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Number of pages to show in the pagination bar
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage <= halfMaxPagesToShow) {
        endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <ul className="pagination">
            {currentPage > 1 && (
                <>
                    <li>
                        <button onClick={() => onPageChange(1)}>First</button>
                    </li>
                    <li>
                        <button onClick={() => onPageChange(currentPage - 1)}>Previous</button>
                    </li>
                </>
            )}

            {startPage > 1 && (
                <li>
                    <span>...</span>
                </li>
            )}

            {pageNumbers.map((number) => (
                <li key={number} className={number === currentPage ? 'active' : null}>
                    <button onClick={() => onPageChange(number)}>{number}</button>
                </li>
            ))}

            {endPage < totalPages && (
                <li>
                    <span>...</span>
                </li>
            )}

            {currentPage < totalPages && (
                <>
                    <li>
                        <button onClick={() => onPageChange(currentPage + 1)}>Next</button>
                    </li>
                    <li>
                        <button onClick={() => onPageChange(totalPages)}>Last</button>
                    </li>
                </>
            )}
        </ul>
    );
};

export default Pagination;