import React, { useState, useEffect } from 'react';
import './LiveTable.css';
import axios from 'axios';

const LiveTable = () => {
  const [password, setPassword] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [stockData, setStockData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://inventory-mtxj.onrender.com/common/stock');
        // const response = await axios.get('http://localhost:8084/common/stock');
        if(response.data.code === 200){
          setStockData(response.data.stockResponseList);
        }
      } catch (error) {
        console.error('Error fetching Stock data:', error);
      }
    };

    if (showTable) {
      fetchData();
    }
  }, [showTable]);

  const handlePasswordSubmit = () => {
    if (password === 'live123') {
      setShowTable(true);
    }
  };

  const handleRefreshClick = () => {
    setShowTable(false);
    setTimeout(() => {
      setShowTable(true);
    }, 1000);
  };

  return (
    <div className="live-table-container">
      <div className="centered-container">
        {!showTable && (
          <div>
            <input
              type="password"
              placeholder="Enter Password"
              className="password-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handlePasswordSubmit} className="submit-button">
              Submit
            </button>
          </div>
        )}
        {showTable && (
          <div>
            <div className="live-title">TANISA INTERNATIONAL LTD.</div>
            <div className="live-icon">LIVE</div>
            <button onClick={handleRefreshClick} className="refresh-button">
              Refresh
            </button>
            <div className={`table-container ${showTable ? 'show-table' : ''}`}>
              {stockData ? (
                <table className="live-table">
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Item</th>
                      <th>UOM</th>
                      <th>Stockin</th>
                      <th>Stockout</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockData.map((row, index) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{row.productName}</td>
                        <td>{row.uom}</td>
                        <td>{row.totalBuy}</td>
                        <td>{row.totalSell}</td>
                        <td>{row.inStock}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveTable;