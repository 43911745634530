import React, { useState, useEffect } from 'react';
import "./OrderDetails.css";
import DailyOrder from './DailyOrder';
import DailyOrderShort from './DailyOrderShort';
import DailyOrderTable from './DailyOrderTable';
import PendingOrder from './PendingOrder';
import CreateRequisition from './CreateRequisition';
import NavBar from './NavBar'; 



const OrderDetails = () => {
    const [showDailyOrderModal, setDailyOrderModal] = useState(false);
    const [showDailyOrderShortModal, setDailyOrderShortModal] = useState(false);
    const [showDailyOrderTableModal, setDailyOrderTableModal] = useState(false);
    const [showPendingOrderModal, setPendingOrderModal] = useState(false);
    const [showPendingRequiaitionModal, setPendingRequisitionModal] = useState(false);

    const handleDailyOrderModal = () => {
        setDailyOrderModal(true);
    };
    const handleCloseDailyOrderModal = () => {
        setDailyOrderModal(false);
    };
    const handleOpenDailyOrderShortModal = () => {
        setDailyOrderShortModal(true);
    }
    const handleCloseDailyOrderShortModal = () => {
        setDailyOrderShortModal(false);
    }
    const handleOpenOrderTableModal = () => {
        setDailyOrderTableModal(true);
    };
    const handleCloseOrderTableModal = () => {
        setDailyOrderTableModal(false);
    }
    const handleOpenPendingModal = () => {
        setPendingOrderModal(true);
    };
    const handleClosePendingModal = () => {
        setPendingOrderModal(false);
    }
    const handleOpenRequisitionModal = () => {
        setPendingRequisitionModal(true);
    };
    const handleCloseRequisitioinModal = () => {
        setPendingRequisitionModal(false);
    }

    return (
        <div className="order-details-container">
            <NavBar />
            <main className="order-details-main-content">
                <header className="order-details-header">
                    <a className="order-details-company-name">Tanisa International LTD</a>
                </header>
                <div className="order-details-action-buttons">
                    <button className="order-details-daily" onClick={handleDailyOrderModal}>Daily Order</button>
                    <button className="order-details-short" onClick={handleOpenDailyOrderShortModal}>Daily Order Short</button>
                    <button className="order-details-table" onClick={handleOpenOrderTableModal}>Daily Order Table</button>
                    <button className="order-details-pending" onClick={handleOpenPendingModal}>Pending Order</button>
                    <button className="order-details-requisition" onClick={handleOpenRequisitionModal}>Pending Requisition</button>
                </div>
            </main>
            {showDailyOrderModal && (
                <div className="order-details-modal">
                    <div className="order-details-modal-content">
                        <span className="order-details-close" onClick={handleCloseDailyOrderModal}>&times;</span>
                        <DailyOrder />
                    </div>
                </div>
            )}
            {showDailyOrderShortModal && (
                <div className="order-details-modal">
                    <div className="order-details-modal-content">
                        <span className="order-details-close" onClick={handleCloseDailyOrderShortModal}>&times;</span>
                        <DailyOrderShort />
                    </div>
                </div>
            )}
            {showDailyOrderTableModal && (
                <div className="order-details-modal">
                    <div className="order-details-modal-content">
                        <span className="order-details-close" onClick={handleCloseOrderTableModal}>&times;</span>
                        <DailyOrderTable />
                    </div>
                </div>
            )}
            {showPendingOrderModal && (
                <div className="order-details-modal">
                    <div className="order-details-modal-content">
                        <span className="order-details-close" onClick={handleClosePendingModal}>&times;</span>
                        <PendingOrder />
                    </div>
                </div>
            )}
            {showPendingRequiaitionModal && (
                <div className="order-details-modal">
                    <div className="order-details-modal-content">
                        <span className="order-details-close" onClick={handleCloseRequisitioinModal}>&times;</span>
                        <CreateRequisition />
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderDetails;