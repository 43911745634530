// Registration.js
import React, { useState } from "react";
import axios from "axios";
import './Registration.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Registration = () => {
  const initialValues = {
    name: "",
    email: "",
    dateOfBirth: "",
    password: "",
    confirmPassword: "",
    superPassword: "",
    gender: ""
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormValues({ ...formValues, [name]: uppercaseValue });
  };

  const handleChangeDate = (date) => {
    setFormValues({ ...formValues, dateOfBirth: date });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      dateOfBirth: undefined,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const formattedDateOfBirthDate = formValues.dateOfBirth ? formatDate(formValues.dateOfBirth) : null;
        const updatedFormData = {
          ...formValues,
          dateOfBirth: formattedDateOfBirthDate,
        };
        console.log(updatedFormData);
        const response = await axios.post("https://inventory-mtxj.onrender.com/auth/signup", updatedFormData);
        // const response = await axios.post("http://localhost:8084/auth/signup", updatedFormData);
        if (response.data && response.data.code === 200) {
          alert(response.data.message);
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.dateOfBirth) {
      errors.dateOfBirth = "Date of birth is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    }
    if (!values.gender) {
      errors.gender = "Gender is required";
    }
    if (!values.superPassword) {
      errors.superPassword = "Super Password is required";
    }
    return errors;
  }
  const handleLoginClick = () => {
    window.location.href = "/";
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <div className="registration-container">
      <main className="registration-main-content">
        <header className="registration-header">
          <a className="registration-company-name">Tanisa International LTD</a>
        </header>
        <div className="registration-form-container">
          <h1 className="registration-h1">Registration</h1>
          <form className="registration-form" onSubmit={handleSubmit}>
            <div className="registration-field">
              <label className="registration-field-label">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
              {formErrors.name && <p className="registration-error-message">{formErrors.name}</p>}
            </div>

            <div className="registration-field">
              <label className="registration-field-label">Email:</label>
              <input
                type="text"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {formErrors.email && <p className="registration-error-message">{formErrors.email}</p>}
            </div>

            <div className="registration-field">
              <label className="registration-field-label">Gender:</label>
              <select
                name="gender"
                value={formValues.gender}
                onChange={handleChange}
              >
                <option value="">Select Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">Other</option>
              </select>
              {formErrors.gender && <p className="registration-error-message">{formErrors.gender}</p>}
            </div>

            <div className="registration-field">
              <label className="registration-field-label">Date of Birth:</label>
              <DatePicker
                selected={formValues.dateOfBirth}
                onChange={handleChangeDate}
                placeholderText="Date Of Birth"
                dateFormat="dd-MM-yyyy"
              />
              {formErrors.dateOfBirth && <p className="registration-error-message">{formErrors.dateOfBirth}</p>}
            </div>

            <div className="registration-field">
              <label className="registration-field-label">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
                placeholder="Enter your password"
              />
              {formErrors.password && <p className="registration-error-message">{formErrors.password}</p>}
            </div>

            <div className="registration-field">
              <label className="registration-field-label">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formValues.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm your password"
              />
              {formErrors.confirmPassword && <p className="registration-error-message">{formErrors.confirmPassword}</p>}
            </div>

            <div className="registration-field">
              <label className="registration-field-label">Super Password:</label>
              <input
                type="password"
                id="superPassword"
                name="superPassword"
                value={formValues.superPassword}
                onChange={handleChange}
                placeholder="Enter super password"
              />
              {formErrors.superPassword && <p className="registration-error-message">{formErrors.superPassword}</p>}
            </div>
            <div className="registration-button-container">
              <button className="registration-submitbutton" type="submit">
                Sign Up
              </button>
            </div>
            <p className="registration-link">
              Already have an account? <a href="/" onClick={handleLoginClick}>Click here to login</a>
            </p>
          </form>
        </div>
      </main>
    </div>
  );
};
export default Registration;