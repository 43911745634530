import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './VATChalan.css';

const VATChalan = () => {
  const [vatDetails, setVatDetails] = useState(null);
  const [commonDetails, setCommonDetails] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('order');
  useEffect(() => {
    const fetchVatDetails = async () => {
      try {
        const response = await axios.get(`https://inventory-mtxj.onrender.com/order/bill/vat?order=${orderId}`);
        // const response = await axios.get(`http://localhost:8084/order/bill/vat?order=${orderId}`);
        if (response.data.code === 200) {
          setVatDetails(response.data.singleVatDetailsResponses);
          setCommonDetails(response.data.singleBillResponse);
        }
      } catch (error) {
        console.error('Error fetching Bill details:', error);
      }
    };
    fetchVatDetails();
  }, [orderId]);

  const handlePrint = () => {
    window.print();
  };

  function formatCurrency(amount) {
    // Check if the amount is a valid number
    if (isNaN(amount)) {
      return amount;
    }

    // Format the amount with commas and two decimal places
    return Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const renderTable = (copyType) => (
    <div className={`table-section ${copyType} ${copyType === 'office-copy' ? 'office-copy' : ''}`}>
      <table className="vat-chalan-table">
        <thead className='vat-table-header'>
          <tr>
            <th>ক্র: নং</th>
            <th>পণ্য বা সেবার বর্ণনা (প্রযোজ্য ক্ষেত্রে ব্রান্ড নাম সহ)</th>
            <th>সরবরাহের একক</th>
            <th>পরিমান</th>
            <th>একক মূল্য <sup>১</sup> (টাকায়)</th>
            <th>মোট মূল্য (টাকায়)</th>
            <th>সম্পূরক শুল্কের হার </th>
            <th>সম্পূরক শুল্কের পরিমান (টাকায়)</th>
            <th>মূল্য সংযোজন করের হার/ সুনির্দিষ্ট কর</th>
            <th>মূল্য সংযোজন কর/ সুনির্দিষ্ট কর এর পরিমাণ (টাকায়)</th>
            <th>সকল প্রকার শুল্ক ও করসহ মূল্য</th>
          </tr>
          <tr>
            <th>(১)</th>
            <th>(২)</th>
            <th>(৩)</th>
            <th>(৪)</th>
            <th>(৫)</th>
            <th>(৬)</th>
            <th>(৭)</th>
            <th>(৮)</th>
            <th>(৯)</th>
            <th>(১০)</th>
            <th>(১১)</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 25 }, (_, index) => {
            const row = vatDetails && vatDetails[index];
            return (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>{row ? row.product : ''}</td>
                <td>{row ? row.uom : ''}</td>
                <td>{row ? row.quantity : ''}</td>
                <td>{row ? formatCurrency(row.price) : ''}</td>
                <td>{row ? formatCurrency(row.total) : ''}</td>
                <td>{row ? row.tax : ''}</td>
                <td>{row ? formatCurrency(row.taxAmount) : ''}</td>
                <td>{row ? row.vat : ''}</td>
                <td>{row ? formatCurrency(row.vatAmount) : ''}</td>
                <td>{row ? formatCurrency(row.totalAmountWithVatTax) : ''}</td>
              </tr>
            );
          })}

          <tr className='lastline'>
            <td colSpan="5">সর্বমোট</td>
            <td className="total-amount-cell text-right">{formatCurrency(vatDetails.reduce((total, row) => total + (row ? row.total : 0), 0))}</td>

            <td colSpan="3"></td>
            <td className='boldfont text-right'>{formatCurrency(vatDetails.reduce((vatAmount, row) => vatAmount + (row ? row.vatAmount : 0), 0))}</td>
            <td className='boldfont text-right'>{formatCurrency(vatDetails.reduce((totalAmountWithVatTax, row) => totalAmountWithVatTax + (row ? row.totalAmountWithVatTax : 0), 0))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <div className="vat-chalan-container">
        <div className="header">
          <div className="top-middle">
            <div className="title">গণপ্রজাতন্ত্রী বাংলাদেশ সরকার, জাতীয় রাজস্ব বোর্ড </div>
            <div className="sub-title">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (গ) ও (চ) দ্রষ্টব্য ]</div>
            <div className='top-middle-2'>
              <div className="text-field">নিবন্ধিত ব্যাক্তির নাম : </div>
              <div className="text-field">নিবন্ধিত ব্যাক্তির বিআইএন : </div>
              <div className="text-field">চালানপত্র ইস্যুর ঠিকানা : </div>
            </div>
          </div>
          <div className="top-right">
            <div className="title-firstcopy">প্রথম কপি</div>
            <div></div>
            <div className="box"> মূসক-৬.৩</div>
          </div>
        </div>
        {commonDetails && vatDetails ? (
          <>
            <div className="left-section">
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '78px' }}>
                ক্রেতার নাম : <span className="customer">{commonDetails.company}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '45px' }}>
                ক্রেতার বিআইএন : <span className="bin">{commonDetails.bin}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '61px' }}>
                ক্রেতার ঠিকানা : <span className="address">{commonDetails.address}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '28px' }}>
                সরবরাহের গন্তব্যস্থল : <span className="delivery"> {commonDetails.delivery}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start' }}>
                যানবাহনের প্রকৃতি ও নম্বর : <span className="van">Freezer Van; No: Sha-11-4013</span>
              </div>
            </div>

            <div className="right-section">
              <div className="text-field-right" style={{ alignItems: 'flex-start', marginLeft: '-15px' }}>চালানপত্র নম্বর : <span className='challan-number'>{commonDetails.challan}</span></div>
              <div className="text-field-right" style={{ alignItems: 'flex-start', marginLeft: '50px' }}>ইস্যুর তারিখ : <span className='issue-date'>{commonDetails.deliveryDate}</span></div>
              <div className="text-field-right" style={{ alignItems: 'flex-start', marginLeft: '-5px' }}>ইস্যুর সময় : </div>
            </div>

            {renderTable('office-copy')}

            <div className="bottom-section">
              <div className="text-field-bottom">প্রতিষ্ঠান কর্তৃপক্ষের দায়িত্বপ্রাপ্ত ব্যক্তির নাম: </div>
              <div className="text-field-bottom">পদবি: </div>
              <div className="text-field-bottom">স্বাক্ষর: </div>
              <div className='topline'>_________________________</div>
              <div className="power-symbol-container">
                <div className="power-symbol">১</div>
                <div className="text-field-last">সকল প্রকার কর ব্যতীত মূল্য"; </div>
              </div>
            </div>

            <div className="bottom-right-text">
              সিল
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <br></br>
      <div className="vat-chalan-container">
        <div className="header">
          <div className="top-middle">
            <div className="title">গণপ্রজাতন্ত্রী বাংলাদেশ সরকার, জাতীয় রাজস্ব বোর্ড</div>
            <div className="sub-title">[বিধি ৪০ এর উপ-বিধি (১) এর দফা (গ) ও (চ) দ্রষ্টব্য ]</div>
            <div className='top-middle-2'>
              <div className="text-field">নিবন্ধিত ব্যাক্তির নাম : </div>
              <div className="text-field">নিবন্ধিত ব্যাক্তির বিআইএন : </div>
              <div className="text-field">চালানপত্র ইস্যুর ঠিকানা : </div>
            </div>
          </div>
          <div className="top-right">
            <div className="box"> মূসক-৬.৩</div>
          </div>
        </div>
        {commonDetails && vatDetails ? (
          <>
            <div className="left-section">
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '78px' }}>
                ক্রেতার নাম : <span className="customer">{commonDetails.company}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '45px' }}>
                ক্রেতার বিআইএন : <span className="bin">{commonDetails.bin}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '61px' }}>
                ক্রেতার ঠিকানা : <span className="address">{commonDetails.address}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start', marginLeft: '28px' }}>
                সরবরাহের গন্তব্যস্থল : <span className="delivery"> {commonDetails.delivery}</span>
              </div>
              <div className="text-field-left" style={{ alignItems: 'flex-start' }}>
                যানবাহনের প্রকৃতি ও নম্বর : <span className="van">Freezer Van; No: Sha-11-4013</span>
              </div>
            </div>

            <div className="right-section">
              <div className="text-field-right" style={{ alignItems: 'flex-start', marginLeft: '10px' }}>চালানপত্র নম্বর : <span className='challan-number'>{commonDetails.challan}</span></div>
              <div className="text-field-right" style={{ alignItems: 'flex-start', marginLeft: '25px' }}>ইস্যুর তারিখ : <span className='issue-date'>{commonDetails.deliveryDate}</span></div>
              <div className="text-field-right" style={{ alignItems: 'flex-start', marginLeft: '33px' }}>ইস্যুর সময় : </div>
            </div>

            {renderTable('customer-copy')}

            <div className="bottom-section">
              <div className="text-field-bottom">প্রতিষ্ঠান কর্তৃপক্ষের দায়িত্বপ্রাপ্ত ব্যক্তির নাম: </div>
              <div className="text-field-bottom">পদবি: </div>
              <div className="text-field-bottom">স্বাক্ষর: </div>
              <div className='topline'>_________________________</div>
              <div className="power-symbol-container">
                <div className="power-symbol">১</div>
                <div className="text-field-last">সকল প্রকার কর ব্যতীত মূল্য"; </div>
              </div>
            </div>


            <div className="bottom-right-text">
              সিল
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className="button-container">
        <button className="vat-print-button" onClick={handlePrint}>
          Print
        </button>
      </div>
    </div>
  );
};

export default VATChalan;
