import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import './CreateRequisition.css';
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const CreateRequisition = () => {
    const [data, setOrderTableData] = useState([]);
    const [warehouses, setWarehouse] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [requisitionMaster, setRequisitionMasterData] = useState({
        warehouse: "",
        delivery: "",
        user: "",
    });
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    const handleChange = (fieldName, fieldValue) => {
        let uppercaseValue = fieldValue;
        if (typeof fieldValue === 'object' && fieldValue.label) {
            uppercaseValue = { label: fieldValue.label.toUpperCase(), value: fieldValue.value };
        }

        setRequisitionMasterData((prevValues) => ({
            ...prevValues,
            [fieldName]: uppercaseValue || '',
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: '',
        }));
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('https://inventory-mtxj.onrender.com/order/waiting');
            // const response = await axios.get('http://localhost:8084/order/waiting');
            if (response.data.code === 200) {
                setOrderTableData(response.data.getSinglePendingOrWaitingOrderResponseList || []);
                setFilteredData(response.data.getSinglePendingOrWaitingOrderResponseList || []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchWarehouse = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/warehouse`);
            // const response = await axios.get(`http://localhost:8084/common/warehouse`);
            if (response.data.code === 200) {
                setWarehouse(response.data.warehouseList);
            }
        } catch (error) {
            alert("Error fetching warehouse data:", error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
        fetchWarehouse();
    }, [formErrors]);

    const handleRowSelection = (row) => {
        if (selectedRows.some(selectedRow => selectedRow.id === row.id)) {
            setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
        } else {
            setSelectedRows([...selectedRows, row]);
        }
    };

    const handleShowModal = async () => {
        if (!selectedRows || selectedRows.length === 0) {
            alert('Please select at least one row before creating a requisition.');
            return;
        }

        const masterErrors = validateFormData(requisitionMaster);
        setFormErrors(masterErrors);

        if (Object.keys(masterErrors).length > 0) {
            return;
        }
        const user = localStorage.getItem('userEmail');
        const userConfirmed = window.confirm("Are you sure you want to create a requisition?");
        if (userConfirmed) {
            try {
                const requisitionData = {
                    warehouse: requisitionMaster.warehouse,
                    deliveryMan: requisitionMaster.delivery,
                    user: user,
                    updateOrderRequestList: selectedRows.map(row => ({
                        id: row.id,
                        user: user,
                    })),
                };

                const response = await axios.put('https://inventory-mtxj.onrender.com/order/waiting_status', requisitionData);
                // const response = await axios.put('http://localhost:8084/order/waiting_status', requisitionData);
                if (response.data.code === 200) {
                    window.location.href = "/order_details";
                }
            } catch (error) {
                console.error('Error fetching selected rows data:', error);
            }
        }
    };

    function formatCurrency(amount) {
        if (isNaN(amount)) {
            return amount;
        }
        return Number(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const validateFormData = (values) => {
        const errors = {};
        if (!values.warehouse) {
            errors.warehouse = "Warehouse is required";
        }
        if (!values.delivery) {
            errors.delivery = "Delivery Man name is required";
        }
        return errors;
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedRows(currentItems);
        } else {
            setSelectedRows([]);
        }
    };

    return (
        <div>
            <div className="create-requisition-table-container">
                <h2>Pending Requisition</h2>
                <div className="create-requisition-action-button">
                    <button className="create-requisition" onClick={handleShowModal}>
                        Create Requisition
                    </button>
                </div>
                <div className="create-requisition-fields-container">
                    <div className='create-requisition-row'>
                        <div className="create-requisition-field">
                            <label className="create-requisition-field-label">Warehouse:</label>
                            <Select
                                name="warehouse"
                                components={{
                                    ...animatedComponents,
                                }}
                                value={requisitionMaster.warehouse}
                                onChange={(fieldValue) => handleChange("warehouse", fieldValue)}
                                options={warehouses.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                                isSearchable
                                placeholder="Select Warehouse"
                            />
                            {formErrors.warehouse && <p className="create-requisition-error-message">{formErrors.warehouse}</p>}
                        </div>
                        <div className="create-requisition-field">
                            <label className="create-requisition-label">Delivery Man:</label>
                            <input
                                type="text"
                                placeholder="Delivery Man"
                                name="delivery"
                                id="delivery"
                                value={requisitionMaster.delivery}
                                onChange={(e) => handleChange("delivery", e.target.value)}
                            />
                            {formErrors.delivery && <p className="create-requisition-error-message">{formErrors.delivery}</p>}
                        </div>
                    </div>
                </div>
                <table className="create-requisition-details-table">
                    <thead>
                        <tr>
                            <th><input type="checkbox" onChange={handleSelectAll} /></th>
                            <th>SL NO</th>
                            <th>Order Id</th>
                            <th>Shipment No</th>
                            <th>Customer Name</th>
                            <th>Company Name</th>
                            <th>Warehouse</th>
                            <th>Product Id</th>
                            <th>Product Name</th>
                            <th>Uom</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Bill</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.some(selectedRow => selectedRow.id === row.id)}
                                        onChange={() => handleRowSelection(row)}
                                    />
                                </td>
                                <td>{indexOfFirstItem + index + 1}</td>
                                <td>{row.orderId}</td>
                                <td>{row.shipment}</td>
                                <td>{row.customer}</td>
                                <td>{row.company}</td>
                                <td>{row.warehouse}</td>
                                <td>{row.productId}</td>
                                <td>{row.product}</td>
                                <td>{row.uom}</td>
                                <td>{row.quantity}</td>
                                <td>{formatCurrency(row.unitPrice)}</td>
                                <td>{formatCurrency(row.totalBill)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
                {data.length === 0 && <p>No records to show.</p>}
            </div>
        </div>
    );
};

export default CreateRequisition;