import React from "react";
import Navbar from "./components/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Registration from "./components/Registration";
import Import from "./components/Import";
import SalesPerson from "./components/SalesPerson";
import Wastage from "./components/Wastage";
import Order from "./components/Order";
import Report from "./components/Report";
import ProductDetails from "./components/ProductDetails";
import Revenue from "./components/Revenue";
import LiveTable from "./components/LiveTable";
import Bill from "./components/Bill";
import OrderDetails from "./components/OrderDetails";
import Returns from "./components/Returns";
import Requisition from "./components/Requisition";
import Move from "./components/Move";
import Payments from "./components/Payments";
import Welcome from "./components/Welcome";
import AllWastage from "./components/AllWastage";
import AllReturn from "./components/AllReturn";
import WastageSlip from "./components/WastageSlip";
import ReturnSlip from "./components/ReturnSlip";
import VATChalan from "./components/VATChalan";
import BillModal from "./components/BillModal";
import AllRequisition from "./components/AllRequisition";
import AllCustomer from "./components/AllCustomer";
import MoveDetails from "./components/MoveDetails";
import AllBranch from "./components/AllBranch";
import MoveSlip from "./components/MoveSlip";
const Wrapper = ({ children }) => children;
const App = () => {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
        <Routes>
          <Route path="/payments" element={<Payments />} />
        </Routes>
        <Routes>
          <Route path="/dashboard" element={<Navbar />} />
        </Routes>
        <Routes>
          <Route path="/registration" element={<Registration />} />
        </Routes>
        <Routes>
          <Route path="/import" element={<Import />} />
        </Routes>
        <Routes>
          <Route path="/order" element={<Order />} />
        </Routes>
        <Routes>
          <Route path="/salesPerson" element={<SalesPerson />} />
        </Routes>
        <Routes>
          <Route path="/wastage" element={<Wastage />} />
        </Routes>
        <Routes>
          <Route path="/report" element={<Report />} />
        </Routes>
        <Routes>
          <Route path="/product_details" element={<ProductDetails />} />
        </Routes>
        <Routes>
          <Route path="/move_details" element={<MoveDetails />} />
        </Routes>
        <Routes>
          <Route path="/revenue" element={<Revenue />} />
        </Routes>
        <Routes>
          <Route path="/live" element={<LiveTable />} />
        </Routes>
        <Routes>
          <Route path="/bill" element={<Bill />} />
        </Routes>
        <Routes>
          <Route path="/order_details" element={<OrderDetails />} />
        </Routes>
        <Routes>
          <Route path="/return" element={<Returns />} />
        </Routes>
        <Routes>
          <Route path="/all_requisition/requisition_slip" element={<Requisition />} />
        </Routes>
        <Routes>
          <Route path="/move" element={<Move />} />
        </Routes>
        <Routes>
          <Route path="/welcome" element={<Welcome />} />
        </Routes>
        <Routes>
          <Route path="/all_wastage/wastage_slip" element={<WastageSlip />} />
        </Routes>
        <Routes>
          <Route path="/all_wastage" element={<AllWastage />} />
        </Routes>
        <Routes>
          <Route path="/all_return" element={<AllReturn />} />
        </Routes>
        <Routes>
          <Route path="/all_return/return_slip" element={<ReturnSlip />} />
        </Routes>
        <Routes>
          <Route path="/bill/vat" element={<VATChalan />} />
        </Routes>
        <Routes>
          <Route path="/bill/bills" element={<BillModal />} />
        </Routes>
        <Routes>
          <Route path="/all_requisition" element={<AllRequisition />} />
        </Routes>
        <Routes>
          <Route path="/all_customer" element={<AllCustomer />} />
        </Routes>
        <Routes>
          <Route path="/all_branch" element={<AllBranch />} />
        </Routes>
        <Routes>
          <Route path="/move_details/move_slip" element={<MoveSlip />} />
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
