import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import './WastageSlip.css';

const WastageSlip = () => {
  const location = useLocation();
  const [wastageData, setWastageData] = useState([]);
  const [total, setTotal] = useState("Total");
  const [piece, setPiece] = useState("");
  const [cartoon, setCartoon] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const serialNoParams = queryParams.get('serialNo');
  const serial = serialNoParams.split('?');
  const serialNo = serial[0] || '';
  const name = serial[1] || '';
  const warehouse = serial[2] || '';
  const createdOn = serial[3] || '';

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/import/wastage?serial=${serialNo}`);
      // const response = await axios.get(`http://localhost:8084/import/wastage?serial=${serialNo}`);
      if (response.data.code === 200) {
        setWastageData(response.data.wastageResponses || []);
        setPiece(response.data.piece);
        setCartoon(response.data.cartoonKgLt);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    fetchData();
  }, []);

  const renderTextFields = () => (
    <div className="wastage-slip-text-fields">
      <div className="wastage-slip-text-pair">
        <div>
          <span >Name: {name}</span>
        </div>
        <div>
          <span>Serial No: {serialNo}</span>
        </div>
      </div>
      <div className="wastage-slip-text-pair">
        <div>
          <span>Date: {createdOn}</span>
        </div>
        <div>
          <span>Warehouse: {warehouse}</span>
        </div>
      </div>
    </div>
  );

  const renderTable = (wastageData, total, piece, cartoon) => {
    const numberOfRows = 20;

    const rows = Array.from({ length: numberOfRows }, (_, index) => {
      const rowData = wastageData[index] || {};
      return (
        <tr key={index + 1}>
          <td>{index + 1}</td>
          <td>{rowData.product || ''}</td>
          <td>{rowData.piece || ''}</td>
          <td>{rowData.quantity || ''}</td>
          <td>{rowData.cause || ''}</td>
        </tr>
      );
    });

    return (
      <>
        <table className="wastage-slip-table">
          <thead>
            <tr>
              <th>SL No</th>
              <th>Product Name</th>
              <th>Qty (pcs)</th>
              <th>Qty (ctn:kg)</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {rows}
            <tr>
              <td></td>
              <td>{total}</td>
              <td>{piece}</td>
              <td>{cartoon}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderSignatures = () => (
    <div className="wastage-slip-signatures">
      <div className="wastage-slip-signature">
        <div>_____________</div>
        <div>Received by:</div>
      </div>
      <div className="wastage-slip-signature">
        <div>_____________</div>
        <div>Authorised by:</div>
      </div>
    </div>
  );

  return (
    <div className="wastage-slip-container">
      <div className="wastage-slip-box print-border office-copy">
        <div className="wastage-slip-company-details">
          <div className="wastage-slip-company-name">TANISA INTERNATIONAL LTD.</div>
          <div className="wastage-slip-small-text"><div>78, Motijheel C/A (5th floor),</div> Motijheel, Dhaka-1000, Bangladesh. <div>
            Cell: 01917390218, 01681594275</div> <div>Email: flagshipintl.pvtltd@yahoo.com</div></div>
        </div>
        <h2>Wastage Slip</h2>
        <div className="wastage-slip-fixed-title">Office Copy</div>
        {renderTextFields()}
        {renderTable(wastageData, total, piece, cartoon)}
        {renderSignatures("Received by:", "Authorised by:")}
      </div>

      <div className="wastage-slip-box print-border receiver-copy">
        <div className="wastage-slip-company-details">
          <div className="wastage-slip-company-name">TANISA INTERNATIONAL LTD.</div>
          <div className="wastage-slip-small-text"><div>78, Motijheel C/A (5th floor),</div> Motijheel, Dhaka-1000, Bangladesh. <div>
            Cell: 01917390218, 01681594275</div> <div>Email: flagshipintl.pvtltd@yahoo.com</div></div>
        </div>
        <h2>Wastage Slip</h2>
        <div className="wastage-slip-fixed-title">Receiver's Copy</div>
        {renderTextFields()}
        {renderTable(wastageData, total, piece, cartoon)}
        {renderSignatures()}
      </div>
      <button className="wastage-slip-print-button" onClick={handlePrint}>
        Print
      </button>
    </div>
  );
};

export default WastageSlip;