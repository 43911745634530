import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import './PendingOrder.css';
import PasswordModal from './PasswordModal';

const PendingOrder = () => {
    const [data, setOrderTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleDelete = (id, user) => {
        setDeleteInfo({ id, user });
        setIsModalOpen(true);
    };

    const handleModalSubmit = (password) => {
        if (!password) {
            alert('Password is required.');
            return;
        }

        if (password !== 'Flagship@2024') {
            alert('Password not matched');
            return;
        }

        const confirmDelete = window.confirm('Are you sure you want to delete this Order details?');
        if (confirmDelete && deleteInfo) {
            deleteProductApiCall(deleteInfo.id, deleteInfo.user);
        }

        setIsModalOpen(false);
        setDeleteInfo(null);
    };

    const deleteProductApiCall = async (id, user) => {
        try {
            const response = await axios.delete(`https://inventory-mtxj.onrender.com/order?id=${id}&user=${user}`);
            if (response.data.code === 200) {
                alert('Order deleted successfully.');
                window.location.href = "/order_details";
            } else {
                alert('Error deleting Order.');
            }
        } catch (error) {
            alert('Error deleting Order . Please try again.');
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('https://inventory-mtxj.onrender.com/order/pending');
            if (response.data.code === 200) {
                setOrderTableData(response.data.getSinglePendingOrWaitingOrderResponseList || []);
                setFilteredData(response.data.getSinglePendingOrWaitingOrderResponseList || [])
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
    }, []);

    const handleRowSelection = (row) => {
        const selectedIndex = selectedRows.findIndex(selectedRow => selectedRow.id === row.id);
        if (selectedIndex === -1) {
            setSelectedRows([...selectedRows, row]);
        } else {
            setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
        }
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedRows(currentItems);
        } else {
            setSelectedRows([]);
        }
    };

    const handleShowModal = async () => {
        if (!selectedRows || selectedRows.length === 0) {
            alert('Please select at least one row before update Status.');
            return;
        }
        const user = localStorage.getItem('userEmail');
        const userConfirmed = window.confirm("Are you sure you want to update the status?");

        if (userConfirmed) {
            try {
                const rowsWithUserEmail = selectedRows.map(row => ({ ...row, user }));
                const response = await axios.put('https://inventory-mtxj.onrender.com/order/status', rowsWithUserEmail);
                if (response.data.code === 200) {
                    window.location.href = "/order_details";
                }
            } catch (error) {
                console.error('Error fetching selected rows data:', error);
            }
        }
    };

    function formatCurrency(amount) {
        if (isNaN(amount)) {
            return amount;
        }

        return Number(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return (
        <div className="pending-order-table-container">
            <h2>Pending Order List</h2>
            <div className="pending-order-action-button">
                <button className="pending-orders" onClick={handleShowModal}>
                    Update Status
                </button>
            </div>

            <table className="pending-order-details-table">
                <thead>
                    <tr>
                        <th><input type="checkbox" onChange={handleSelectAll} /></th>
                        <th>SL NO</th>
                        <th>Order Id</th>
                        <th>Shipment No</th>
                        <th>Customer Name</th>
                        <th>Company Name</th>
                        <th>Warehouse</th>
                        <th>Product Id</th>
                        <th>Product Name</th>
                        <th>Uom</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>Total Bill</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedRows.some(selectedRow => selectedRow.id === row.id)}
                                    onChange={() => handleRowSelection(row)}
                                />
                            </td>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{row.orderId}</td>
                            <td>{row.shipment}</td>
                            <td>{row.customer}</td>
                            <td>{row.company}</td>
                            <td>{row.warehouse}</td>
                            <td>{row.productId}</td>
                            <td>{row.product}</td>
                            <td>{row.uom}</td>
                            <td>{row.quantity}</td>
                            <td>{formatCurrency(row.unitPrice)}</td>
                            <td>{formatCurrency(row.totalBill)}</td>
                            <td>
                                <button onClick={() => handleDelete(row.id, localStorage.getItem('userEmail'))} button={true.toString()}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            {data.length === 0 && <p>No records to show.</p>}
            <PasswordModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
            />
        </div>
    );
};

export default PendingOrder;