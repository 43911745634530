import React, { useState } from "react";
import axios from "axios";
import './Categories.css';
import NavBar from "./NavBar.js";

export const Categories = () => {
    const initialValues = {
        categoryId: "",
        categoryName: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const uppercaseValue = value.toUpperCase();
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        setFormValues({ ...formValues, [name]: uppercaseValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            try {
                const updatedFormData = { ...formValues, user };
                 const response = await axios.post("https://inventory-mtxj.onrender.com/common/category", updatedFormData);
                // const response = await axios.post("http://localhost:8084/common/category", updatedFormData);
                if (response.data.code === 200) {
                    alert(response.data.message);
                    window.location.href = "/import";
                }
            } catch (error) {
                alert(error.response.data.message);
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.categoryId) {
            errors.categoryId = "Category ID is required";
        }
        if (!values.categoryName) {
            errors.categoryName = "Category Name is required";
        }
        return errors;
    };

    return (
        <div className="category-form-container">
            <h1 className="category-form-title">Add category</h1>
            <form className="category-form" onSubmit={handleSubmit}>
                <div className="category-field">
                    <label className="category-field-label">Category Id:</label>
                    <input
                        type="text"
                        placeholder="category Id"
                        name="categoryId"
                        value={formValues.categoryId}
                        onChange={handleChange}
                    />
                    {formErrors.categoryId && <p className="category-error-message">{formErrors.categoryId}</p>}
                </div>
                <div className="category-field">
                    <label className="category-field-label">Category Name:</label>
                    <input
                        type="text"
                        placeholder="category Name"
                        name="categoryName"
                        value={formValues.categoryName}
                        onChange={handleChange}
                    />
                    {formErrors.categoryName && <p className="category-error-message">{formErrors.categoryName}</p>}
                </div>
                <div className="category-submit-button-container">
                    <button className="category-submit" type="submit">
                        Create Category
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Categories;