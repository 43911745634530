import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Payments.css';
import Ledger from './Ledger';
import Navbar from './NavBar';

const Payments = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerName, setSelectedCustomerName] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [paymentData, setPaymentData] = useState(null);

  const handleLedger = (customerId, customer, company) => {
    setSelectedCustomer(customerId);
    setSelectedCustomerName(customer);
    setSelectedCompany(company);
    setSelectedModal('ledger');
  };
  const handleCloseModal = () => {
    setSelectedCustomer(null);
    setSelectedModal(null);
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    const fetchData = async () => {
      try {
        const response = await axios.get('https://inventory-mtxj.onrender.com/order/payments');
        // const response = await axios.get('http://localhost:8084/order/payments');
        if (response.data.code === 200) {
          setPaymentData(response.data.paymentResponseList);
        }
      } catch (error) {
        console.error('Error fetching Payment data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container">
      <Navbar />
      <h1>Tanisa Group - Payments</h1>
      {paymentData ? (
        <table>
          <thead>
            <tr>
              <th>Sln No</th>
              <th>Customer Name</th>
              <th>Company Name</th>
              <th>DUE</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {paymentData.map((row, index) => (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>{row.customer}</td>
                <td>{row.company}</td>
                <td>{Number(row.due).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                <td>
                  <button onClick={() => handleLedger(row.customerId, row.customer, row.company)}>LEDGER</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading payment data...</p>
      )}
      {selectedCustomer && selectedCustomerName && selectedCompany && selectedModal === 'ledger' && (
        <div className="payments-modal">
          <div className="payments-modal-content">
            <span className="payments-close" onClick={handleCloseModal}>&times;</span>
            <Ledger customerId={selectedCustomer} customer={selectedCustomerName} company={selectedCompany} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Payments;