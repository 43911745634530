import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from './NavBar';
import './Welcome.css';

const Welcome = () => {
  const [product, setProductData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://inventory-mtxj.onrender.com/common/welcome');
      // const response = await axios.get('http://localhost:8084/common/welcome');
      if (response.data.code === 200) {
        setProductData(response.data.responseList || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    fetchData();
  }, []);

  return (
    <div className="welcome-container">
      <NavBar />
      <h1>Product Room</h1>
      <table className="product-table">
        <thead>
          <tr>
            <th>SL No</th>
            <th>PRODUCT NAME</th>
            <th>PRODUCT ID</th>
            <th>COMPANY NAME</th>
            <th>SALES CODE</th>
            <th>ARTICLE CODE</th>
          </tr>
        </thead>
        <tbody>
          {product && product.map((p) => (
            p.companies.map((company, index) => (
              <tr key={`${p.serialNo}-${index}`}>
                {index === 0 && (
                  <>
                    <td rowSpan={p.companies.length}>{p.serialNo}</td>
                    <td rowSpan={p.companies.length}>{p.productName}</td>
                    <td rowSpan={p.companies.length}>{p.productId}</td>
                  </>
                )}
                <td>{company.companyName}</td>
                <td>{company.salesCode}</td>
                <td>{company.articleCode}</td>
              </tr>
            ))
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Welcome;
