import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import './AllCustomer.css';
import 'jspdf-autotable';
import Navbar from './NavBar';
import PasswordModal from './PasswordModal';

const AllCustomer = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setCustomerData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleDelete = (id, user) => {
        setDeleteInfo({ id, user });
        setIsModalOpen(true);
    };

    const handleModalSubmit = (password) => {
        if (!password) {
            alert('Password is required.');
            return;
        }

        if (password !== 'Flagship@2024') {
            alert('Password not matched');
            return;
        }

        const confirmDelete = window.confirm('Are you sure you want to delete this customer?');
        if (confirmDelete && deleteInfo) {
            deleteCustomerApiCall(deleteInfo.id, deleteInfo.user);
        }

        setIsModalOpen(false);
        setDeleteInfo(null);
    };

    const deleteCustomerApiCall = async (id, user) => {
        try {
            const response = await axios.delete(`https://inventory-mtxj.onrender.com/common/customer?id=${id}&user=${user}`);
            // const response = await axios.delete(`http://localhost:8084/common/customer?id=${id}&user=${user}`);
            if (response.data.code === 200) {
                alert('Customer deleted successfully.');
                window.location.href = "/all_customer";
            } else {
                alert('Error deleting customer.');
            }
        } catch (error) {
            console.error('Error deleting customer:', error);
            alert('Error deleting customer. Please try again.');
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('https://inventory-mtxj.onrender.com/common/all/customer');
            // const response = await axios.get('http://localhost:8084/common/all/customer');
            if (response.data.code === 200) {
                const requisitionResponseList = response.data.customerList || [];
                setCustomerData(requisitionResponseList);
                setFilteredData(requisitionResponseList);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
    }, []);

    useEffect(() => {
        const updatedFilteredData = data.filter((item) => {
            const matchesSearchTerm =
                item.customerId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.customerType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.supplier?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.company?.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesSearchTerm;
        });

        setFilteredData(updatedFilteredData);
    }, [data, searchTerm]);

    return (
        <div className="customer-details-table-container">
            <Navbar />
            <h2>Customer Details</h2>
            <div className='customer-table-controls'>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <table className="customer-details-table">
                <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Customer Id</th>
                        <th>Customer Name</th>
                        <th>Customer Company</th>
                        <th>Customer Type</th>
                        <th>Customer Address</th>
                        <th>Credit Term</th>
                        <th>Phone No</th>
                        <th>Bin No</th>
                        <th>Supplier</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{row.customerId}</td>
                            <td>{row.customerName}</td>
                            <td>{row.company}</td>
                            <td>{row.customerType}</td>
                            <td>{row.address}</td>
                            <td>{row.creditTerm}</td>
                            <td>{row.phoneNumber}</td>
                            <td>{row.binNo}</td>
                            <td>{row.supplier}</td>
                            <td>
                                <button onClick={() => handleDelete(row.id, localStorage.getItem('userEmail'))}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            {data.length === 0 && <p>No records to show.</p>}
            <PasswordModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
            />
        </div>
    );
};
export default AllCustomer;