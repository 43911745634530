import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import EditBill from './EditBill';
import Pay from './Pay';
import "./Bill.css";
import Ledger from './Ledger';
import Navbar from './NavBar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';

const Bill = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [data, setBillData] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedDue, setDueValue] = useState(null);
  const [selectedCustomerId, setCustomerIdValue] = useState(null);
  const [selectedCustomer, setCustomerValue] = useState(null);
  const [selectedCompany, setCompany] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function formatCurrency(amount) {
    if (isNaN(amount)) {
      return amount;
    }

    return Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const fetchData = async () => {
    try {
      const response = await axios.get('https://inventory-mtxj.onrender.com/order/bills');
      // const response = await axios.get('http://localhost:8084/order/bills');
      if (response.data.code === 200) {
        setBillData(response.data.billsList || []);
      }
    } catch (error) {
      console.error('Error fetching Bill data:', error);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    fetchData();
  }, []);

  const handleBillViewModal = (orderId) => {
    const newTab = window.open('', '_blank');
    if (newTab) {
      const vatUrl = `bills?order=${orderId}`;
      newTab.location.href = vatUrl;
    }

  };

  const handleVatViewModal = (orderId) => {
    const newTab = window.open('', '_blank');
    if (newTab) {
      const vatUrl = `vat?order=${orderId}`;
      newTab.location.href = vatUrl;
    }
  };

  const handleEditBillModal = (orderId) => {
    setSelectedOrderId(orderId);
    setSelectedModal('edit');
  };

  const handlePayModal = (orderId, due) => {
    setSelectedOrderId(orderId);
    setDueValue(due);
    setSelectedModal('pay');
    setIsPasswordRequired(true);
  };

  const handleLedgerModal = (orderId, customerId, customer, company) => {
    setSelectedOrderId(orderId);
    setCustomerIdValue(customerId);
    setCustomerValue(customer);
    setCompany(company);
    setSelectedModal('ledger');
  };

  const handleCloseModal = () => {
    setSelectedOrderId(null);
    setSelectedModal(null);
  };

  useEffect(() => {
    const updatedFilteredData = data.filter((item) => {
      const matchesSearchTerm =
        item.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.orderId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.salesPerson.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.orderDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.branch.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesSearchTerm;
    });

    setFilteredData(updatedFilteredData);
  }, [data, searchTerm]);

  useEffect(() => {
    const updatedFilteredData = data.filter((item) => {
      // Convert item.orderDate to Date object
      const orderDateParts = item.orderDate.split('-');
      const orderDate = new Date(orderDateParts[2], orderDateParts[1] - 1, orderDateParts[0]);
  
      const from = fromDate ? new Date(fromDate.setHours(0, 0, 0, 0)) : null;
      const to = toDate ? new Date(toDate.setHours(23, 59, 59, 999)) : null;
  
      if (from && to) {
        return orderDate >= from && orderDate <= to;
      } else if (from) {
        return orderDate >= from;
      } else if (to) {
        return orderDate <= to;
      }
  
      return true;
    });
    
    setFilteredData(updatedFilteredData);
  }, [data, fromDate, toDate]);
  
  const handleXlsxClick = () => {
    const customFileName = prompt('Enter the file name') || 'report';
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const uniqueFileName = `${customFileName}_${timestamp}`;
    const wsData = [
      ...filteredData.map((row, index) => ({
        'SL NO': index + 1,
        'Customer Name': row.customer,
        'Order Id': row.orderId,
        'Order By': row.salesPerson,
        'Order Date': row.orderDate,
        'Bill To': row.company,
        'Branch Name': row.branch,
        'Total Sell': row.sales,
        'Total Due': row.due,
      })),
      {
        'Branch Name': 'Total',
        'Total Due': totalDue,
        'Total Sell': totalSell,
      },
    ];

    const ws = XLSX.utils.json_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const xlsxBinaryArray = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    const xlsxBlob = new Blob([xlsxBinaryArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const xlsxUrl = URL.createObjectURL(xlsxBlob);
    const a = document.createElement('a');
    a.href = xlsxUrl;
    a.download = `${uniqueFileName}.xlsx`;
    a.click();
  };

  const totalDue = filteredData.reduce((acc, row) => acc + Number(row.due), 0)
    .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const totalSell = filteredData.reduce((acc, row) => acc + Number(row.sales), 0)
    .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return (
    <div className="bill-table-container">
      <Navbar />
      <h2>Bill Details</h2>
      <div className='bill-table-controls'>
        <label>From: </label>
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select start date"
        />
        <label>To: </label>
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select end date"
        />
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <button className='bill-action-buttons' type="button" onClick={handleXlsxClick}>
        XLSX
      </button>

      <table className="bill-details-table">
        <thead>
          <tr>
            <th>SL NO</th>
            <th>Customer Name</th>
            <th>Order Id</th>
            <th>Order By</th>
            <th>Order Date</th>
            <th>Bill To</th>
            <th>Branch Name</th>
            <th>Total Sell</th>
            <th>Total Due</th>
            <th>View Bill</th>
            <th>View Vat</th>
            <th>Edit Bill</th>
            <th>Pay Bill</th>
            <th>Ledger</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((row, index) => (
            <tr key={index}>
              <td>{indexOfFirstItem + index + 1}</td>
              <td>{row.customer}</td>
              <td>{row.orderId}</td>
              <td>{row.salesPerson}</td>
              <td>{row.orderDate}</td>
              <td>{row.company}</td>
              <td>{row.branch}</td>
              <td>{formatCurrency(row.sales)}</td>
              <td>{formatCurrency(row.due)}</td>
              <td>
                <button onClick={() => handleBillViewModal(row.orderId)}>Bill</button>
              </td>
              <td>
                <button onClick={() => handleVatViewModal(row.orderId)}>Vat</button>
              </td>
              <td>
                <button onClick={() => handleEditBillModal(row.orderId)}>Edit</button>
              </td>
              <td>
                <button onClick={() => handlePayModal(row.orderId, row.due)}>Pay</button>
              </td>
              <td>
                <button onClick={() => handleLedgerModal(row.orderId, row.customerId, row.customer, row.company)}>Ledger</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />

      {filteredData.length === 0 && <p>No records to show.</p>}
      {filteredData.length > 0 && (
        <div className="bill-grand-total-container">
          <span colSpan="10">Total Due:</span>
          <span colSpan="2">{formatCurrency(totalDue)}</span>
        </div>
      )}
      {filteredData.length > 0 && (
        <div className="bill-grand-total-container">
          <span colSpan="10">Total Sell:</span>
          <span colSpan="2">{formatCurrency(totalSell)}</span>
        </div>
      )}
      {selectedOrderId && selectedModal === 'edit' && (
        <div className="bill-modal">
          <div className="bill-modal-content">
            <span className="bill-close" onClick={handleCloseModal}>&times;</span>
            <EditBill orderId={selectedOrderId} />
          </div>
        </div>
      )}

      {selectedOrderId && selectedDue !== null && selectedModal === 'pay' && (
        <div className="bill-modal">
          <div className="bill-modal-content">
            <span className="bill-close" onClick={handleCloseModal}>&times;</span>
            <Pay orderId={selectedOrderId} due={selectedDue} isPasswordRequired={isPasswordRequired} />
          </div>
        </div>
      )}

      {selectedOrderId && selectedCustomerId !== null && selectedCustomer !== null
        && selectedCompany !== null && selectedModal === 'ledger' && (
          <div className="bill-modal">
            <div className="bill-modal-content">
              <span className="bill-close" onClick={handleCloseModal}>&times;</span>
              <Ledger orderIdss={selectedOrderId} customerId={selectedCustomerId} customer={selectedCustomer} company={selectedCompany} />
            </div>
          </div>
        )}
    </div>
  );
};

export default Bill;