import React, { useState, useEffect } from "react";
import "./Returns.css";
import axios from "axios";
import NavBar from "./NavBar.js";
import makeAnimated from "react-select/animated";
import Select from "react-select";

const animatedComponents = makeAnimated();

export const Returns = () => {
  const [formErrors, setFormErrors] = useState({});
  const [returnsDetailsErrors, setReturnsDetailsErrors] = useState([{}]);
  const [products, setProduct] = useState([]);
  const [orders, setOrders] = useState([]);
  const [customers, setCustomer] = useState([]);
  const [branches, setBranches] = useState([]);
  const [warehouseList, setWarehouse] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [returnsMaster, setReturnsMasterData] = useState({
    warehouse: "",
    delivery: "",
    customer: "",
    supplier: "",
    supplierId: "",
    branch: "",
    user: "",
    returnDetailsRequestList: [],
  });
  const [returnsDetails, setReturnsDetails] = useState([
    {
      cause: "",
      product: "",
      order: "",
      returnTo: "",
      uom: "",
      carton: "",
      piece: "",
      kgLt: "",
    },
  ]);

  const initialReturnsDetail = {
    cause: "",
    product: "",
    order: "",
    returnTo: "",
    uom: "",
    carton: "",
    piece: "",
    kgLt: "",
  };

  const validateFormData = (values) => {
    const errors = {};
    if (!values.warehouse) {
      errors.warehouse = "Warehouse is required";
    }
    if (!values.delivery) {
      errors.delivery = "Delivery Man name is required";
    }
    if (!values.customer) {
      errors.customer = "Customer is required";
    }
    if (!values.branch) {
      errors.branch = "Delivery Man name is required";
    }
    return errors;
  };

  const handleChange = async (name, selectedOption) => {
    let uppercaseValue = selectedOption;
    
    if (typeof selectedOption === 'object' && selectedOption.label) {
      uppercaseValue = { label: selectedOption.label.toUpperCase(), value: selectedOption.value };
    }
  
    // First, update the state with the new selected value
    setReturnsMasterData((prevValues) => ({
      ...prevValues,
      [name]: uppercaseValue || '',
    }));
  
    // Clear form errors for the current field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  
    // Handle "customer" selection
    if (name === "customer" && uppercaseValue) {
      const selectedCustomer = customers.find(customer => customer.customerId === uppercaseValue.value);
      
      if (selectedCustomer) {
        setReturnsMasterData((prevReturnMaster) => {
          const updatedData = {
            ...prevReturnMaster,
            customer: uppercaseValue || ''
          };
  
          // Set supplierId if present
          if (selectedCustomer.supplierId) {
            updatedData.supplierId = selectedCustomer.supplierId;
          } else {
            updatedData.branch = ''; // Reset branch if no supplierId
          }
  
          return updatedData;
        });
  
        // Use selectedCustomer.supplierId directly instead of returnsMaster.supplierId
        if (selectedCustomer.supplierId) {
          await fetchBranches(selectedCustomer.supplierId); // Fetch branches based on selectedCustomer's supplierId
        } else {
          setBranches(null); // Clear branches if no supplierId
        }
      }
    }
  
    // Handle "branch" selection
    if (name === "branch" && uppercaseValue && returnsMaster.supplier) {
      const selectedBranch = branches.find(branch => branch.branchName === uppercaseValue.value);
      
      if (selectedBranch) {
        setReturnsMasterData((prevReturnMaster) => ({
          ...prevReturnMaster,
          branch: uppercaseValue || '',
        }));
      }
    }
  
    // Lastly, update the form field state for the given name
    setReturnsMasterData((prevValues) => ({
      ...prevValues,
      [name]: uppercaseValue,
    }));
  };  

  const fetchBranches = async (supplierId) => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/common/branch?supplier=${supplierId}`);
      // const response = await axios.get(`http://localhost:8084/common/branch?supplier=${supplierId}`);
      if (response.data.code === 200) {
        setBranches(response.data.branchList);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fecthCustomer = async () => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/common/customer`);
      // const response = await axios.get(`http://localhost:8084/common/customer`);
      if (response.data.code === 200) {
        setCustomer(response.data.customerList);
      }
    } catch (error) {
      alert("Error fetching customer data:", error);
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const masterErrors = validateFormData(returnsMaster);
    setFormErrors(masterErrors);
    const detailsErrors = returnsDetails.map((detail) => validateReturnsDetail(detail));
    setReturnsDetailsErrors(detailsErrors);
    if (Object.keys(masterErrors).length === 0 && detailsErrors.every((errors) => Object.keys(errors).length === 0)) {
      const user = localStorage.getItem('userEmail');
      callYourAPI(returnsMaster, user);
    }
  };

  const callYourAPI = async (returnsMaster, user) => {
    try {
      const cleanedReturnDetails = returnsDetails.map(detail => {
        const { order, returnTo, uom, ...rest } = detail;
        return {
          ...rest,
          ...(order ? { order } : {}),
          ...(returnTo ? { returnTo } : {}),
          ...(uom ? { uom } : {}),
        };
      });
      const updatedFormData = { ...returnsMaster, user, returnDetailsRequestList: cleanedReturnDetails };
      console.log(updatedFormData);
      const response = await axios.post("https://inventory-mtxj.onrender.com/common/return", updatedFormData);
      // const response = await axios.post("http://localhost:8084/common/return", updatedFormData);
      if (response && response.data && response.data.code === 200) {
        alert(response.data.message);
        window.location.href = "/return";
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setFormErrors({ serverError: error.response.data.message });
      } else {
        console.error(error.response.data.message);
      }
    }
  };

  const fetchProduct = async () => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/common/product`);
      // const response = await axios.get(`http://localhost:8084/common/product`);
      if (response.data.code === 200) {
        setProduct(response.data.allProductResponse);
      }
    } catch (error) {
      alert("Error fetching product data:", error);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/common/warehouse`);
      // const response = await axios.get(`http://localhost:8084/common/warehouse`);
      if (response.data.code === 200) {
        setWarehouse(response.data.warehouseList);
      }
    } catch (error) {
      alert("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem('userEmail');
    if (!user) {
      window.location.href = "/";
    }
    fetchProduct();
    fecthCustomer();
    fetchWarehouse();
  }, [formErrors]);

  const handleRemoveRow = (index) => {
    if (returnsDetails.length > 1) {
      const updatedReturnsDetails = [...returnsDetails];
      updatedReturnsDetails.splice(index, 1);
      setReturnsDetails(updatedReturnsDetails);
    } else {
      alert("Cannot remove the only row");
    }
  };

  const handleChangeDetail = async (index, name, selectedOption) => {
    const updatedReturnsDetails = [...returnsDetails];
    const updatedReturnsDetailsErrors = [...returnsDetailsErrors];
    if (!updatedReturnsDetails[index]) {
      updatedReturnsDetails[index] = {};
    }
    if (!updatedReturnsDetailsErrors[index]) {
      updatedReturnsDetailsErrors[index] = {};
    }

    updatedReturnsDetailsErrors[index][name] = "";
    const stringValue = typeof selectedOption === 'string' ? selectedOption.toUpperCase() : selectedOption;
    updatedReturnsDetails[index][name] = stringValue;

    if (name === 'cause') {
      if (stringValue === 'DAMAGED' || stringValue === 'DATE_OVER' || stringValue === 'NO_NEED') {
        if (updatedReturnsDetails[index].product && returnsMaster.customer && returnsMaster.branch) {
          await fetchOrders(updatedReturnsDetails[index].product.value, returnsMaster.customer.value, returnsMaster.branch.value,
            returnsMaster.supplierId);
        }
      } else {
        updatedReturnsDetails[index].order = "";
        updatedReturnsDetails[index].returnTo = "";
        setOrders([]);
        setWarehouses([]);
        updatedReturnsDetails[index] = {
          ...updatedReturnsDetails[index],
          uom: "",
        };
      }
    }

    if ((name === 'product' && updatedReturnsDetails[index].product) ||
      (name === 'order' && updatedReturnsDetails[index].order) ||
      (name === 'returnTo' && updatedReturnsDetails[index].returnTo)) {
      await handleProductOrOrderChange(index, name, stringValue, updatedReturnsDetails, updatedReturnsDetailsErrors);
    }

    setReturnsDetails(updatedReturnsDetails);
    setReturnsDetailsErrors(updatedReturnsDetailsErrors);
  };

  const handleProductOrOrderChange = async (index, name, value, updatedReturnsDetails, updatedReturnsDetailsErrors) => {
    const product = updatedReturnsDetails[index].product;
    const cause = updatedReturnsDetails[index].cause;
    const order = updatedReturnsDetails[index].order;
    const returnTo = updatedReturnsDetails[index].returnTo;

    if (!updatedReturnsDetails[index]) {
      updatedReturnsDetails[index] = {};
    }
    if (!updatedReturnsDetailsErrors[index]) {
      updatedReturnsDetailsErrors[index] = {};
    }

    updatedReturnsDetails[index] = {
      ...updatedReturnsDetails[index],
      [name]: value || null,
    };

    if ((name === 'product' && product) && returnsMaster.customer && returnsMaster.branch && cause && (cause === 'DAMAGED' || cause === 'DATE_OVER' || cause === 'NO_NEED')) {
      await fetchOrders(product.value, returnsMaster.customer.value, returnsMaster.branch.value, returnsMaster.supplierId);
    }

    if (name === 'order' && order) {
      await fetchWarehouseInfo(product.value, order.value);
    }

    if (name === "returnTo" && returnTo) {
      const orderInfo = await fetchUom(product.value, order.value, returnTo.value);
      updatedReturnsDetails[index] = {
        ...updatedReturnsDetails[index],
        uom: orderInfo.uom,
      };
      updatedReturnsDetailsErrors[index].uom = "";
    } else {
      updatedReturnsDetails[index] = {
        ...updatedReturnsDetails[index],
        uom: "N/A",
      };
      updatedReturnsDetailsErrors[index].uom = "";
    }
  };


  const fetchWarehouseInfo = async (productId, orderId) => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/order/warehouse?product=${productId}&order=${orderId}`);
      // const response = await axios.get(`http://localhost:8084/order/warehouse?product=${productId}&order=${orderId}`);
      if (response.data.code === 200) {
        setWarehouses(response.data.allWarehouse || []);
      }
    } catch (error) {
      alert("Error fetching warehouse:", error);
    }
  };

  const fetchOrders = async (productId, customerId, branchId, supplier) => {
    try {
      console.log(supplier);
      const orderIdsResponse = await axios.get(`https://inventory-mtxj.onrender.com/order/product/customer?product=${productId}&&customer=${customerId}&&branch=${branchId}&&supplier=${supplier}`);
      // const orderIdsResponse = await axios.get(`http://localhost:8084/order/product/customer?product=${productId}&&customer=${customerId}&&branch=${branchId}&&supplier=${supplier}`);
      if (orderIdsResponse.data.code === 200) {
        setOrders(orderIdsResponse.data.idList);
      }
    } catch (error) {
      alert("Error fetching order IDs:", error);
    }
  };

  const fetchUom = async (productId, orderId, returnTo) => {
    try {
      const response = await axios.get(`https://inventory-mtxj.onrender.com/order/uom?product=${productId}&order=${orderId}&warehouse=${returnTo}`);
      // const response = await axios.get(`http://localhost:8084/order/uom?product=${productId}&order=${orderId}&warehouse=${returnTo}`);
      if (response.data.code === 200) {
        return {
          uom: response.data.uom,
        };
      }
    } catch (error) {
      return {
        uom: "",
      };
    }
  };

  const handleAddRow = () => {
    const newRowErrors = returnsDetails.map((detail) => validateReturnsDetail(detail));
    const hasErrors = newRowErrors.some((errors) => Object.keys(errors).length > 0);

    if (hasErrors) {
      setReturnsDetailsErrors(newRowErrors);
    } else {
      setReturnsDetails([...returnsDetails, { ...initialReturnsDetail }]);
      setReturnsDetailsErrors([...returnsDetailsErrors, {}]);
    }
  };

  const validateReturnsDetail = (detail) => {
    const errors = {};
    if (!detail.product) {
      errors.product = "Product is required";
    }
    if (!detail.cause) {
      errors.cause = "Cause is required";
    }
    if (!detail.carton) {
      errors.carton = "Carton is required";
    }
    if (!detail.piece) {
      errors.piece = "Piece is required";
    }
    if (!detail.kgLt) {
      errors.kgLt = "Kg/Lt is required";
    }
    return errors;
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      maxHeight: '200px',
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div className="returns-container">
      <NavBar />
      <main className="returns-main-content">
        <header className="returns-header">
          <a className="returns-company-name">Tanisa International LTD</a>
        </header>
        <div className="returns-form-container">
          <h1 className="returns-form-title">Add Returns</h1>
          <form className="returns-form" onSubmit={handleSubmit}>
            <div className="returns-Common-fields-container">
              <div className='returns-row'>
                <div className="returns-field">
                  <label className="returns-field-label">Warehouse:</label>
                  <Select
                    name="warehouse"
                    components={{
                      ...animatedComponents,
                    }}
                    value={returnsMaster.warehouse}
                    onChange={(value) => handleChange("warehouse", value)}
                    options={warehouseList.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                    isSearchable
                    placeholder="Select Warehouse"
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    menuPlacement='auto'
                  />
                  {formErrors.warehouse && <p className="returns-error-message">{formErrors.warehouse}</p>}
                </div>

                <div className="returns-field">
                  <label className="returns-field-label">Delivery Man:</label>
                  <input
                    type="text"
                    placeholder="Delivery Man"
                    name="delivery"
                    id="delivery"
                    value={returnsMaster.delivery}
                    onChange={(e) => handleChange("delivery", e.target.value)}
                  />
                  {formErrors.delivery && <p className="returns-error-message">{formErrors.delivery}</p>}
                </div>
              </div>
              <div className='returns-row'>
                <div className="returns-field">
                  <label className='returns-field-label'>Customer:</label>
                  <Select
                    name="customer"
                    components={{
                      ...animatedComponents,
                    }}
                    value={returnsMaster.customer}
                    onChange={(selectedOption) => handleChange("customer", selectedOption)}
                    options={customers.map((c) => ({ label: c.customerName, value: c.customerId }))}
                    placeholder="Customer"
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    menuPlacement='auto'
                  />
                  {formErrors.customer && <p className="returns-error-message">{formErrors.customer}</p>}
                </div>
                <div className="returns-field">
                  <label className='returns-field-label'>Branch:</label>
                  <Select
                    name="branch"
                    components={{
                      ...animatedComponents,
                    }}
                    value={returnsMaster.branch}
                    onChange={(selectedOption) => handleChange("branch", selectedOption)}
                    options={branches ? branches.map((branch) => ({ label: branch.branchName, value: branch.branchName })) : []}
                    placeholder="Branch"
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    menuPlacement='auto'
                  />
                  {formErrors.branch && <p className="returns-error-message">{formErrors.branch}</p>}
                </div>
              </div>
            </div>
            <div className="returns-table-container">
              <table className="returns-table">
                <thead>
                  <th>Cause</th>
                  <th>Product Name</th>
                  <th>Order</th>
                  <th>Return To</th>
                  <th>UOM</th>
                  <th>Carton</th>
                  <th>Piece</th>
                  <th>Kg/Lt</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {returnsDetails.map((detail, index) => (
                    <tr row={index}>
                      <td>
                        <select
                          name="cause"
                          value={detail.cause}
                          onChange={(e) => handleChangeDetail(index, "cause", e.target.value)}
                        >
                          <option value="">Select Cause</option>
                          <option value="DAMAGED">DAMAGED</option>
                          <option value="DATE_OVER">DATE OVER</option>
                          <option value="NO_NEED">NO NEED</option>
                          <option value="OLD_SYSTEM_RETURN">OLD SYSTEM RETURN</option>
                          <option value="OLD_RETURN">OLD RETURN</option>
                        </select>
                        {returnsDetailsErrors[index].cause && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].cause}</p>
                        )}
                      </td>
                      <td>
                        <Select
                          name="product"
                          components={{
                            ...animatedComponents,
                          }}
                          value={detail.product}
                          onChange={(selectedOption) => handleChangeDetail(index, "product", selectedOption)}
                          options={products.map((p) => ({ label: p.productName, value: p.productId }))}
                          placeholder="Product"
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPlacement='auto'
                        />
                        {returnsDetailsErrors[index].product && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].product}</p>
                        )}
                      </td>
                      <td>
                        <Select
                          name="order"
                          components={{
                            ...animatedComponents,
                          }}
                          value={detail.order || ""}
                          onChange={(selectedOption) => handleChangeDetail(index, "order", selectedOption)}
                          options={orders.map((o) => ({ label: o.orderId, value: o.orderId }))}
                          placeholder="Order"
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPlacement='auto'
                        />
                        {returnsDetailsErrors[index].order && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].order}</p>
                        )}
                      </td>
                      <td>
                        <Select
                          name="returnTo"
                          components={{
                            ...animatedComponents,
                          }}
                          value={detail.returnTo}
                          onChange={(value) => handleChangeDetail(index, "returnTo", value)}
                          options={warehouses.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                          placeholder="Return To"
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPlacement='auto'
                        />
                        {returnsDetailsErrors[index].returnTo && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].returnTo}</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          name="uom"
                          value={detail.uom}
                          readOnly={true}
                          onChange={(e) => handleChangeDetail(index, "uom", e.target.value)}
                        />
                        {returnsDetailsErrors[index].uom && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].uom}</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="number"
                          name="carton"
                          value={detail.carton}
                          onChange={(value) => handleChangeDetail(index, "carton", value.target.value)}
                        />
                        {returnsDetailsErrors[index].carton && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].carton}</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="number"
                          name="piece"
                          value={detail.piece}
                          onChange={(value) => handleChangeDetail(index, "piece", value.target.value)}
                        />
                        {returnsDetailsErrors[index].piece && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].piece}</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="number"
                          name="kgLt"
                          value={detail.kgLt}
                          onChange={(value) => handleChangeDetail(index, "kgLt", value.target.value)}
                        />
                        {returnsDetailsErrors[index].kgLt && (
                          <p className="returns-detail-error-message">{returnsDetailsErrors[index].kgLt}</p>
                        )}
                      </td>
                      <td>
                        <button className="remove-button" type="button" onClick={() => handleRemoveRow(index)}>
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <button className="returns-add-row-button" type="button" onClick={handleAddRow}>
                  Add Row
                </button>
              </table>
            </div>
            <div class="returns-center-button">
              <button class="returns-button">SUBMIT</button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

export default Returns;