import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Ledger.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';

const Ledger = ({ orderIdss, customerId, customer, company }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [commonData, setCommonData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredLedgerData, setFilteredLedgerData] = useState([]);
  const [minDueByOrderId, setMinDueByOrderId] = useState({});
  const [totalDiscountByOrderId, setTotalDiscountByOrderId] = useState({});
  const [totalSalesByOrderId, setTotalSalesByOrderId] = useState({});
  const [totalPaymentsByOrderId, setTotalPaymentsByOrderId] = useState({});
  const tableRef = useRef();
  const grandTotal = {
    sales: 0,
    payments: 0,
    discount: 0,
    due: 0,
  };

  const calculateFilteredGrandTotal = () => {
    const filteredTotalDiscount = Object.values(totalDiscountByOrderId).reduce((sum, discount) => sum + discount, 0);
    const filteredTotalSales = Object.values(totalSalesByOrderId).reduce((sum, sales) => sum + sales, 0);
    const filteredTotalPayments = Object.values(totalPaymentsByOrderId).reduce((sum, payments) => sum + payments, 0);
    const filteredTotalMinDue = Object.values(minDueByOrderId).reduce((sum, minDue) => sum + minDue, 0);

    const grandTotal = {
      discount: filteredTotalDiscount || 0,
      sales: filteredTotalSales || 0,
      payments: filteredTotalPayments || 0,
      due: filteredTotalMinDue || 0,
    };

  };

  useEffect(() => {
    calculateFilteredGrandTotal();
  }, [filteredLedgerData, totalDiscountByOrderId, totalSalesByOrderId, totalPaymentsByOrderId, minDueByOrderId]);

  useEffect(() => {
    calculateFilteredGrandTotal();
  }, [filteredLedgerData, totalDiscountByOrderId, totalSalesByOrderId, totalPaymentsByOrderId, minDueByOrderId]);

  useEffect(() => {
    const fetchLedgerData = async () => {
      try {
        const response = await axios.get(`https://inventory-mtxj.onrender.com/order/ledger?customer=${customerId}`);
        // const response = await axios.get(`http://localhost:8084/order/ledger?customer=${customerId}`);
        if (response.data && response.data.code === 200) {
          setCommonData(response.data.ledgerResponses);
        } else {
          console.error('Failed to fetch ledger data');
        }
      } catch (error) {
        console.error('Error fetching ledger data:', error);
      }
    };
    fetchLedgerData();
  }, [customerId]);

  useEffect(() => {
    const calculateTotalDiscount = (data) => {
      const newTotalDiscountByOrderId = {};
      data.forEach((entry) => {
        const orderId = entry.orderId;
        if (!newTotalDiscountByOrderId[orderId]) {
          newTotalDiscountByOrderId[orderId] = 0;
        }
        newTotalDiscountByOrderId[orderId] += entry.discount;
      });
      setTotalDiscountByOrderId(newTotalDiscountByOrderId);
    };

    calculateTotalDiscount(commonData);
    if (filteredLedgerData.length > 0) {
      calculateTotalDiscount(filteredLedgerData);
    }
  }, [commonData, filteredLedgerData]);


  useEffect(() => {
    const calculateTotalSales = (data) => {
      const newTotalSalesByOrderId = {};
      data.forEach((entry) => {
        const orderId = entry.orderId;
        if (!newTotalSalesByOrderId[orderId]) {
          newTotalSalesByOrderId[orderId] = 0;
        }
        newTotalSalesByOrderId[orderId] += entry.totalSales;
      });
      setTotalSalesByOrderId(newTotalSalesByOrderId);
    };

    calculateTotalSales(commonData);
    if (filteredLedgerData.length > 0) {
      calculateTotalSales(filteredLedgerData);
    }
  }, [commonData, filteredLedgerData]);

  useEffect(() => {
    const calculateTotalPayments = (data) => {
      const newTotalPaymentsByOrderId = {};
      data.forEach((entry) => {
        const orderId = entry.orderId;
        if (!newTotalPaymentsByOrderId[orderId]) {
          newTotalPaymentsByOrderId[orderId] = 0;
        }
        newTotalPaymentsByOrderId[orderId] += entry.totalPayments;
      });
      setTotalPaymentsByOrderId(newTotalPaymentsByOrderId);
    };

    calculateTotalPayments(commonData);
    if (filteredLedgerData.length > 0) {
      calculateTotalPayments(filteredLedgerData);
    }
  }, [commonData, filteredLedgerData]);

  useEffect(() => {
    const calculateMinDue = (data) => {
      const newMinDueByOrderId = {};
      data.forEach((entry) => {
        const orderId = entry.orderId;
        if (!newMinDueByOrderId[orderId]) {
          newMinDueByOrderId[orderId] = Number.MAX_VALUE;
        }
        entry.singleLedgerResponseList.forEach((row) => {
          newMinDueByOrderId[orderId] = Math.min(newMinDueByOrderId[orderId], row.due);
        });
      });
      setMinDueByOrderId(newMinDueByOrderId);
    };

    calculateMinDue(commonData);
    if (filteredLedgerData.length > 0) {
      calculateMinDue(filteredLedgerData);
    }
  }, [commonData, filteredLedgerData]);

  const handleSearch = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = commonData.filter((entry) =>
      entry.branch.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredLedgerData(filteredData);
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery, commonData]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const handleDateFilter = () => {
      const filteredData = commonData.filter((entry) => {
        const orderDate = entry.orderDate;
        const from = startDate ? formatDate(startDate) : null;
        const to = endDate ? formatDate(endDate) : null;
        if (from > to) {
          alert("From date should be less than or equal to date");
        }
        if (from && to) {
          return orderDate >= from && orderDate <= to;
        } else if (from) {
          return orderDate >= from;
        } else if (to) {
          return orderDate <= to;
        }

        return true;
      });

      setFilteredLedgerData(filteredData);
    };

    handleDateFilter();
  }, [startDate, endDate, commonData]);


  const totalDiscount = Object.values(totalDiscountByOrderId).reduce((sum, discount) => sum + discount, 0);
  const totalSales = Object.values(totalSalesByOrderId).reduce((sum, sales) => sum + sales, 0);
  const totalPayments = Object.values(totalPaymentsByOrderId).reduce((sum, payments) => sum + payments, 0);
  const totalMinDue = Object.values(minDueByOrderId).reduce((sum, minDue) => sum + minDue, 0);

  grandTotal.discount += totalDiscount;
  grandTotal.sales += totalSales;
  grandTotal.payments += totalPayments;
  grandTotal.due += totalMinDue;

  const handleExportToExcel = () => {
    const formattedData = commonData.flatMap((entry) =>
      entry.singleLedgerResponseList.map((row) => ({
        'SL': entry.salesPerson,
        'Responsible Person': entry.salesPerson,
        'Branch': entry.branch,
        'Order Date': entry.orderDate,
        'Have To Pay ON': entry.haveToPay,
        'Order ID': entry.orderId,
        'Sales': Number(row.sales).toFixed(2),
        'Payment Date': row.payments > 0 ? row.paymentDate : '',
        'Received': Number(row.payments).toFixed(2),
        'Discount & Return': Number(entry.discount).toFixed(2),
        'VDS': '0',
        'TDS': '0',
        'DUE': Number(row.due).toFixed(2),
      }))
    );

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Ledger Data');

    XLSX.writeFile(wb, `ledger_data_${customer}_${company}.xlsx`);
  };

  const handleExportToPDF = () => {
    const title = `Ledger of ${customer}/${company}`;
    html2canvas(tableRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.text(title, 10, 10);
      pdf.addImage(imgData, 'PNG', 10, 20, 180, 0);
      pdf.save(`ledger_data_${customer}_${company}.pdf`);
    });
  };

  return (
    <div className="ledger-container">
      <h1>Ledger: {customer}/{company}</h1>
      <div className="export-buttons">
        <button onClick={handleExportToExcel}>Export to Excel</button>
        <button onClick={handleExportToPDF}>Export to PDF</button>
      </div>
      <div className="date-filters">
        <label>From: </label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select start date"
        />
        <label>To: </label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          dateFormat="dd-MM-yyyy"
          placeholderText="Select end date"
        />
        <input
          type="text"
          placeholder="Search by branch"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <table ref={tableRef} className="ledger-table">
        <thead>
          <tr>
            <th>SL</th>
            <th>Responsible Person</th>
            <th>Branch</th>
            <th>Order Date</th>
            <th>Have To Pay ON</th>
            <th>Order ID</th>
            <th>Sales</th>
            <th>Payment Date</th>
            <th>Received</th>
            <th>Discount & Return</th>
            <th>VDS</th>
            <th>TDS</th>
            <th>DUE</th>
          </tr>
        </thead>
        <tbody>
          {filteredLedgerData.map((entry, index) => (
            entry.singleLedgerResponseList.map((row, rowIndex) => (
              <tr key={`${index}_${rowIndex}`}>
                <td>{index + 1}</td>
                <td>{entry.salesPerson}</td>
                <td>{entry.branch}</td>
                <td>{entry.orderDate}</td>
                <td>{entry.haveToPay}</td>
                <td>{entry.orderId}</td>
                <td>{Number(row.sales).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                <td style={{ background: row.paymentDate ? 'yellow' : 'transparent' }}>
                  {row.payments > 0 ? row.paymentDate : ''}
                </td>
                <td style={{ background: row.payments > 0 ? 'yellow' : 'transparent' }}>
                  {Number(row.payments).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </td>
                <td>
                  {Number(entry.discount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </td>
                <td>0</td>
                <td>0</td>
                <td>{Number(row.due).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              </tr>
            ))
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan="6">Grand Total</td>
            <td>{Number(grandTotal.sales).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
            <td></td>
            <td>{Number(grandTotal.payments).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
            <td>{Number(grandTotal.discount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
            <td></td>
            <td></td>
            <td>{Number(grandTotal.due).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
          </tr>
        </tfoot>
      </table>

    </div>
  );
};

export default Ledger;