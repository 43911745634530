import React, { useState, useEffect } from 'react';
import './Order.css';
import axios from "axios";
import NavBar from "./NavBar.js";
import Customer from './Customer.js'
import Supplier from './Supplier.js';
import Sale from './Sale.js';
import Branch from './Branch.js';
import makeAnimated from "react-select/animated";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SalesPerson from "./SalesPerson.js"

const animatedComponents = makeAnimated();

const Order = () => {
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [showBranchModal, setBranchModal] = useState(false);
    const [showSaleModal, setShowSaleModal] = useState(false);
    const [showSalesPersonModal, setShowSalesPersonModal] = useState(false);
    const [products, setProduct] = useState([]);
    const [customers, setCustomer] = useState([]);
    const [productShipments, setProductShipments] = useState([]);
    const [sales, setSales] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [orderDetailsErrors, setOrderDetailsErrors] = useState([{}]);
    const [branches, setBranches] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [previousChallan, setPreviousChallan] = useState({
        previousChalan: 0,
    });
    const [orderMaster, setMasterData] = useState({
        customer: "",
        companyName: "",
        address: "",
        deliveryAddress: "",
        supplier: "",
        supplierId: "",
        branch: "",
        customerType: "",
        orderDate: "",
        deliveryDate: "",
        creditTerm: 0,
        challanNo: "",
        binNo: "",
        orderBy: "",
        invoiceCompany: "",
        user: "",
        orderDetails: [],
    });
    const [orderDetail, setOrderDetail] = useState([
        {
            product: "",
            shipment: "",
            warehouse: "",
            article: "",
            saleCode: "",
            vat: "",
            uom: "",
            available: "",
            quantity: "",
            piece: "",
            discount: "",
            remarks: "",
            lastPrice: "",
            price: "",
            totalPrice: "",
        },
    ]);

    const initialOrderDetail = {
        product: "",
        shipment: "",
        warehouse: "",
        article: "",
        saleCode: "",
        vat: "",
        uom: "",
        available: "",
        quantity: "",
        piece: "",
        discount: "",
        remarks: "",
        price: "",
        totalPrice: "",
    };
    const calculateGrandTotal = () => {
        return orderDetail.reduce((total, detail) => total + Number(detail.totalPrice), 0).toFixed(2);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const masterErrors = validateFormData(orderMaster);
        setFormErrors(masterErrors);
        const detailsErrors = orderDetail.map((detail) => validateOrderDetail(detail));
        setOrderDetailsErrors(detailsErrors);
        if (Object.keys(masterErrors).length === 0 && detailsErrors.every((errors) => Object.keys(errors).length === 0)) {
            const user = localStorage.getItem('userEmail');
            callYourAPI(orderMaster, user);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const callYourAPI = async (orderMaster, user) => {
        try {
            const formattedOrderDate = orderMaster.orderDate ? formatDate(orderMaster.orderDate) : null;
            const formattedDeliveryDate = orderMaster.deliveryDate ? formatDate(orderMaster.deliveryDate) : null;
            const numericOrderDetail = orderDetail.map(detail => ({
                ...detail,
                discount: parseInt(detail.discount, 10),
                quantity: parseInt(detail.quantity, 10),
            }));

            const isInvalidQuantity = numericOrderDetail.some((detail) => detail.quantity > detail.available);
            const isInvalidDiscount = numericOrderDetail.some((detail) => detail.discount > detail.quantity);

            if (isInvalidQuantity) {
                alert("Quantity cannot be greater than available!");
                return;
            }

            if (isInvalidDiscount) {
                alert("Discount cannot be greater than quantity!");
                return;
            }

            const updatedFormData = {
                ...orderMaster,
                user,
                orderDetails: orderDetail,
                orderDate: formattedOrderDate,
                deliveryDate: formattedDeliveryDate,
            };
            console.log(updatedFormData);
            const response = await axios.post("https://inventory-mtxj.onrender.com/order", updatedFormData);
            // const response = await axios.post("http://localhost:8084/order", updatedFormData);
            if (response && response.data && response.data.code === 200) {
                alert(response.data.message);
                window.location.href = "/order";
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setFormErrors({ serverError: error.response.data.message });
            } else {
                console.error(error.response.data.message);
            }
        }
    };

    const handleChange = async (name, selectedOption) => {
        let uppercaseValue = selectedOption;
        if (typeof selectedOption === 'object' && selectedOption.label) {
            uppercaseValue = { label: selectedOption.label.toUpperCase(), value: selectedOption.value };
        }

        setMasterData((prevValues) => ({
            ...prevValues,
            [name]: uppercaseValue || '',
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        if (name === "customer" && uppercaseValue) {
            const selectedCustomer = customers.find(customer => customer.customerId === uppercaseValue.value);
            if (selectedCustomer) {
                setMasterData((prevOrderMaster) => ({
                    ...prevOrderMaster,
                    customer: uppercaseValue || '',
                    customerName: selectedCustomer.customerName || "",
                    companyName: selectedCustomer.company || "",
                    customerType: selectedCustomer.customerType || "",
                    supplier: selectedCustomer.supplier || "",
                    supplierId: selectedCustomer.supplierId || "",
                    address: selectedCustomer.address || "",
                    binNo: selectedCustomer.binNo || "",
                    creditTerm: selectedCustomer.creditTerm || 0,
                }));
                if (selectedCustomer.supplierId) {
                    await fetchBranches(selectedCustomer.supplierId);
                } else {
                    setBranches(null);
                    setMasterData((prevOrderMaster) => ({
                        ...prevOrderMaster,
                        branch: '',
                        deliveryAddress: "",
                    }));
                }
            }
        }
        if (name === "branch" && uppercaseValue && orderMaster.supplier) {
            const selectedBranch = branches.find(branch => branch.branchName === uppercaseValue.value);
            if (selectedBranch) {
                setMasterData((prevOrderMaster) => ({
                    ...prevOrderMaster,
                    branch: uppercaseValue || '',
                    deliveryAddress: selectedBranch.branchAddress || "",
                }));
            }
        }
    };

    useEffect(() => {
        if (orderMaster.invoiceCompany && orderMaster.deliveryDate) {
            fetchLastChallan();
        }
    }, [orderMaster.invoiceCompany, orderMaster.deliveryDate]);

    const fetchBranches = async (supplierId) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/branch?supplier=${supplierId}`);
            // const response = await axios.get(`http://localhost:8084/common/branch?supplier=${supplierId}`);
            if (response.data.code === 200) {
                setBranches(response.data.branchList);
            }
        } catch (error) {
            console.error("Error fetching branches:", error);
        }
    };

    const fetchLastChallan = async () => {
        const deliveryDate = formatDate(orderMaster.deliveryDate);
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/order/invoice?company=${orderMaster.invoiceCompany}&&delivery=${deliveryDate}`);
            // const response = await axios.get(`http://localhost:8084/order/invoice?company=${orderMaster.invoiceCompany}&&delivery=${deliveryDate}`);
            if (response.data && response.data.code === 200) {
                setPreviousChallan({ previousChalan: response.data.invoice });
            } else {
                setPreviousChallan({ previousChalan: 0 });
            }
        } catch (error) {
            console.error("Error fetching last challan:", error);
        }
    };

    const handleChangePrevious = (e) => {
        const { name, value } = e.target;
    };
    const handleChangeDetail = async (index, name, selectedOption) => {
        const updatedOrderDetail = [...orderDetail];
        const updatedOrderDetailsErrors = [...orderDetailsErrors];
        updatedOrderDetailsErrors[index][name] = "";

        let uppercaseValue;

        if (selectedOption && typeof selectedOption === 'object') {
            if (selectedOption.label) {
                uppercaseValue = selectedOption.label.toUpperCase();
            } else {
                console.error("Invalid selectedOption structure. Missing label property.");
                return;
            }
        } else {
            uppercaseValue = String(selectedOption).toUpperCase();
        }

        updatedOrderDetail[index][name] = uppercaseValue;
        if (name === "product") {
            updatedOrderDetail[index][name] = selectedOption;
        } else if (name === "shipment") {
            updatedOrderDetail[index][name] = selectedOption;
        } else if (name === "warehouse") {
            updatedOrderDetail[index][name] = selectedOption;
        } else {
            updatedOrderDetail[index][name] = uppercaseValue;
        }
        if (name === "product" || name === "shipment" || name === "warehouse") {
            await handleProductOrShipmentChange(index, name, updatedOrderDetail, updatedOrderDetailsErrors);
        }
        updatedOrderDetail[index].totalPrice = calculateTotal(updatedOrderDetail[index]);
        updatedOrderDetailsErrors[index].totalPrice = "";
        setOrderDetail(updatedOrderDetail);
        setOrderDetailsErrors(updatedOrderDetailsErrors);
    };

    const handleProductOrShipmentChange = async (index, name, updatedOrderDetail, updatedOrderDetailsErrors) => {
        const product = updatedOrderDetail[index].product;
        const shipment = updatedOrderDetail[index].shipment;
        const warehouse = updatedOrderDetail[index].warehouse;
        if (product) {
            await fetchShipments(product.value);

            if (orderMaster.supplierId && orderMaster.supplierId !== "LOCAL") {
                const saleDetails = await fetchSaleCode(product.value, orderMaster.supplierId);
                updatedOrderDetail[index] = {
                    ...updatedOrderDetail[index],
                    article: saleDetails.article,
                    saleCode: saleDetails.saleCode,
                };
                updatedOrderDetailsErrors[index].saleCode = "";
                updatedOrderDetailsErrors[index].article = "";
            } else {
                updatedOrderDetail[index] = {
                    ...updatedOrderDetail[index],
                    saleCode: "",
                    article: "",
                };
                updatedOrderDetailsErrors[index].saleCode = "";
                updatedOrderDetailsErrors[index].article = "";
            }
            if (orderMaster.customer) {
                const price1 = await fetchLastPrice(orderMaster.customer.value, product.value);
                updatedOrderDetail[index] = {
                    ...updatedOrderDetail[index],
                    lastPrice: price1 && price1.price !== undefined ? price1.price : 0,
                };
                updatedOrderDetailsErrors[index].lastPrice = "";
            } else {
                updatedOrderDetail[index] = {
                    ...updatedOrderDetail[index],
                    lastPrice: 0,
                };
                updatedOrderDetailsErrors[index].lastPrice = "";
            }
        }
        if (name === "shipment" && shipment) {
            await fetchWarehouseInfo(product.value, shipment.value);
        };
        if (name === "warehouse" && warehouse) {
            const uomAndAvailable = await fetchUomAndAvaiableInfo(product.value, shipment.value, warehouse.value);
            updatedOrderDetail[index] = {
                ...updatedOrderDetail[index],
                uom: uomAndAvailable.uom,
                available: uomAndAvailable.available,
            };
            updatedOrderDetailsErrors[index].uom = "";
            updatedOrderDetailsErrors[index].available = "";
        } else {
            updatedOrderDetail[index] = {
                ...updatedOrderDetail[index],
                uom: "N/A",
                available: 0,
            };
            updatedOrderDetailsErrors[index].uom = "";
            updatedOrderDetailsErrors[index].available = "";
        }
    };

    const fetchShipments = async (productId) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/import/shipment?product=${productId}`);
            // const response = await axios.get(`http://localhost:8084/import/shipment?product=${productId}`);
            if (response.data.code === 200) {
                setProductShipments(response.data.allShipment || []);
            }
        } catch (error) {
            alert("Error fetching shipments:", error);
        }
    };

    const fetchUomAndAvaiableInfo = async (productId, shipmentNo, warehouse) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/import/uom/available?product=${productId}&shipment=${shipmentNo}&warehouse=${warehouse}`);
            // const response = await axios.get(`http://localhost:8084/import/uom/available?product=${productId}&shipment=${shipmentNo}&warehouse=${warehouse}`);
            if (response.data.code === 200) {
                return {
                    uom: response.data.uom,
                    available: response.data.available,
                };
            }
        } catch (error) {
            return {
                uom: "",
                available: 0,
            };
        }
    };

    const fetchSaleCode = async (productId, supplierIds) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/sale?product=${productId}&supplier=${supplierIds}`);
            // const response = await axios.get(`http://localhost:8084/common/sale?product=${productId}&supplier=${supplierIds}`);
            if (response.data.code === 200) {
                return {
                    sale: response.data.saleName,
                    saleCode: response.data.saleCode,
                    article: response.data.article,
                };
            }
        } catch (error) {
            return {
                sale: "",
                article: "",
                saleCode: "",
            };
        }
    };
    const fetchWarehouseInfo = async (productId, shipmentNo) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/import/warehouse?product=${productId}&shipment=${shipmentNo}`);
            // const response = await axios.get(`http://localhost:8084/import/warehouse?product=${productId}&shipment=${shipmentNo}`);
            if (response.data.code === 200) {
                setWarehouses(response.data.allWarehouse || []);
            }
        } catch (error) {
            alert("Error fetching warehouse:", error);
        }
    };
    const fetchLastPrice = async (customerId, productId) => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/order/price?customer=${customerId}&product=${productId}`);
            // const response = await axios.get(`http://localhost:8084/order/price?customer=${customerId}&product=${productId}`);
            if (response.data.code === 200) {
                return {
                    price: response.data.price,
                };
            }
        } catch (error) {
            return {
                price: 0,
            };
        }
    };
    const calculateTotal = (detail) => {
        if (!detail.price || (!detail.quantity)) {
            return 0;
        }
        const uom = detail.uom;
        if (!uom) {
            alert("Please select a valid UOM");
        }
        if (detail.discount !== null) {
            const quantity = detail.quantity;
            return ((quantity - detail.discount) * detail.price);
        } else {
            return detail.quantity * detail.price;
        }
    };
    const handleAddRow = () => {
        const newRowErrors = orderDetail.map((detail) => validateOrderDetail(detail));
        const hasErrors = newRowErrors.some((errors) => Object.keys(errors).length > 0);
        if (hasErrors) {
            setOrderDetailsErrors(newRowErrors);
        } else {
            setOrderDetail([...orderDetail, { ...initialOrderDetail }]);
            setOrderDetailsErrors([...orderDetailsErrors, {}]);
        }
    };
    const handleRemoveRow = (index) => {
        if (orderDetail.length > 1) {
            const updatedOrderDetail = [...orderDetail];
            updatedOrderDetail.splice(index, 1);
            setOrderDetail(updatedOrderDetail);
        } else {
            alert("Cannot remove the only row");
        }
    };
    const fetchProduct = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/product`);
            // const response = await axios.get(`http://localhost:8084/common/product`);
            if (response.data.code === 200) {
                setProduct(response.data.allProductResponse);
            }
        } catch (error) {
            alert("Error fetching product data:", error);
        }
    };
    const fecthCustomer = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/customer`);
            // const response = await axios.get(`http://localhost:8084/common/customer`);
            if (response.data.code === 200) {
                setCustomer(response.data.customerList);
            }
        } catch (error) {
            alert("Error fetching customer data:", error);
        }
    }
    const fetchSalesPerson = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/sales_person`);
            // const response = await axios.get(`http://localhost:8084/common/sales_person`);
            if (response.data.code === 200) {
                setSales(response.data.singleSalesPersonResponseList);
            }
        } catch (error) {
            alert("Error fetching customer data:", error);
        }
    }
    const handleOpenCustomerModal = () => {
        setShowCustomerModal(true);
    };
    const handleCloseCustomerModal = () => {
        setShowCustomerModal(false);
    };
    const handleOpenSupplierModal = () => {
        setShowSupplierModal(true);
    }
    const handleOpenBranchModal = () => {
        setBranchModal(true);
    }
    const handleCloseSupplierModal = () => {
        setShowSupplierModal(false);
    };
    const handleOpenSaleModal = () => {
        setShowSaleModal(true);
    }
    const handleCloseSaleModal = () => {
        setShowSaleModal(false);
    };
    const handleCloseBranchModal = () => {
        setBranchModal(false);
    };
    const handleBillsVat = () => {
        const newPageUrl = "/bill";
        window.open(newPageUrl, "_blank");
    }
    const handleOrderDetails = () => {
        const newPageUrl = "/order_details";
        window.open(newPageUrl, "_blank");
    }
    const handleOpenSalesPersonModal = () => {
        setShowSalesPersonModal(true);
    }
    const handleCloseSalesPersonModal = () => {
        setShowSalesPersonModal(false);
    }
    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchProduct();
        fecthCustomer();
        fetchSalesPerson();
    }, [formErrors]);

    const validateFormData = (values) => {
        const errors = {};
        if (!values.customer) {
            errors.customer = "Customer is required";
        }
        if (!values.orderDate) {
            errors.orderDate = "Order Date is required";
        }
        if (!values.deliveryDate) {
            errors.deliveryDate = "Delivery Date is required";
        }
        if (!values.orderBy) {
            errors.orderBy = "Order By is required";
        }
        if (!values.supplier) {
            errors.supplier = "Supplier is required";
        }
        if (!values.branch) {
            errors.branch = "Branch is required";
        }
        return errors;
    };

    const validateOrderDetail = (detail) => {
        const errors = {};
        if (!detail.product) {
            errors.product = "Product is required";
        }
        if (!detail.shipment) {
            errors.shipment = "Shipment is required";
        }
        if (!detail.uom) {
            errors.uom = "Uom is required";
        }
        if (!detail.available) {
            errors.available = "Available is required";
        }
        if (!detail.quantity) {
            errors.quantity = "Quantity is required";
        }
        if (!detail.piece) {
            errors.piece = "Piece is required";
        }
        if (!detail.price) {
            errors.price = "Price is required";
        }
        if (!detail.totalPrice) {
            errors.totalPrice = "Total Price is required";
        }
        if (!detail.warehouse) {
            errors.warehouse = "Warehouse is required";
        }
        return errors;
    };

    function formatCurrency(amount) {
        if (isNaN(amount)) {
            return amount;
        }

        return Number(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            maxHeight: '200px',
            overflowY: 'auto',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '200px',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };


    return (
        <div className="order-container">
            <NavBar />
            <main className="order-main-content">
                <header className="order-header">
                    <a className="order-company-name">Tanisa International LTD</a>
                </header>
                <div className="order-action-buttons">

                    <button className="order-supplier" onClick={handleOpenSupplierModal}>Supplier</button>
                    <button className="order-customer" onClick={handleOpenCustomerModal}>Customer</button>
                    <button className="order-branch" onClick={handleOpenBranchModal}>Branch</button>
                    <button className="order-sale" onClick={handleOpenSaleModal}>Sale & Article</button>
                    <button className="order-sales-person" onClick={handleOpenSalesPersonModal}>Sales Person</button>
                    <button className="order-bills-vat" onClick={handleBillsVat}>Bills & Vat</button>
                    <button className="order-details" onClick={handleOrderDetails}>Order Details</button>
                </div>
                <div className="order-form-container">
                    <h1 className="order-form-title">Add Order</h1>
                    <form className="order-form" onSubmit={handleSubmit}>
                        <div className="order-Common-fields-container">
                            <div className='order-row'>
                                <div className="order-field">
                                    <label className='order-field-label'>Order By:</label>
                                    <Select
                                        name="orderBy"
                                        components={{
                                            ...animatedComponents,
                                        }}
                                        value={orderMaster.orderBy}
                                        onChange={(selectedOption) => handleChange("orderBy", selectedOption)}
                                        options={sales.map((s) => ({ label: s.salesPersonName, value: s.salesPersonId }))}
                                        isSearchable
                                        placeholder="Sales Person"
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        menuPlacement='auto'
                                    />
                                    {formErrors.orderBy && <p className="order-error-message">{formErrors.orderBy}</p>}
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>Customer:</label>
                                    <Select
                                        name="customer"
                                        components={{
                                            ...animatedComponents,
                                        }}
                                        value={orderMaster.customer}
                                        onChange={(selectedOption) => handleChange("customer", selectedOption)}
                                        options={customers.map((c) => ({ label: c.customerName, value: c.customerId }))}
                                        placeholder="Customer"
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        menuPlacement='auto'
                                    />
                                    {formErrors.customer && <p className="order-error-message">{formErrors.customer}</p>}
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>Branch:</label>
                                    <Select
                                        name="branch"
                                        components={{
                                            ...animatedComponents,
                                        }}
                                        value={orderMaster.branch}
                                        onChange={(selectedOption) => handleChange("branch", selectedOption)}
                                        options={branches ? branches.map((branch) => ({ label: branch.branchName, value: branch.branchName })) : []}
                                        placeholder="Branch"
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        menuPlacement='auto'
                                    />
                                    {formErrors.branch && <p className="order-error-message">{formErrors.branch}</p>}
                                </div>
                            </div>

                            <div className='order-row'>
                                <div className="order-field">
                                    <label className='order-field-label'>Order Date:</label>
                                    <DatePicker
                                        selected={orderMaster.orderDate}
                                        onChange={(date) => handleChange("orderDate", date)}
                                        placeholderText="Order Date"
                                        dateFormat="dd-MM-yyyy"  // Adjust the date format as needed
                                    />
                                    {formErrors.orderDate && <p className="order-error-message">{formErrors.orderDate}</p>}
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>Delivery Date:</label>
                                    <DatePicker
                                        selected={orderMaster.deliveryDate}
                                        onChange={(date) => handleChange("deliveryDate", date)}
                                        placeholderText="Delivery Date"
                                        dateFormat="dd-MM-yyyy"  // Adjust the date format as needed
                                    />
                                    {formErrors.deliveryDate && <p className="order-error-message">{formErrors.deliveryDate}</p>}
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>Type:</label>
                                    <input
                                        type="text"
                                        placeholder="Customer Type"
                                        name="customerType"
                                        id="customerType"
                                        readOnly={true}
                                        value={orderMaster.customerType}
                                        onChange={(e) => handleChange("customerType", e.target.value)}
                                    />
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>Credit Term:</label>
                                    <input
                                        type="number"
                                        placeholder="Credit Term"
                                        name="creditTerm"
                                        id="creditTerm"
                                        value={orderMaster.creditTerm}
                                        readOnly={true}
                                        onChange={(e) => handleChange("creditTerm", e.target.value)}
                                    />
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>BIN No:</label>
                                    <input
                                        type="text"
                                        placeholder="BIN No"
                                        name="binNo"
                                        id="binNo"
                                        readOnly={true}
                                        value={orderMaster.binNo}
                                        onChange={(e) => handleChange("binNo", e.target.value)}
                                    />
                                </div>
                                <div>
                                    <div className="order-field">
                                        <label className='order-field-label'>Challan Company:</label>
                                        <select
                                            name="invoiceCompany"
                                            value={orderMaster.invoiceCompany}
                                            onChange={(e) => handleChange("invoiceCompany", e.target.value)}
                                        >
                                            <option value="">Select Company</option>
                                            <option value="TANISA_INTERNATIONAL_LTD">TANISA INTERNATIONAL LTD</option>
                                            <option value="FLAGSHIP_INTERNATIONAL_LTD">FLAGSHIP INTERNATIONAL (PVT.) LTD.</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>Previous Challan:</label>
                                    <input
                                        type="number"
                                        placeholder="Previous Challan No"
                                        name="previous"
                                        id="previous"
                                        readOnly={true}
                                        value={previousChallan.previousChalan}
                                        onChange={handleChangePrevious}
                                    />
                                </div>
                                <div className="order-field">
                                    <label className='order-field-label'>Challan No:</label>
                                    <input
                                        type="number"
                                        placeholder="Challan No"
                                        name="challanNo"
                                        id="challanNo"
                                        value={orderMaster.challanNo}
                                        onChange={(e) => handleChange("challanNo", e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='order-row'>
                                <div className="order-field">
                                    <label className='order-field-label'>Address:</label>
                                    <input
                                        type="text"
                                        placeholder="Customer Address"
                                        name="address"
                                        id="address"
                                        readOnly={true}
                                        value={orderMaster.address}
                                        onChange={(e) => handleChange("address", e.target.value)}
                                    />
                                </div>

                                <div className="order-field">
                                    <label className='order-field-label'>Delivery Address:</label>
                                    <input
                                        type="text"
                                        placeholder="Delivery Address"
                                        name="delivery"
                                        id="delivery"
                                        value={orderMaster.deliveryAddress}
                                        readOnly={true}
                                        onChange={(e) => handleChange("deliveryAddress", e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='order-row'>
                                <div className="order-field">
                                    <label className='order-field-label'>Company:</label>
                                    <input
                                        type="text"
                                        placeholder="company Name"
                                        name="company"
                                        id="company"
                                        readOnly={true}
                                        value={orderMaster.companyName}
                                        onChange={(e) => handleChange("companyName", e.target.value)}
                                    />
                                </div>

                                <div className="order-field">
                                    <label className='order-field-label'>Supplier:</label>
                                    <input
                                        type="text"
                                        placeholder="Supplier Code"
                                        name="supplier"
                                        id="supplier"
                                        readOnly={true}
                                        value={orderMaster.supplier}
                                        onChange={(e) => handleChange("supplier", e.target.value)}
                                    />
                                    {formErrors.supplier && <p className="order-error-message">{formErrors.supplier}</p>}
                                </div>
                            </div>
                        </div>

                        <div className='order-table-container'>
                            <table className="order-table">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Shipment No</th>
                                        <th>Warehouse</th>
                                        <th>Article</th>
                                        <th>Sale Code</th>
                                        <th>Vat</th>
                                        <th>UOM</th>
                                        <th>Available</th>
                                        <th>Quantity</th>
                                        <th>Quantity in PIECE</th>
                                        <th>Discount</th>
                                        <th>Remarks</th>
                                        <th>Last Price</th>
                                        <th>Unit Price</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetail.map((detail, index) => (
                                        <tr row={index}>
                                            <td>
                                                <Select
                                                    name="product"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.product || ""}
                                                    onChange={(selectedOption) => handleChangeDetail(index, "product", selectedOption)}
                                                    options={products.map((p) => ({ label: p.productName, value: p.productId }))}
                                                    placeholder="Product"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {orderDetailsErrors[index].product && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].product}</p>
                                                )}
                                            </td>

                                            <td>
                                                <Select
                                                    name="shipment"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.shipment || ""}
                                                    onChange={(selectedOption) => handleChangeDetail(index, "shipment", selectedOption)}
                                                    options={productShipments.map((s) => ({ label: s.shipmentNo, value: s.shipmentNo }))}
                                                    placeholder="Shipment"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {orderDetailsErrors[index].shipment && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].shipment}</p>
                                                )}
                                            </td>

                                            <td>
                                                <Select
                                                    name="warehouse"
                                                    components={{
                                                        ...animatedComponents,
                                                    }}
                                                    value={detail.warehouse || ""}
                                                    onChange={(selectedOption) => handleChangeDetail(index, "warehouse", selectedOption)}
                                                    options={warehouses.map((w) => ({ label: w.warehouseName, value: w.warehouseId }))}
                                                    placeholder="Warehouse"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body}
                                                    menuPlacement='auto'
                                                />
                                                {orderDetailsErrors[index].warehouse && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].warehouse}</p>
                                                )}
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Article Code"
                                                    name="article"
                                                    id="article"
                                                    readOnly={true}
                                                    value={detail.article}
                                                    onChange={(e) => handleChangeDetail(index, "article", e.target.value)}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Sale Code"
                                                    name="saleCode"
                                                    id="saleCode"
                                                    readOnly={true}
                                                    value={detail.saleCode}
                                                    onChange={(e) => handleChangeDetail(index, "saleCode", e.target.value)}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    name="vat"
                                                    placeholder="Vat"
                                                    value={detail.vat}
                                                    onChange={(e) => handleChangeDetail(index, "vat", e.target.value)}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    name="uom"
                                                    placeholder='UOM'
                                                    value={detail.uom}
                                                    readOnly={true}
                                                    onChange={(e) => handleChangeDetail(index, "uom", e.target.value)}
                                                />
                                                {orderDetailsErrors[index].uom && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].uom}</p>
                                                )}
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    name="available"
                                                    placeholder='Available'
                                                    value={detail.available}
                                                    readOnly={true}
                                                    onChange={(e) => handleChangeDetail(index, "available", e.target.value)}
                                                />
                                                {orderDetailsErrors[index].available && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].available}</p>
                                                )}
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    placeholder="Quantity"
                                                    value={detail.quantity}
                                                    onChange={(e) => handleChangeDetail(index, "quantity", e.target.value)}
                                                />
                                                {orderDetailsErrors[index].quantity && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].quantity}</p>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="piece"
                                                    placeholder="piece"
                                                    value={detail.piece}
                                                    onChange={(e) => handleChangeDetail(index, "piece", e.target.value)}
                                                />
                                                {orderDetailsErrors[index].piece && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].piece}</p>
                                                )}
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    name="discount"
                                                    placeholder="Discount"
                                                    value={detail.discount}
                                                    onChange={(e) => handleChangeDetail(index, "discount", e.target.value)}
                                                />
                                                {orderDetailsErrors[index].discount && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].discount}</p>
                                                )}
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    name="remarks"
                                                    placeholder='Remarks'
                                                    value={detail.remarks}
                                                    onChange={(e) => handleChangeDetail(index, "remarks", e.target.value)}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    placeholder="Last Price"
                                                    name="lastPrice"
                                                    id="lastPrice"
                                                    readOnly={true}
                                                    value={detail.lastPrice}
                                                    onChange={(e) => handleChangeDetail(index, "lastPrice", e.target.value)}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    name="price"
                                                    placeholder="Unit Price"
                                                    value={detail.price}
                                                    onChange={(e) => handleChangeDetail(index, "price", e.target.value)}
                                                />
                                                {orderDetailsErrors[index].price && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].price}</p>
                                                )}
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    name="total"
                                                    placeholder="Total"
                                                    value={` ${formatCurrency(detail.totalPrice)}৳`}
                                                    readOnly={true}
                                                    onChange={(e) => handleChangeDetail(index, "totalPrice", e.target.value)}
                                                />

                                                {orderDetailsErrors[index].totalPrice && (
                                                    <p className="order-detail-error-message">{orderDetailsErrors[index].totalPrice}</p>
                                                )}

                                            </td>

                                            <td>
                                                <button className='remove-button' type="button" onClick={() => handleRemoveRow(index)}>
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                                <tr >
                                    <td colSpan="14" >Grand Total</td>
                                    <td colSpan="2">{formatCurrency(calculateGrandTotal())} &#2547;</td>
                                </tr>

                                <button className="order-add-row-button" type="button" onClick={handleAddRow}>
                                    Add Row
                                </button>

                            </table>
                        </div>
                        <button className="order-submit">Submit</button>
                    </form>
                </div>
            </main>
            {showCustomerModal && (
                <div className="order-modal">
                    <div className="order-modal-content">
                        <span className="order-close" onClick={handleCloseCustomerModal}>&times;</span>
                        <Customer />
                    </div>
                </div>
            )}
            {showSupplierModal && (
                <div className="order-modal">
                    <div className="order-modal-content">
                        <span className="order-close" onClick={handleCloseSupplierModal}>&times;</span>
                        <Supplier />
                    </div>
                </div>
            )}
            {showSaleModal && (
                <div className="order-modal">
                    <div className="order-modal-content">
                        <span className="order-close" onClick={handleCloseSaleModal}>&times;</span>
                        <Sale />
                    </div>
                </div>
            )}
            {showBranchModal && (
                <div className="order-modal">
                    <div className="order-modal-content">
                        <span className="order-close" onClick={handleCloseBranchModal}>&times;</span>
                        <Branch />
                    </div>
                </div>
            )}
            {showSalesPersonModal && (
                <div className="order-modal">
                    <div className="order-modal-content">
                        <span className="order-close" onClick={handleCloseSalesPersonModal}>&times;</span>
                        <SalesPerson />
                    </div>
                </div>
            )}
        </div>
    );
};
export default Order;