import React, { useState, useEffect } from "react";
import axios from "axios";
import './Customer.css';
import makeAnimated from "react-select/animated";
import Select from "react-select";

const animatedComponents = makeAnimated();

export const Customer = () => {
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const initialValues = {
        id: "",
        name: "",
        company: "",
        type: "",
        address: "",
        creditTerm: 0,
        contact: "",
        binNo: "",
        supplier: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [suppliers, setSuppliers] = useState([]);

    const fectchSupplier = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/supplier`);
            // const response = await axios.get(`http://localhost:8084/common/supplier`);
            if (response.data.code === 200) {
                setSuppliers(response.data.supplierList);
            }
        } catch (error) {
            alert("Error fetching suppliers:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const uppercaseValue = value.toUpperCase();
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        setFormValues({ ...formValues, [name]: uppercaseValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const user = localStorage.getItem('userEmail');
            callYourAPI(formValues, user);
        }
    };

    const callYourAPI = async (formData, user) => {
        try {
            const filteredFormData = Object.fromEntries(
                Object.entries(formData).filter(([key, value]) => value !== null)
            );
            const updatedFormData = { ...filteredFormData, user };
            const response = await axios.post(`https://inventory-mtxj.onrender.com/common/customer`, updatedFormData);
            // const response = await axios.post(`http://localhost:8084/common/customer`, updatedFormData);
            if (response && response.data && response.data.code === 200) {
                alert(response.data.message);
                window.location.href = "/order";
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.message) {
                    alert(error.response.data.message)
                }
            } else {
                alert(error);
            }
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fectchSupplier();
    }, [formErrors]);

    const handleSupplierChange = (selectedOption) => {
        setSelectedSupplier(selectedOption);
        setFormValues({
            ...formValues,
            supplier: selectedOption.value || null,
        });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.id) {
            errors.id = "Customer Id is required";
        }
        if (!values.name) {
            errors.name = "Customer Name is required";
        }
        if (!values.contact) {
            errors.contact = "Phone Number is required";
        }
        if (!values.type) {
            errors.type = "Customer Type is required";
        }
        if (!values.address) {
            errors.address = "Customer Address is required";
        }
        return errors;
    };

    const handleCreditTermChange = (e) => {
        const { value } = e.target;
        const integerValue = parseInt(value, 10);
        if (!isNaN(integerValue)) {
            setFormValues({ ...formValues, creditTerm: integerValue });
        } else {
            setFormValues({ ...formValues, creditTerm: '' });
        }
    };



    return (
        <div className="customer-form-container">
            <h1 className="customer-form-title">Add Customer</h1>
            <form className="customer-form" onSubmit={handleSubmit}>
                <div className='customer-row'>
                    <div className="customer-field">
                        <label className="customer-field-label">Id:</label>
                        <input
                            type="text"
                            placeholder="Customer Id"
                            name="id"
                            value={formValues.id}
                            onChange={handleChange}
                        />
                        {formErrors.id && <p className="customer-error-message">{formErrors.id}</p>}
                    </div>
                    <div className="customer-field">
                        <label className="customer-field-label">Name:</label>
                        <input
                            type="text"
                            placeholder="Customer Name"
                            id="name"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                        />
                        {formErrors.name && <p className="customer-error-message">{formErrors.name}</p>}
                    </div>
                    <div className="customer-field">
                        <label className="customer-field-label">Company:</label>
                        <input
                            type="text"
                            placeholder="Customer Company"
                            id="company"
                            name="company"
                            value={formValues.company}
                            onChange={handleChange}
                        />
                        {formErrors.company && <p className="customer-error-message">{formErrors.company}</p>}
                    </div>
                </div>
                <div className='customer-row'>
                    <div className="customer-field">
                        <label className="customer-field-label">Type:</label>
                        <select
                            name="type"
                            id="type"
                            value={formValues.type}
                            onChange={handleChange}
                        >
                            <option value="">Select Customer Type</option>
                            <option value="LOCAL">Local</option>
                            <option value="CORPORATE">Corporate</option>
                        </select>
                        {formErrors.type && <p className="customer-error-message">{formErrors.type}</p>}
                    </div>
                    <div className="customer-field">
                        <label className="customer-field-label">Address:</label>
                        <input
                            type="text"
                            placeholder="Customer Address"
                            id="address"
                            name="address"
                            value={formValues.address}
                            onChange={handleChange}
                        />
                        {formErrors.address && <p className="customer-error-message">{formErrors.address}</p>}
                    </div>
                    <div className="customer-field">
                        <label className="customer-field-label">Credit Term:</label>
                        <input
                            type="number"
                            placeholder="Credit Term"
                            id="creditTerm"
                            name="creditTerm"
                            value={formValues.creditTerm}
                            onChange={handleCreditTermChange}  // Custom onChange handler
                            step="1"
                        />
                    </div>
                </div>
                <div className='customer-row'>
                    <div className="customer-field">
                        <label className="customer-field-label">Phone:</label>
                        <input
                            type="text"
                            placeholder="Phone Number"
                            id="contact"
                            name="contact"
                            value={formValues.contact}
                            onChange={handleChange}
                        />
                        {formErrors.contact && <p className="customer-error-message">{formErrors.contact}</p>}
                    </div>
                    <div className="customer-field">
                        <label className="customer-field-label">BIN No:</label>
                        <input
                            type="text"
                            placeholder="BIN Number"
                            id="binNo"
                            name="binNo"
                            value={formValues.binNo}
                            onChange={handleChange}
                        />
                        {formErrors.binNo && <p className="customer-error-message">{formErrors.binNo}</p>}
                    </div>
                    <div className="customer-field">
                        <label className="customer-field-label">Supplier Of:</label>
                        <Select
                            className="dropdown"
                            name="supplier"
                            components={{
                                ...animatedComponents,
                            }}
                            value={selectedSupplier}
                            onChange={handleSupplierChange}
                            options={suppliers.map((s) => ({ label: s.supplierName, value: s.supplierId }))}
                            isSearchable
                            placeholder="Select Supplier"
                            menuPosition="fixed"
                            styles={{ menu: (provided) => ({ ...provided, top: "auto", bottom: 0 }) }}
                        />
                        {formErrors.supplier && <p className="customer-error-message">{formErrors.supplier}</p>}
                    </div>
                </div>
                <div className="customer-submit-button-container">
                    <button className="customer-submit" type="submit" >Add Customer</button>
                </div>
            </form>
        </div>
    );
};

export default Customer