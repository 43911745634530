import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Revenue.css';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import Navbar from './NavBar';
import Pagination from './Pagination';

const Revenue = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setRevenueData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    function formatCurrency(amount) {
        if (isNaN(amount)) {
            return amount;
        }

        return Number(amount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://inventory-mtxj.onrender.com/common/revenue`);
            // const response = await axios.get(`http://localhost:8084/common/revenue`);
            if (response.data.code === 200) {
                const revenueResponseList = response.data.revenueResponses || [];
                setRevenueData(revenueResponseList);
                setFilteredData(revenueResponseList);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
    }, []);

    const handleXlsxClick = () => {
        const customFileName = prompt('Enter the file name') || 'report';
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const uniqueFileName = `${customFileName}_${timestamp}`;
        const wsData = [
            ...filteredData.map((row, index) => ({
                'SL NO': index + 1,
                'Product': row.product,
                'Total Buy': row.totalBuy,
                'Total Sale': row.totalSell,
                'In Stock(Cartoon)': row.totalCarton,
                'In Stock(Piece)': row.totalPiece,
                'In Stock(Kg/Lt)': row.totalKgLt,
                'Average Unit Price(Buy)': row.averageBuyingPrice,
                'Average Unit Price(Sale)': row.averageSellingPrice,
                'Total Price(Buy)': row.totalBuyingPrice,
                'Total Price(Sale)': row.totalSellingPrice,
                'Stock Value': row.revenue,
            })),
            {
                'SL NO': 'Grand Total',
                'Stock Value': grandTotal,
            },

        ];
        const ws = XLSX.utils.json_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const xlsxBinaryArray = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });
        const xlsxBlob = new Blob([xlsxBinaryArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const xlsxUrl = URL.createObjectURL(xlsxBlob);
        const a = document.createElement('a');
        a.href = xlsxUrl;
        a.download = `${uniqueFileName}.xlsx`;
        a.click();
    };

    useEffect(() => {
        const updatedFilteredData = data.filter((item) => {
            const matchesSearchTerm =
                item.product.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesSearchTerm;
        });

        setFilteredData(updatedFilteredData);
    }, [data, searchTerm]);

    const grandTotal = filteredData.reduce((acc, row) => acc + row.revenue, 0)
        .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return (
        <div className="revenue-details-table-container">
            <Navbar />
            <h2>Stock Value</h2>
            <div className='revenue-table-controls'>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <button className='revenue-action-buttons' type="button" onClick={handleXlsxClick}>
                XLSX
            </button>

            <table className="revenue-details-table">
                <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Product Name</th>
                        <th>Total Buy</th>
                        <th>Total Sale</th>
                        <th>In Stock (Carton)</th>
                        <th>In Stock (Piece)</th>
                        <th>In Stock (Kg/Lt)</th>
                        <th>Average Unit Price (BUY)</th>
                        <th>Average Unit Price (Sale)</th>
                        <th>Total Price (Buy)</th>
                        <th>Total Price (Sale)</th>
                        <th>Stock Value</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{row.product}</td>
                            <td>{row.totalBuy}</td>
                            <td>{row.totalSell}</td>
                            <td>{row.totalCarton}</td>
                            <td>{row.totalPiece}</td>
                            <td>{row.totalKgLt}</td>
                            <td>{formatCurrency(row.averageBuyingPrice)}</td>
                            <td>{formatCurrency(row.averageSellingPrice)}</td>
                            <td>{formatCurrency(row.totalBuyingPrice)}</td>
                            <td>{formatCurrency(row.totalSellingPrice)}</td>
                            <td>{formatCurrency(row.revenue)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            {data.length === 0 && <p>No records to show.</p>}
            {filteredData.length > 0 && (
                <div className="revenue-grand-total-container">
                    <span>Grand Total:</span>
                    <span>{formatCurrency(grandTotal)}</span>
                </div>
            )}
        </div>
    );
};
export default Revenue;