import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AllBranch.css';
import 'jspdf-autotable';
import Navbar from './NavBar';
import Pagination from './Pagination';
import PasswordModal from './PasswordModal';

const AllBranch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setBranchData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleDelete = (id, user) => {
        setDeleteInfo({ id, user });
        setIsModalOpen(true);
    };

    const handleModalSubmit = (password) => {
        if (!password) {
            alert('Password is required.');
            return;
        }

        if (password !== 'Flagship@2024') {
            alert('Password not matched');
            return;
        }

        const confirmDelete = window.confirm('Are you sure you want to delete this branch?');
        if (confirmDelete && deleteInfo) {
            deleteBranchApiCall(deleteInfo.id, deleteInfo.user);
        }

        setIsModalOpen(false);
        setDeleteInfo(null);
    };

    const deleteBranchApiCall = async (id, user) => {
        try {
            const response = await axios.delete(`https://inventory-mtxj.onrender.com/common/branch?id=${id}&user=${user}`);
            // const response = await axios.delete(`http://localhost:8084/common/branch?id=${id}&user=${user}`);
            if (response.data.code === 200) {
                alert('Branch deleted successfully.');
                fetchData();
            } else {
                alert('Error deleting Branch.');
            }
        } catch (error) {
            console.error('Error deleting Branch:', error);
            alert('Error deleting Branch. Please try again.');
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('https://inventory-mtxj.onrender.com/common/all/branch');
            // const response = await axios.get('http://localhost:8084/common/all/branch');
            if (response.data.code === 200) {
                const branchResponseList = response.data.branchList || [];
                setBranchData(branchResponseList);
                setFilteredData(branchResponseList);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const user = localStorage.getItem('userEmail');
        if (!user) {
            window.location.href = "/";
        }
        fetchData();
    }, []);

    useEffect(() => {
        const updatedFilteredData = data.filter((item) => {
            const matchesSearchTerm =
                item.branchName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.branchAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.supplier?.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesSearchTerm;
        });

        setFilteredData(updatedFilteredData);
    }, [data, searchTerm]);

    return (
        <div className="branch-details-table-container">
            <Navbar />
            <h2>Branch Details</h2>
            <div className='branch-table-controls'>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <table className="branch-details-table">
                <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Branch Name</th>
                        <th>Branch Address</th>
                        <th>Supplier Name</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{row.branchName}</td>
                            <td>{row.branchAddress}</td>
                            <td>{row.supplier}</td>
                            <td>
                                <button onClick={() => handleDelete(row.id, localStorage.getItem('userEmail'))}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            {data.length === 0 && <p>No records to show.</p>}
            <PasswordModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                onSubmit={handleModalSubmit} 
            />
        </div>
    );
};

export default AllBranch;